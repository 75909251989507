<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding: 0; margin: 0; padding-top: 60px; padding-bottom: 4rem">
        <div class="container" style="padding: 20px; margin-top: 50px">
          <div class="columns is-vcentered">
            <div class="column">
              <br />
              <br />

              <p class="title is-3">
                <!--   
                      Get the most out of AI with ChatGPT

                      // 100+ simple AI tools for your business

                             Start using AI at your business

                              Bring AI to your business
                               Bring AI shouldn't be hard AI baby steps for your business

     AI for the Busy Entrepreneur
                               Your Business, Supercharged by AI


                                Supercharge your business with GPTs

                                Supercharge your business with GPTs
-->
                GPTs, personalized for <strong class="underline"> you</strong>
              </p>
              <p class="subtitle is-5">
                We provide easy to use AI tools to small businesses.

                <!--
                    productive
                    
                    for busy professional
                      We provide 250+ easy to use AI tools for busy entrepreneurs.
                    
               
                AI with easy to use tools. Focus on your business, not on AI. We help you get the most out of AI,
                without the complexity. Easy-to-Use AI Tools for profesionnals and busy entrepreneurs.
                 -->
                <!--
                    Easy-to-Use AI Tools for profesionnals and busy entrepreneurs.
                     to Simplify Your Business Operations Without Complexity
                     Secure your turf and unlock new audiences on the ChatGPT platform.</p>

                    We help businesses use AI
                -->
              </p>

              <div style="max-width: 500px">
                <b-button type="is-dark" style="font-weight: bold" @click="scrollToDiv">Get Started</b-button>

                <waitlist source="agency" v-if="false" />
                <!--       -->
              </div>
              <!-- 
          <b-button native-type="submit" type="  is-dark" style="text-transform: uppercase; letter-spacing: 1px">
            TRY IT NOW
          </b-button>
        -->
              <br />
              <em style="color: black; opacity: 0.2">
                <!-- 
            Get early access to OnlyBots beta.<br />-->
                <!-- 
            OnlyBots beta is invite only.-->
              </em>

              <!--   
            The first AI creators community. Invite only
            The first AI creators community. Invite only<em style="color: black; opacity: 0.2">Join the first AI creators community </em>  <qrform /> -->
            </div>

            <div class="column is-3">
              <!-- -->
              <img src="/img/gpt/landing/chatbot1.png" style="max-width: 300px" />
            </div>
          </div>
        </div>
      </div>
    </section>
    <br />
    <br />

    <div class="section">
      <div class="container c1200 promoMenu" style="max-width: 800px !important">
        <div class="" style="text-align: center">
          <p class="title is-6 label">
            <!--  That's what we do
            
               We 🖤 building AI tools
            
            Don't Get Left Behind
            your companies
        -->
            Future-Proof
          </p>
          <p class="title is-1">
            <!--
            Working with us
             Let's work together
             Who is it for?
               You will 🖤 using your GPTs
                Build a Future-Ready Company
                AI will change (almost) everything

                 AI Today, Success Tomorrow
          -->

            Embrace AI, now
          </p>
          <p class="subtitle is-5">
            ChatGPT is a simple tool anyone can use. GPTs now open up a tons of new possibilities.

            <!-- -->
            Businesses first leveraging those innovations are set to gain a competitive advantage.

            <!-- -->

            <!-- 

                 Our generous bundles will give an
            edge to your business today and prepare it for a smarter tomorrow.

      Get the most of AI with an our
            bundle that's right for your business.
                            
            GPTs are a good way to get started with AI. ChatGPT is notoriously easy to use, and GPTs can be used for a
            wide range of tasks. Get the most of AI with our precisely crafted GPTs.

                Our team carefully crafted 250 GPTs that you can use today.
            Dive deeper into AI with our GPT bundles. Simple yet powerful tools that will give an edge to your
            business today and prepare it for a smarter tomorrow.
          
                 or custom-made AI tools for your specefic needs.
                 
            QualityGPTs is an agency specializing in custom GPTs. We help brands and organizations maximize their use of
            the ChatGPT platform. -->
          </p>
          <br />
        </div>

        <div
          v-for="(item, index) in julyFeatures"
          :key="index"
          class="columns alterGrid is-vcentered"
          :class="{ 'reverse-columns-desktop': !(index % 2) }"
        >
          <div class="column is-6" style="padding-bottom: 0">
            <img :src="item.image" style="max-height: 300px; height: 30vw" />
          </div>
          <div class="column is-6">
            <div class="cardNO cardCity">
              <div>
                <div class="card-contentNO">
                  <!--
                  <span class="icon is-large is-left">
                    <i :class="'fal fa-' + item.icon"></i>
                  </span>  -->
                  <p class="title is-6 label">{{ item.label }}</p>
                  <p class="title is-2">{{ item.title }}</p>
                  <p class="subtitleNO desc" v-html="item.desc"></p>
                </div>
              </div>
              <!-- 
              <footer class="card-footer">
                <p class="card-footer-item">
                  <a href="/quote" class="button is-link bold">GET STARTED</a>
                  <span v-if="item.demoUrl"
                    >&nbsp; &nbsp;
                    <a :href="item.demoUrl" target="_blank" class="button is-light">Demo</a>
                  </span>
                </p>
              </footer> -->
            </div>
          </div>
          <br /><br /><br />
        </div>
      </div>
    </div>
    <!--  
    <div class="section">
      <div class="container c1200 promoMenuNO" style="">
        <div style="text-align: center">
          <div style="">
            <appStoreBtns />
          </div>
          <br />

          <h2 class="title is-2">Fast, helpful and fun</h2>

          <h2 class="subtitle is-4">Learn new things, get things done, and talk with hundreds of different AI</h2>
          <br />
        </div>

        <bot-list :bots="bots" :includeLinks="false" :page-size="16" :key="543534"></bot-list>

        <b-tabs v-model="selectedBotCat" expanded>
          <b-tab-item :label="i.title" v-for="(i, index) in botCats" :key="index + 'f666ds'"> </b-tab-item>
        </b-tabs>

        <div v-if="botCats && botCats.length && botCats[selectedBotCat] && botCats[selectedBotCat].bots">
          <bot-list
            :bots="botCats[selectedBotCat].bots"
            :includeLinks="false"
            :page-size="16"
            :key="selectedBotCat + 'fdshui'"
          ></bot-list>
        </div>

        <div v-show="false">
          <promoMenu />
        </div>
      </div>
      <hr />
    </div>


        
          -->

    <hr />

    <div class="section pricing" id="pricing">
      <div style="text-align: center" class="container c1200">
        <h2 class="title is-2">
          <!--

  Professional GPTs, good price.
   Accurate, reliable, and affordable.
            -->
          Simple pricing, big ROI
        </h2>
        <h2 class="subtitle is-4" style="max-width: 780px; text-align: center; margin: auto">
          <!-- 

     All bundles include documentation, source materials, friendly support, all core features, and generous usage
          limits.



            -->

          <!-- -->
          Unlock full access to all our best GPTs or
          <!-- -->
          get a custom set of tools specefically designed for your business.
          <!--AI tools specefically for
          your business and show you how to use them.

               We also provide custom GPTs that covers advanced workflows and API access.
                  Powerful, reliable, and affordable.
           -->
        </h2>
        <br />
        <br />
        <h2
          class="title is-5"
          style="
            border-radius: 99px;
            background: #bbffd3;
            color: #24d160;
            display: inline-block;
            padding: 5px 20px;
            margin: 0 auto;
            margin-bottom: 40px;
          "
        >
          Launch offer - 50% off
        </h2>
        <pricing />
      </div>
    </div>

    <div class="section">
      <div class="container c1200">
        <h2 class="title is-1" style="text-align: center">
          <!--
 
            -->
          What's in the box?
        </h2>
        <br />
        <!--      -->
        <p class="title is-6 label">Designed for Small Businesses.</p>
        <p class="title is-3">Simple GPTs that saves 🕗 and 💰</p>
        <!--GPTs are used for various tasks accross many industries.-->
        <p class="subtitle is-5">
          <!---->
          Here are some examples of things you'll be able to Do using GPTs from our bundles. <br />
        </p>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="feature in baseFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />

        <p class="title is-6 label">Personalized Bundle</p>
        <p class="title is-3">Let us customize some GPTs</p>
        <!--GPTs are used for various tasks accross many industries.-->
        <p class="subtitle is-5">
          <!---
          Here are some examples of things you could do using GPTs. <br /> 
            We'd love to chat with you and explore other ways we could add value to your business.
         -->

          Let's chat, we'll assemble a set of GPTs that fits your needs, and tailor them for your organization.
        </p>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="feature in persoFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />

        <p class="title is-6 label">Complex workflows & tools</p>
        <p class="title is-3">Order an advanced GPT</p>
        <!--GPTs are used for various tasks accross many industries.-->
        <p class="subtitle is-5">
          <!---
          Here are some examples of things you could do using GPTs. <br /> 
          We'd love to chat with you and explore other ways we could add value to your business.
           -->
          Expertly designed GPTs for your needs, perfectly integrated to your processes.
        </p>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="feature in advancedFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>

        <br />
        <br />
        <hr />

        <br />
        <div class="columns">
          <div class="column">
            <div class="box">
              <p class="title is-2">
                <!--     <qrform /> 
                Be a part of the AI revolution.-->
                Not sure?
                <br />
                Let's talk 😊
              </p>
              <a href="https://superpeer.com/menard/-/gpt-demo" style="font-weight: bold" target="_blank">
                <b-button type="is-dark" style="font-weight: bold">Book a Demo</b-button>
              </a>
              <a
                href="mailto:team@qualitygpts.com?subject=Custom GPT project"
                class="button is-dark is-outlined"
                style="font-weight: bold"
                target="_blank"
              >
                <span class="icon">
                  <i class="fal fa-envelope"></i>
                </span>
                <span>Discuss your project</span>
              </a>
              <!-- 
              <waitlist source="agency" />
               -->
            </div>
          </div>
          <div class="column"></div>
        </div>

        <div class="columns is-vcentered">
          <div class="column is-7">
            <h3 class="title id-4">Affiliate Program</h3>
            <p class=" ">
              Help us on our mission to build the best AI tools. Promote QualityGPTs on your social media, podcast, or
              blog, and receive

              <strong> 10-20% commissions</strong>.

              <!-- 
              We're looking for creators to establish the foundation of the OnlyBots platform. Be amongst the first
              brands & creators to join; establish your presence on the platform.
              -->
            </p>
            <p>
              <br />
              <a
                href="mailto:team@qualitygpts.com?subject=QualityGPTs affiliate program&body=Hi! I'd like to promote QualityGPT and join the affiliate program."
                class="button is-dark is-outlined"
                style="font-weight: bold"
                target="_blank"
              >
                <span class="icon">
                  <i class="fal fa-envelope"></i>
                </span>
                <span>Get in touch</span>
              </a>
            </p>
          </div>
          <div class="column">
            <img
              class="multiply"
              src="/img/gpt/landing/mega1.png"
              style="max-width: 250px; margin: auto; display: block"
            />
          </div>
        </div>

        <div class="columns is-vcentered">
          <div class="column is-7">
            <h3 class="title id-4">Join our team</h3>
            <p class=" ">
              We're growing quickly and looking for talented people to join our team. Put your GPT skills to good use
              and come build the future of AI with us.

              <!-- 
              We're looking for creators to establish the foundation of the OnlyBots platform. Be amongst the first
              brands & creators to join; establish your presence on the platform.
              -->
            </p>
            <p>
              <br />
              <a
                href="https://airtable.com/appz8rzyyFX2TGSDm/shrrEugWzkxv8ET6L"
                class="button is-dark is-outlined"
                style="font-weight: bold"
                target="_blank"
              >
                <span class="icon">
                  <i class="fal fa-edit"></i>
                </span>
                <span>Apply to job</span>
              </a>
            </p>
          </div>
          <div class="column">
            <img
              class="multiply"
              src="/img/gpt/landing/chat1.png"
              style="max-width: 200px; margin: auto; display: block"
            />
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <div class="section" id=" ">
      <div class="container c1200" style="text-align: left">
        <br />
        <br />

        <!--
        <br />
        <div style="">
          <appStoreBtns />
        </div>
        <br />
  -->
        <br />

        <br />
        <p class=" ">
          © {{ new Date().getFullYear() }}. <strong>QualityGPTs</strong> - Made with ❤️ by real humans, in Montréal ❄️
        </p>
      </div>
    </div>

    <!--  
    <div class="section">
      <div class="container c1200 guide">
        <guide />
      </div>
    </div>

    <theFooter />
     -->
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";
import BotList from "@/components/BotList.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

import promoBlock from "@/components/re/promoBlock.vue";

import appStoreBtns from "@/components/brochure/appStoreBtns.vue";
//import pricing
import pricing from "@/components/pricingGpt1.vue";

const julyFeatures = [
  // ING: https://icons8.com/illustrations/style--3d-plastic-people

  // https://icons8.com/illustrations/illustration/3d-fluency-fire
  {
    label: "Streamline tasks  ", //"AI opportunities",
    title: "Work Smart, Not Hard",
    desc: "Plan, organize, and execute using GPTs. From crafting social media posts to orchestrating office events, our GPTs streamline your workload.",
    image: "/img/gpt/landing/magic1.png",
  },

  {
    label: "Quality first", // " Don't waste your time", //"Your time is precious", //   " Don't waste time",
    title: "No bad bots ",
    desc: "Profesionals deserve carefully crafted AI tools. Our experts avoid common AI design pitfalls, ensuring coherent, effective interactions every time.",
    image: "/img/gpt/landing/robot3.png",
  },

  {
    label: "More than chats",
    title: "Web, Books, APIs and datasets", //"Efficient Investment",
    desc: "Quality GPTs can reference facts, search online, schedule appointments, analyse your organization data and integrate with 100+ other tools.",
    image: "/img/gpt/landing/web1.png",
  },

  {
    label: " Personalized", //
    title: "Your voice, your GPTs",
    desc: " Our <strong>essential bundle</strong> delivers a ton of value at a low price. But since every business is unique, we've specialized in crafting custom set of GPTs that align with your specific needs.",

    //you'll really get the most of AI with a custom GPT.",

    // ensure that our GPTs are tailored to your specific needs.",

    //also offer custom GPTs that are tailored to your specific needs.",
    // and we will customize your set of GPTs to f
    // fit your specific needs.",

    //many businesses want to customize their GPTs further to avoid, or order a set of custom GPT for your business.",
    image: "/img/gpt/landing/store1.png",
  },

  {
    label: "Advanced GPTs",
    title: "Go further",
    desc: "Customized GPTs that align with your organization needs. Connect your data, integrate APIs, and experience AI that adapts to you.",
    image: "/img/gpt/landing/boom1.png",
  },
  {
    label: "Risk-Free bundles ",
    title: "No string attached", //"Efficient Investment",
    desc: "One-time payment, lifetime value. Dive into AI with QualityGPTs and experience immediate, enduring gains for your business.",
    image: "/img/gpt/landing/fire1.png",
  },
];

////
const baseFeatures = [
  {
    title: "Content Creation  📝",
    description:
      "Automate content writing, generate creative social media post ideas, and respond effectively to online reviews, enhancing digital presence and engagement.",
    score: 8,
  },

  {
    title: "  Marketing Research 📈",
    description:
      "Leverage the best methodologies to reach and persuade your audience. Use GPTs to communicate value proposition in a way that's engaging and true to your voice.",
  },

  {
    title: "Lawyer-bot ⚖️",
    description:
      "Cut legal costs and get some quick legal advices. Summarize long document and assess main risks. Draft the first version of your agreements, contracts and formal notices.",
    score: 7,
  },
  {
    title: "HR Tools 🛠️",
    description:
      "Define your company culture, write compelling job posts, compose personalize candidate follow-ups and unlock better employee experience.",
  },
  {
    title: "Meeting day   📅",
    description:
      "Conntect your calendar to discuss your agenda, get summaries and concise briefing of your day's meetings", /// Get briefing on your meetings and tasks. Let your GPTs handle the scheduling, and get a summary of your day's meetings and tasks.",
    //  "Streamline appointment scheduling with an AI assistant, reducing administrative workload and improving customer experience.",
    score: 7,
  },

  {
    title: "Reply to customer reviews 🌟",
    description:
      "Engage with your most passionate audiences. Provide quick, appropriate responses that maintain a friendly and profesional tone, improving customer satisfaction.",
    score: 9,
  },

  {
    title: "More Internal Tools 🛠️",
    description:
      "Improve team efficiency with other AI-enhanced tools. Facilitate smoother internal communications and processes.",
  },

  /*
  {
    title: "Brand Marketing  📈",
    description:
      "Connect with your audience on ChatGPT. Use AI to communicate your brand's message in a way that's engaging and true to your voice.",
  },

    {
    title: "Automated Support 🤖",
    description:
      "Transform customer support with AI. Provide quick, appropriate responses that maintain a profesional tone, improving customer satisfaction.",
    score: 9,
  },

    {
    title: "Personality Clone 👥",
    description:
      "Create a digital version of yourself. Your chatbot will communicate and create content with your personal style, maintaining your presence consistently and engagning your audience in new ways.",
  },
    */

  /*
  {
    title: "Homework Helper Bot 📚",
    description:
      "AI chatbots that assist students with homework, offering explanations, resources, and study tips in various subjects.",
    score: 8,
  },
  {
    title: "Workflow automations🚀",
    description:
      "Custom AI tools designed for your specific business processes. Streamline your workflow with solutions that fit seamlessly into your operations.",
  },

  {
    title: "Accurate Data 🔍",
    description:
      "Rely on precise and coherent AI interactions. Our approach minimizes errors and enhances the reliability of information provided.",
  },
   */
];

const persoFeatures = [
  {
    title: " A unique voice ",
    description:
      "Let you organization personality shine throught your internal communications and marketing content. Communicate efficiently, improving customer satisfaction and employee engagement.",
    score: 9,
  },

  // "Connect with your audience on ChatGPT. Use AI to communicate your brand's message in a way that's engaging and true to your voice.",

  {
    title: " Business knowledge ",
    description:
      " Your GPTs will have all the context information they need to assist your team even better. No need to repeat yourself, your GPTs will remember. ",
    score: 9,
  },
  {
    title: "Staff companion 📚",
    description:
      "Create employee onboarding assistants, let your staff quickly access important informations and get guidance on company processes. Reduce the need for training and improve your team's efficiency.",
    // "Tailor chatbots to your curriculum. Facilitate engaging and relevant student interactions that complement their learning experience.",
  },
  {
    title: " Optimize tasks ",
    description:
      "We'll help you find parts of your processes where AI tools could save you tons of time. Frenquently need to write one very specefic type of communication? Handle repetitive support questions? We'll find the best GPTs for you.",
    score: 9,
  },

  /*
  {
    title: "Brand Marketing 🌟",
    description:
      "Connect with your audience on ChatGPT. Use AI to communicate your brand's message in a way that's engaging and true to your voice.",
  },
 

  {
    title: "Ecommerce Integration 🛒",
    description:
      "Connect your product catalog to AI, enabling personalized suggestions and real-time inventory updates during customer chats.",
  },
  */
];

const advancedFeatures = [
  {
    title: "Launch a public GPT",
    description:
      "Provide your customers with unique tools available on the GPT store and elsewhere. Handle support requests, offer an unique experience or simply reach a new audience.",
  },
  {
    title: "Ecommerce Integration 🛒",
    description:
      "Connect your product catalog to AI, enabling personalized suggestions and real-time inventory updates during customer chats.",
  },
  {
    title: "Workflow automations  🚀",
    description:
      "Custom AI tools triggering processes, sending emails or notifications. There's no limits to AI automations.",
    score: 9,
  },

  {
    title: "Integrations  🤖",
    description:
      "Access your GTPs from spreadsheets, your own apps, in a website widget, your API, or other 100+ other tools like Slack, Canva or Gmail.",
    score: 9,
  },

  /*
  {
    title: "Workflow automations  🚀",
    description:
      "Custom AI tools designed for your specific business processes. Streamline your workflow with solutions that fit seamlessly into your operations.",
  },
  {
    title: "Accurate Data 🔍",
    description:
      "Rely on precise and coherent AI interactions. Our approach minimizes errors and enhances the reliability of information provided.",
  },
  {
    title: "Advanced Integrations 🛠️",
    description:
      "Connect your GPTs to your existing tools and data. We can integrate with your APIs, databases, and more.",
  },
  {
    title: "Custom GPTs 🛠️",
    description:
      "Custom AI tools designed for your specific business processes. Streamline your workflow with solutions that fit seamlessly into your operations.",
  },

  */
];

const OG_DEFAULT = "https://qualitygpts.com/img/gpt/landing/chatbot1.png";

export default {
  name: "Home",
  components: {
    // qrform, //
    waitlist,
    // appStoreBtns,
    //  guide,
    //promoMenu,
    //BotList,
    // promoBlock,
    // pricing,
    //  HelloWorld,
    // projCard,
    pricing,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "QualityGPTs • The agency for ChatGPT dev 🤖", ///the platform for AI cretors
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      //  { property: "og:site_name", content: "OnlyBots" },
      { property: "og:type", content: "website" },
      { property: "og:image", vmid: "og:image", content: OG_DEFAULT }, //hid props act as unique id
      { property: "twitter:image", content: OG_DEFAULT },
      { property: "twitter:card", content: "summary_large_image" },
    ],
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      bots: [],
      selectedBotCat: 0,
      botCats: [],
      julyFeatures,
      advancedFeatures,
      persoFeatures,
      baseFeatures,

      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    var promo = {
      handle: "Add yours",
      avatar: "/img/bots/plus.webp",
      bio: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
    };
    /*
     */

    window.API.getFeaturedBots({ brochureHome: 12, format: "list" }).then((proj) => {
      //this.bots = proj;
      this.bots = proj;
      this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    /*
    window.API.getFeaturedBots({ brochureHome: 1, format: "audiences" }).then((proj2) => {
      //this.bots = proj;
      this.botCats = proj2;
      //  this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    */
  },
  methods: {
    scrollToDiv() {
      const element = document.getElementById("pricing");
      element.scrollIntoView({ behavior: "smooth" });
    },
  },
};
</script>
<style scoped>
.underline {
  text-decoration: none;
  box-shadow: inset 0 -2px 0 rgb(242 248 255 / 80%), 0 2px 0 rgb(255 255 255);
  transition: box-shadow 0.3s;
  color: inherit;
  overflow: hidden;
  font-weight: bold !important;
}
div,
p {
  line-height: 1.4em;
}
strong {
  font-weight: bold;
}
.container.c1200 {
  max-width: 1100px !important ;
}

.label {
  opacity: 0.3;
  letter-spacing: 1px;
  font-weight: 200;
}
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);

  background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);

  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
}

/* mobile smaller title and p text size */
@media screen and (max-width: 768px) {
  /* heros */
  .hero.is-main {
    background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);
  }

  .title.is-2 {
    font-size: 1.8rem !important;
  }
  .subtitle.is-4 {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (min-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns-desktop {
    flex-direction: column-reverse;
    flex-direction: row-reverse;
    display: flex;
  }
}

.alterGrid img {
  margin: auto;
  display: block;
}

.content .icon.is-large {
  font-size: 2.8rem;
  margin-bottom: 10px;
  float: right;
}

.cardCity {
  /*
  border-radius: 10px;
  border: 2px solid transparent;
  */
}
.cardCity p.desc {
  line-height: 1.6em;
}
.cardCity a {
  color: #000;
}

.title.label {
  text-transform: uppercase;
}
/*
.cardCity:not(.muted):hover,
.cardCity:not(.muted):hover a .icon,
.cardCity:not(.muted):hover a .title {
  border-color: blue;
  color: blue;
}*/
</style>
