<template>
  <div class="columns is-vcentered">
    <div class="column">
      <div class="content">
        <h2 class="title is-2">{{ title }}</h2>
        <p class="subtitle is-4">{{ sub }}</p>
        <div v-if="Array.isArray(paragraphs)">
          <p v-for="p in paragraphs" :key="p" v-html="p" />
        </div>
        <div v-else>
          <p>{{ paragraphs }}</p>
        </div>
      </div>
    </div>
    <div class="column is-5">
      <figure class="image">
        <img :src="img" :alt="title" />
      </figure>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    img: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    sub: {
      type: String,
      required: false,
    },

    paragraphs: {
      type: [String, Array],
      required: true,
    },
  },
};
</script>

<style scoped>
.content {
  margin-bottom: 1.5rem;
}
</style>
