import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

import _ from "lodash";

Vue.use(VueRouter);

import scrollBehavior from "@/router/scrollBehavior.js";
// import beforeEach from '@/routes/beforeEach.js';

const inboxChildren = [
  {
    path: ":messageId",
    component: () => import("../views/MessageDetails.vue"),
    // name: "messageDetails",
  },
];

const settingsChildren = [
  {
    path: "/",
    name: "UserSettings",
    component: () => import("../views/UserSettingsEmpty.vue"),
  },
  {
    path: "profile",
    name: "UserSettingsProfile",
    component: () => import("../views/EditUserProfile.vue"),
  },
  {
    path: "context",
    name: "UserSettingsContext",
    component: () => import("../views/UserSettingsContext.vue"),
  },
  {
    path: "pass",
    name: "UserSettingsPass",
    component: () => import("../views/UserSettingsPass.vue"),
  },
  {
    path: "admin",
    name: "UserSettingsAdmin",
    component: () => import("../views/UserSettingsAdmin.vue"),
    meta: {
      isAdmin: true,
    },
  },
];

const blueprintChildren = [
  {
    path: "/",
    name: "BlueprintOptions",
    component: () => import("../views/BlueprintOptions.vue"),
  },
  {
    path: "options",
    name: "UserSettingsProfile",
    component: () => import("../views/BlueprintOptions.vue"),
  },
  {
    path: "demo",
    name: "UserSettingsProfile",
    component: () => import("../views/BlueprintOptions.vue"),
  },
  {
    path: "image-demo",
    name: "UserSettingsProfile",
    component: () => import("../views/BlueprintGenerateImage.vue"),
  },

  //chat specefic
  {
    path: "chat-examples",
    name: "BlueprintFineTune",
    component: () => import("../views/BlueprintFineTune.vue"),
  },
  {
    path: "profile",
    name: "UserSettingsProfile",
    component: () => import("../views/EditUserProfile.vue"),
  },
  {
    path: "context",
    name: "UserSettingsContext",
    component: () => import("../views/UserSettingsContext.vue"),
  },
  {
    path: "pass",
    name: "UserSettingsPass",
    component: () => import("../views/UserSettingsPass.vue"),
  },
  {
    path: "admin",
    name: "UserSettingsAdmin",
    component: () => import("../views/UserSettingsAdmin.vue"),
    meta: {
      isAdmin: true,
    },
  },
];

const routes = [
  {
    path: "/img/:catchAll(.*)*",
    component: () => import("../views/e404.vue"),
  },

  {
    path: "/home48",
    component: () => import("../views/HomeGpt48h.vue"),
  },

  {
    path: "/legal/:id",
    component: () => import("../views/StaticPage.vue"),
  },

  {
    path: "/invite/:inviteId",
    component: () => import("../views/Signup.vue"),
  },
  {
    path: "/gpts/new",
    component: () => import("../views/gpts/gptCreate.vue"),
    name: "gptCreate",
  },
  {
    path: "/gpts/admin",
    component: () => import("../views/gpts/gptsAdmin.vue"),
    name: "gpts",
  },
  {
    path: "/gpts/home",
    component: () => import("../views/gpts/gptsAdmin.vue"),
    name: "gptsHome",
  },
  {
    path: "/gpts/:gptId",
    component: () => import("../views/gpts/gptProfile.vue"),
    name: "gptProfile",
  },

  {
    path: "/gpts/:gptId/edit",
    component: () => import("../views/gpts/gptEdit.vue"),
    name: "gptEdit",
  },
  {
    path: "/gpts/:gptId/saved",
    component: () => import("../views/gpts/gptSaved.vue"),
    name: "gptSaved",
  },
  {
    path: "/gpts/:gptId/write-a-review",
    component: () => import("../views/gpts/gptSaved.vue"),
    name: "gptReview",
  },

  {
    path: "/signup",
    name: "signup",
    component: () => import("../views/Signup.vue"),
  },
  {
    path: "/login",
    redirect: "/signin",
  },
  {
    path: "/signin",
    name: "signin",
    component: () => import("../views/Signup.vue"),
  },
  {
    path: "/menu",
    name: "menuProfilePage",
    component: () => import("@/views/menuProfilePage.vue"),
  },

  //brochure stuff
  {
    path: "/discord",
    component: () => import("../views/brochure/discord.vue"),
  },
  {
    path: "/thank-you",
    component: () => import("../views/brochure/waitlist-thankyou.vue"),
  },

  {
    path: "/plans",
    name: "plans",
    component: () => import("../views/brochure/plans.vue"),
  },
  {
    path: "/pricing",
    name: "pricing",
    component: () => import("../views/brochure/plans.vue"),
  },

  {
    path: "/upgrade",
    name: "upgrade",
    component: () => import("../views/brochure/plans.vue"),
  },

  {
    path: "/generate",
    name: "generateImageOLD",
    component: () => import("../views/UserGenerateImage_OLD.vue"),
  },
  {
    path: "/images/create",
    name: "generateImage2",
    component: () => import("../views/UserGenerateImage_OLD.vue"),
  },

  {
    path: "/images/:bot",
    name: "generateImage3",
    component: () => import("../views/UserGenerateImage.vue"),
  },

  {
    path: "/create",
    name: "createHome",
    component: () => import("../views/createHome.vue"),
  },
  /*
  {
    path: "/invite/:inviteId",
    component: () => import("../views/Invite.vue"),
  },
  {
    path: "/org/:orgId",
    component: () => import("../views/Dashboard.vue"),
  },

  ///share/message/:messageId
  {
    path: "/share/message/:messageId/:shareKey",
    component: () => import("../views/SharedMessage.vue"),
  },

  {
    path: "/contacts",
    component: () => import("../views/AccountSettings.vue"),
  },
  {
    path: "/u/:handle",
    component: () => import("../views/Profile.vue"),
  },
  {
    path: "/contacts/:orgId",
    component: () => import("../views/AccountSettings.vue"),
  },
  {
    path: "/contacts/:orgId/:contactId", //emaml
    component: () => import("../views/AccountSettings.vue"),
  },

  {
    path: "/screen",
    component: () => import("../views/AccountSettings.vue"),
  },

  {
    path: "/domains",
    component: () => import("../views/AccountSettings.vue"),
  },
*/

  {
    path: "/directory/bots/:letter/:page",
    name: "directoryBotsList",
    component: () => import("../views/directoryListing.vue"),
  },
  {
    path: "/directory/bots",
    name: "directoryBotsHome",
    component: () => import("../views/directoryListing.vue"),
  },

  {
    path: "/new-bot",
    name: "createHome",
    component: () => import("../views/BotCreate.vue"),
  },
  {
    path: "/bots/create",
    name: "BotCreate",
    component: () => import("../views/BotCreate.vue"),
  },

  {
    path: "/page/:bot",
    name: "botAlt",
    component: () => import("../views/BotProfile.vue"),
  },

  {
    path: "/sub/confirm",
    name: "SubConfirm",
    component: () => import("../views/SubConfirm.vue"),
  },

  {
    path: "/sub/wallet",
    name: "SubWallet",
    component: () => import("../views/SubWallet.vue"),
  },

  //EditReadme.vue
  /*
  {
    path: "/page/:bot/manage/readme",
    name: "EditReadme",
    component: () => import("../views/EditReadme.vue"),
  },*/
  /*
  {
    path: "/page/:bot/admin",
    name: "BotAdmin",
    component: () => import("../views/BotAdmin.vue"),
  },*/

  //users profiles
  {
    path: "/u/:userId",
    name: "profile",
    component: () => import("../views/UserProfile.vue"),
  },
  {
    path: "/u/:userId/followers",
    name: "userFollowers",
    component: () => import("../views/Followers.vue"),
  },
  {
    path: "/u/:userId/following",
    name: "userFollowing",
    component: () => import("../views/Followers.vue"),
  },
  {
    path: "/u/:userId/invited",
    name: "userInvited",
    component: () => import("../views/Followers.vue"),
  },
  {
    path: "/u/:userId/settings", //useful for mods
    component: () => import("../views/UserSettings.vue"),

    //childresns for editprofil messaging, traffic
    children: settingsChildren,
  },
  {
    path: "/edit-profile",
    component: () => import("../views/EditUserProfile.vue"),
  },
  {
    path: "/settings",
    component: () => import("../views/UserSettings.vue"),

    //childresns for editprofil messaging, traffic
    children: settingsChildren,
  },
  {
    path: "/settingsOld",
    component: () => import("../views/UserSettings.vue"),

    //childresns for editprofil messaging, traffic
    children: settingsChildren,
  },
  {
    path: "/billing",
    component: () => import("../views/Billing.vue"),

    //childresns for editprofil messaging, traffic
    //children: settingsChildren,
  },
  {
    path: "/payouts",
    component: () => import("../views/Payouts.vue"),

    //childresns for editprofil messaging, traffic
    //children: settingsChildren,
  },

  {
    path: "/shared-content-receiver",
    name: "sharedContentReceiver",
    component: () => import("../views/sharedContentReceiver.vue"),
  },
  {
    path: "/sd",
    name: "sd",
    component: () => import("../views/sdGen.vue"),
  },

  {
    path: "/refer",
    name: "refer",
    component: () => import("../views/InviteList.vue"),
  },
  {
    path: "/settings",
    component: () => import("../views/AccountSettings.vue"),
  },

  {
    path: "/messagesDemo",
    name: "inboxDemo",
    component: () => import("../views/Inbox_DEMO.vue"),
  },
  /*
  Let's write a responsive InboxLayout vue component.
The component don'T handle messages, just the layout.
All routes uses the layout as a view. We toggle between different state and show different items based on routen ames and computed properties like 'isNewMessage", isInboxHome, etc

The thread and form will be handled by a sub component "inboxThread" (dont write code).
The inbox feature a app bar at the top, it has different state depending on the view.
A list of Interlocutors, each of these interlocutors have variable conversation id..
On desktop, we always show the interlocutor list on the left columns. On mobile, the list is only visible on inbox home.
*/
  {
    path: "/messages",
    name: "inbox",
    component: () => import("../views/InboxLayout.vue"),
  },
  {
    path: "/messages/:bot", //show a new message by default? Only if stale, more than 30min?
    name: "inboxAuto",
    component: () => import("../views/InboxLayout.vue"),
  },
  {
    path: "/messages/:bot/history",
    name: "inboxHistory",
    component: () => import("../views/InboxLayout.vue"),
  },
  {
    path: "/messages/:bot/new",
    name: "inboxNewConvo",
    component: () => import("../views/InboxLayout.vue"),
  },
  {
    path: "/messages/:bot/:convo",
    name: "inboxConvo",
    component: () => import("../views/InboxLayout.vue"),
  },
  {
    path: "/messages/:bot/new/:task",
    name: "inboxNewConvoTask",
    component: () => import("../views/InboxLayout.vue"),
  },

  //public msg thread - shared
  {
    path: "/shared/convo/:bot/:key",
    name: "inboxConvoPublic",
    component: () => import("../views/InboxSharedThread.vue"),
  },
  {
    path: "/creators",
    name: "creators",
    component: () => import("../views/brochure/creators.vue"),
  },
  /*
  {
    path: "/explore",
    name: "discover",
    component: () => import("../views/ExploreBots.vue"),
  },*/

  {
    path: "/bots",
    name: "ExploreBots",
    component: () => import("../views/ExploreBotsHome.vue"),
  },
  {
    path: "/conversational",
    //name: "ExploreBots",
    component: () => import("../views/ExploreConversational.vue"),
  },
  /*
  {
    path: "/images",
    name: "ExploreImages",
    component: () => import("../views/ExploreImages.vue"),
  },
*/

  {
    path: "/images",
    //name: "ExploreBots",
    component: () => import("../views/ExploreVisual.vue"),
  },
  {
    path: "/images/explore/:tag",
    // name: "ExploreImages",
    name: "ExploreImagesByTag",
    component: () => import("../views/ExploreImages.vue"),
  },
  {
    path: "/social",
    //name: "ExploreBots",
    component: () => import("../views/ExploreConversational.vue"),
  },
  {
    path: "/explore",
    name: "explore",
    component: () => import("../views/ExplorePlatform.vue"),
  },
  {
    path: "/explore-bots",
    name: "explore-bots",
    component: () => import("../views/ExploreBotBrowse.vue"),
  },

  {
    path: "/explore-bots/:cat",
    name: "exploreSearch",
    component: () => import("../views/ExploreBotBrowse.vue"),
  },
  {
    path: "/explore-bots/:cat/:search",
    name: "exploreSearch",
    component: () => import("../views/ExploreBotBrowse.vue"),
  },

  //audience pages.
  {
    path: "/ai-for-:audienceSlug([a-z-]+)", // regex to match any combination of lowercase letters and dashes
    name: "audience",
    component: () => import("../views/ExploreAudience.vue"),
  },

  {
    path: "/tag/:search",
    name: "tagSearch",
    component: () => import("../views/TagPage.vue"),
  },

  {
    path: "/bots/me",
    name: "myBots",
    component: () => import("../views/MyBots.vue"),
  },
  /*
  {
    path: "/pages",
    redirect: "/bots",
  },

  {
    path: "/bots/me",
    redirect: "/bots",
  },
*/
  {
    path: "/insights",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/earnings",
    component: () => import("../views/NotFound.vue"),
  },
  {
    path: "/ressources",
    component: () => import("../views/NotFound.vue"),
  },

  {
    path: "/bots/me/:bot",
    component: () => import("../views/BotManageHub.vue"),
  },

  {
    path: "/",
    name: "Home",
    component: Home,
    //children: inboxChildren,
    //childresns for "notifications", junk, starred, teams, etc
  },

  {
    path: "/search",
    name: "Search",
    component: () => import("../views/SearchResults.vue"),
    //children: inboxChildren,
    //childresns for "notifications", junk, starred, teams, etc
  },

  {
    path: "/welcome",
    name: "welcome", //TODO: personalize the welcome page
    component: () => import("../views/HomeLogged.vue"),
  },

  {
    path: "/home/:messageId",
    component: () => import("../views/Inbox.vue"),
  },
  {
    path: "/updates",
    component: () => import("../views/Inbox.vue"),
    name: "updates",
    //childrens for mesage details route
    //  children: inboxChildren,
  },
  {
    path: "/updates/:messageId",
    component: () => import("../views/Inbox.vue"),
    //  name: "updatesDeets",
    //childrens for mesage details route
    //  children: inboxChildren,
  },
  {
    path: "/team",
    component: () => import("../views/Inbox.vue"),
    name: "team",
    children: inboxChildren,
  },
  {
    path: "/promos",
    component: () => import("../views/Inbox.vue"),
    name: "promos",
    children: inboxChildren,
  },
  {
    path: "/sent",
    component: () => import("../views/Inbox.vue"),
    name: "sent",
    children: inboxChildren,
  },
  {
    path: "/loginPass",
    name: "loginPass",
    component: () => import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/signout",
    name: "signout",
    component: () => import(/* webpackChunkName: "about" */ "../views/Logout.vue"),
  },
  //redirect logout there too
  {
    path: "/logout",
    redirect: "/signout",
  },

  // LOGGED various pages

  {
    path: "/notifications",
    name: "notificationPage",
    component: () => import("@/views/Notifications.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/notifications/:type",
    name: "notificationPageType",
    component: () => import("@/views/Notifications.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/activity",
    name: "ProfileActivityAlt",
    component: () => import("@/views/ProfileActivity.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/u/:userId/activity",
    name: "ProfileActivity",
    component: () => import("@/views/ProfileActivity.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/u/:userId/activity/:type",
    name: "profileActivityType",
    component: () => import("@/views/ProfileActivity.vue"),
    meta: { requiresAuth: true },
  },

  //feed
  {
    path: "/latest",
    name: "newsfeed-new",
    component: () => import("@/views/GlobalFeed.vue"),
  },
  {
    path: "/following",
    name: "newsfeed-new",
    component: () => import("@/views/GlobalFeed.vue"),
  },
  {
    path: "/trending",
    name: "newsfeed-new",
    component: () => import("@/views/GlobalFeed.vue"),
  },

  //misc
  {
    path: "/bookmarks",
    name: "bookmarks",
    component: () => import("@/views/Bookmarks.vue"),
  },
  // BOT profile

  {
    path: "/:bot",
    name: "botRoot", //ALT no path
    component: () => import("../views/BotProfile.vue"),
  },
  {
    path: "/:bot/new",
    //  name: "newBotPost",
    component: () => import("../views/newBotPostLayout.vue"),
    children: [
      {
        path: "/",
        name: "newBotPost",
        component: () => import("../views/newBotPostEmpty.vue"),
      },
      {
        path: "photo",
        name: "newPhotoBotPost",
        component: () => import("../views/newBotPost.vue"),
      },
      {
        path: "text",
        name: "newTextBotPost",
        component: () => import("../views/newBotPost.vue"),
      },
      {
        path: ":type/posted/:postId",
        name: "newBotPostFinish",
        component: () => import("../views/newBotPostFinish.vue"),
      },
    ],
  },
  {
    path: "/:bot/p/:postId",
    name: "editBotPost",
    component: () => import("../views/botPostDetail.vue"),
  },

  {
    path: "/:bot/p/:postId/edit",
    name: "editBotPost",
    component: () => import("../views/editBotPost.vue"),
  },

  {
    path: "/:bot/followers",
    name: "botFollowers",
    component: () => import("../views/Followers.vue"),
  },
  {
    path: "/:bot/following",
    name: "botFollowing",
    component: () => import("../views/Followers.vue"),
  },

  {
    path: "/:bot/discord",
    name: "bobotProfileDiscordt",
    component: () => import("../views/botProfileDiscord.vue"),
  },
  {
    path: "/:bot/about",
    name: "botProfileAbout",
    component: () => import("../views/botProfileAbout.vue"),
  },

  {
    path: "/:bot/new-article",
    name: "newArticle",
    component: () => import("../views/newArticle.vue"),
  },
  {
    path: "/:bot/boost",
    name: "BotBoosting",
    component: () => import("../views/BotBoosting.vue"),
  },
  {
    path: "/:bot/manageOLD",
    //name: "BotSettingsHome", //root should never be called, call firt child
    component: () => import("../views/BotManageOld.vue"),

    //childresns for editprofil messaging, traffic
    children: [
      {
        path: "/",
        name: "BotSettings",
        component: () => import("../views/BotSettingsEmpty.vue"),
      },
      {
        path: "readme",
        component: () => import("../views/EditReadme.vue"),
      },
      {
        path: "profile",
        component: () => import("../views/EditBotProfile.vue"),
      },
      {
        path: "access",
        component: () => import("../views/BotManageAccess.vue"),
      },
      {
        path: "integrations",
        component: () => import("../views/BotManageIntegration.vue"),
      },

      {
        path: "search",
        component: () => import("../views/BotManageSearch.vue"),
      },

      {
        path: "monetization",
        component: () => import("../views/BotManageMonetization.vue"),
      },
      {
        path: "messaging",
        component: () => import("../views/BotManageMessaging.vue"),
      },

      {
        path: "tasks",
        component: () => import("../views/BotManageTasks.vue"),
      },
      {
        path: "text-posts",
        component: () => import("../views/BotManageTextPosts.vue"),
      },
      {
        path: "image-posts",
        component: () => import("../views/BotManageImagePosts.vue"),
      },
      /*
      {
        path: "post-generators",
        component: () => import("../views/BotManageGenerators.vue"),
      },
      {
        path: "integrations",
        component: () => import("../views/BotManageIntegrations.vue"),
      },
      {
        path: "widgets",
        component: () => import("../views/BotManageWidgets.vue"),
      },
    
      {
        path: "traffic",
        component: () => import("../views/BotManageTraffic.vue"),
      },
      {
        path: "gallery",
        component: () => import("../views/BotManageGallery.vue"),
      },*/
      {
        path: "admin",
        name: "BotManageModerate",
        component: () => import("../views/BotManageModerate.vue"),
        meta: {
          isAdmin: true,
        },
      },

      //

      {
        path: "*",
        component: () => import("../views/NotFound.vue"),
      },
      /*
      {
        path: "profile",
        component: () => import("../views/EditBotProfile.vue"),
      },
       
      {
        path: "messaging",
        component: () => import("../views/EditMessaging.vue"),
      },
      {
        path: "traffic",
        component: () => import("../views/EditTraffic.vue"),
      },*/
    ],
  },

  /// BOT MANAGE v2
  {
    path: "/studio/images",
    name: "BotGenerate",
    component: () => import("../views/GenerateImage.vue"),
  },

  {
    path: "/:bot/manage/plans",
    name: "BotPlans",
    component: () => import("../views/BotPlans.vue"),
  },

  {
    path: "/:bot/manage/generate",
    name: "BotGenerate",
    component: () => import("../views/BotGenerate.vue"),
  },
  {
    path: "/bots/:bot/plans",
    name: "BotPlans",
    component: () => import("../views/BotPlans.vue"),
  },

  {
    path: "/bots/:bot/generate",
    name: "BotGenerate",
    component: () => import("../views/BotGenerate.vue"),
  },
  {
    path: "/:bot/images",
    // name: "BotGenerate",
    component: () => import("../views/BotGenerateImage.vue"),
    children: [
      {
        path: "/",
        name: "BotImageGenerate",
        component: () => import("@/components/studio/imgGenForm.vue"),
      },
      {
        path: "templates",
        component: () => import("../views/BotManageTemplates.vue"),
      },
    ],
  },
  {
    path: "/:bot/article",
    name: "BotGenerateaaaa",
    component: () => import("../views/BotGenerateArticles.vue"),
  },

  {
    path: "/:bot/text",
    name: "BotGenerateTTT",
    component: () => import("../views/BotGenerateText.vue"),
  },
  {
    path: "/:bot/generate",
    name: "BotGenerate",
    component: () => import("../views/BotGenerate.vue"),
  },
  {
    path: "/:bot/manage/studio",
    name: "BotGenerate",
    component: () => import("../views/BotGenerate.vue"),
  },

  {
    path: "/:bot/manage/messaging",
    name: "BotGenerate",
    component: () => import("../views/BotGenerateMesaging.vue"),
  },

  {
    path: "/:bot/studio",
    name: "BotGenerate",
    component: () => import("../views/BotGenerate.vue"),
  },

  /*
  {
    path: "/:bot/manage/:program",
    name: "BotProgram",
    component: () => import("../views/BotProgram.vue"),
  },*/

  {
    path: "/bots/:bot",
    //name: "BotSettingsHome", //root should never be called, call firt child
    component: () => import("../views/BotManage.vue"),

    //childresns for editprofil messaging, traffic
    children: [
      {
        path: "/",
        name: "BotSettings",
        component: () => import("../views/BotSettingsHome.vue"),
      },
      {
        path: "programs", //dupe
        component: () => import("../views/BotSettingsHome.vue"),
      },
      {
        path: "posts",
        name: "BotSettings",
        component: () => import("../views/BotManagePosts.vue"),
      },

      {
        path: "settings",
        component: () => import("../views/BotManageGeneral.vue"),
      },
      {
        path: "insights",
        component: () => import("../views/BotManageInsights.vue"),
      },
      {
        path: "readme",
        component: () => import("../views/EditReadme.vue"),
      },
      {
        path: "profile",
        component: () => import("../views/EditBotProfile.vue"),
      },
      {
        path: "access",
        component: () => import("../views/BotManageAccess.vue"),
      },
      {
        path: "integrations",
        component: () => import("../views/BotManageIntegration.vue"),
      },
      {
        path: "behavior",
        component: () => import("../views/BotManageBehavior.vue"),
      },
      {
        path: "search",
        component: () => import("../views/BotManageSearch.vue"),
      },

      {
        path: "monetization",
        component: () => import("../views/BotManageMonetization.vue"),
      },
      {
        path: "messaging",
        component: () => import("../views/BotManageMessaging.vue"),
      },

      {
        path: "tasks",
        component: () => import("../views/BotManageTasks.vue"),
      },
      {
        path: "posts",
        component: () => import("../views/BotManagePosts.vue"),
      },
      {
        path: "text-posts",
        component: () => import("../views/BotManageTextPosts.vue"),
      },
      {
        path: "image-posts",
        component: () => import("../views/BotManageImagePosts.vue"),
      },
      {
        path: "new-article",
        name: "newArticle",
        component: () => import("../views/BotManageImagePosts.vue"),
      },
      /*
      {
        path: "post-generators",
        component: () => import("../views/BotManageGenerators.vue"),
      },
      {
        path: "integrations",
        component: () => import("../views/BotManageIntegrations.vue"),
      },
      {
        path: "widgets",
        component: () => import("../views/BotManageWidgets.vue"),
      },
    
      {
        path: "traffic",
        component: () => import("../views/BotManageTraffic.vue"),
      },
      {
        path: "gallery",
        component: () => import("../views/BotManageGallery.vue"),
      },*/
      {
        path: "admin",
        name: "BotManageModerate",
        component: () => import("../views/BotManageModerate.vue"),
        meta: {
          isAdmin: true,
        },
      },

      //
      /*
      {
        path: "/bots/:bot/:program",
        name: "BotProgram",
        component: () => import("../views/BotProgram.vue"),
      },*/
      /*
      {
        path: "*",
        component: () => import("../views/NotFound.vue"),
      },*/
      /*
      {
        path: "profile",
        component: () => import("../views/EditBotProfile.vue"),
      },
       
      {
        path: "messaging",
        component: () => import("../views/EditMessaging.vue"),
      },
      {
        path: "traffic",
        component: () => import("../views/EditTraffic.vue"),
      },*/
    ],
  },
  /*
  {
    path: "/:bot/manage/functions/:blueprint",
    name: "Blueprint2",
    component: () => import("../views/Blueprint.vue"),
    children: blueprintChildren,
  },
  {
    path: "/:bot/manage/:blueprint",
    name: "Blueprint2",
    component: () => import("../views/Blueprint.vue"),
    children: blueprintChildren,
  },

*/
  {
    path: "/bots/:bot/:blueprint",
    name: "Blueprint",
    component: () => import("../views/Blueprint.vue"),
    children: blueprintChildren,
  },
  { path: "*", component: () => import("@/views/NotFound.vue") },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,

  async scrollBehavior(to, from, savedPosition) {
    //that's the propery name here.
    //bellow is externa method in a file.
    return await scrollBehavior(to, from, savedPosition); // extarnal lib
  },
  routes,
});

export default router;
