<template>
  <div v-show="true" :class="{ 'is-hidden-touch': hideFootMobile }">
    <section class="main-contentBLACK foot mainn" style="">
      <div class="clear"></div>
      <article class="container containerMargins secondary wide">
        <div class="columns is-variable is-1 is-multiline">
          <div class="column is-4 footlogo is-narrow">
            <div class="columns is-mobile is-multiline">
              <div class="column is-12-desktop is-6-mobile is-12-tablet">
                <router-link to="/" class="inviteBrand">
                  <img
                    src="/img/brand/logo_white.png"
                    class="logotype logo"
                    loading="lazy"
                    alt="OnlyBots app "
                    style="opacity: 0.9; height: 37px; margin-left: 0px; margin-top: 4px"
                  />
                  <!--
                    <logo class="logo" color="white" style="max-width:180px;" alt="OnlyBots app referral codes" />

                    https://discord.com/channels/1083423353014075513/1085890710034718871
                                                   1085890144898388008-1085890144898388008


                                                   
                                                  
 
 1085890710034718871   - Forum chann el ID
 1085921258681667684   -  forum POST id

                    -->
                </router-link>
                <br />
                <a
                  href="https://twitter.com/intent/follow?screen_name=OnlyBots_"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-twitter"></i>
                  </span>
                </a>

                <a href="https://discord.gg/hKRfQa8Bzq" target="_blank" rel="noopener noreferrer">
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-discord"></i>
                  </span>
                </a>

                <a href="https://github.com/Only-Bots" target="_blank" rel="noopener noreferrer">
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-github"></i>
                  </span>
                </a>
                <a href="https://www.reddit.com/r/OnlyBots_" target="_blank" rel="noopener noreferrer">
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-reddit"></i>
                  </span>
                </a>
                <a href="https://medium.com/@OnlyBots" target="_blank" rel="noopener noreferrer">
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-medium"></i>
                  </span>
                </a>
                <a
                  href="https://www.facebook.com/profile.php?id=100090176881926"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-facebook"></i>
                  </span>
                </a>
                <!--  

                           <a href="https://instagram.com/OnlyBots.codes.app" target="_blank" rel="noopener noreferrer">
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-instagram"></i>
                  </span>
                </a>



                <a href="https://t.me/OnlyBots_app" target="_blank" rel="noopener noreferrer">
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fab fa-telegram"></i>
                  </span>
                </a>-->

                <a href="https://www.facebook.com/groups/605427557682346" target="_blank" rel="noopener noreferrer">
                  <span class="icon has-text-light is-largeNo socialicon">
                    <i class="fal fa-users"></i>
                  </span>
                </a>
                <br />

                <!--  
                <a
                  href="https://chrome.google.com/webstore/detail/OnlyBots-%E2%86%90-referrals-co/kmeijfochmidimcngicjabokhkeifmmj"
                  class="is-hidden-touch extensionPromoLink"
                  target="_blank"
                  v-if="$browserDetect.isChrome || $browserDetect.isBrave"
                  style="
                    padding: 1px 0px;
                    border-radius: 11px;
                    background: #fff;
                    display: block;
                    max-width: 170px;
                    margin-top: 20px;
                    border: 1px solid #b9b9b9;
                  "
                >
                  <img src="/assets/img/chrome_web_1.png" loading="lazy" style="margin-top: 0px" />
                </a>

                {{ $store.main.getters.deferredPrompt }} == $store.main.getters.deferredPrompt
               -->
                <div
                  v-if="$store.main.getters.deferredPrompt"
                  @click="$store.dispatch('promptInstall')"
                  class=" "
                  target="_blank"
                  style="padding: 0px; max-width: 170px; margin-top: 10px; color: #fff"
                >
                  <img
                    src="/img/pwa-install-badge-1.png"
                    alt="Install OnlyBots app"
                    loading="lazy"
                    style="margin-top: 0px; cursor: pointer"
                    width="170"
                  />
                </div>
              </div>

              <div class="column">
                <div class="hidden-logged">
                  <div class="g-signin2" data-onsuccess="onSignIn"></div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <!--
      <div class="column is-2">
        <div class="hidden-logged">
          <div class="g-signin2 " data-onsuccess="onSignIn"  ></div>
          <br>
        </div>
    </div>
  -->
          <div class="column is-8">
            <footmenu />
          </div>
        </div>

        <!--
  <a href="https://www.buymeacoffee.com/OnlyBots" target="_blank" rel="nofollow">
    <img src="https://img.buymeacoffee.com/button-api/?text=Support us&amp;emoji=🚀&amp;slug=OnlyBots&amp;button_colour=fc1678&amp;font_colour=ffffff&amp;font_family=Arial&amp;outline_colour=000000&amp;coffee_colour=FFDD00">
  </a>
  <br> -->
        <p>
          <small class="muted">
            All product names, logos, brands and dogs are property of their respective owners.
            <br />
            Feel free to check out our
            <router-link to="/legal/privacy">privacy policy </router-link> and
            <router-link to="/legal/terms">terms of service</router-link>.
          </small>
        </p>
        <br /><br />
        <div class="clear"></div>

        <div class="copyright is-hidden-desktop">
          <p class="small">© {{ currentYear }}. OnlyBots</p>
        </div>
        <br /><br />
      </article>
    </section>
  </div>
</template>
<script>
import footmenu from "./footmenu.vue"; //NEED REFACTORING!
//import logo from "@/components/e/logo.vue";
export default {
  name: "footerComponent",
  components: {
    // logo,
    footmenu,
    //  Navbar: Navbar,
  },
  computed: {
    hideFoot: function () {
      return false;
      if (this.$route.query.pwa) return true;
      //hide in the kinda-login page that we trigger on logged-out pwa installs. it has no route name...
      return this.$store.main.getters.hideFoot;
    },
    hideFootMobile: function () {
      return this.$store.main.getters.hideFootMobile;
    },
    profile() {
      return this.$store.main.getters.profile;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style scoped>
.muted {
  opacity: 0.3;
}

.foot .inviteBrand img {
  max-height: 70px;
  margin-right: 3rem;
  margin-bottom: 20px;
}
.foot .inviteBrand {
  margin-bottom: 1rem;
}

.foot .socialicon {
  font-size: 1.5rem;
  margin-right: 10px;
  opacity: 0.3;
}

.foot {
  background: linear-gradient(139deg, #ffc92f, #ff185c, #f316d5, #b81ed2);
  background: linear-gradient(29deg, #1e2023, #2e3946);

  min-height: 300px;
  padding-top: 4rem;
  padding-bottom: 1rem;

  /*  border-top: #3de3fe 3px solid;*/
}
.foot,
.foot a,
.foot h5,
.foot .socialicon {
  color: #fff;
}
.foot a:hover {
  /*color:white;*/
}

.foot li a {
  font-size: 0.8rem;
}
</style>
