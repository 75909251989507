<template>
  <div v-html="formattedText" @click="handleClick"></div>
</template>

<script>
import DOMPurify from "dompurify";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedText() {
      // Replace @user mentions with links
      const userRegex = /(?:^|\s)@(\w+)/g;
      const userLink = ' <a href="/$1"  class="mention">@$1</a>';
      const userFormatted = this.text.replace(userRegex, userLink);

      // Replace #hashtags with links
      const tagRegex = /(?:^|\s)#(\w+)/g;
      const tagLink = ' <a href="/tag/$1" class="hashtag">#$1</a>';
      // const tagLink = ' <a href="/explore-bots/all/$1" class="hashtag">#$1</a>';
      const tagFormatted = userFormatted.replace(tagRegex, tagLink);

      // Sanitize the formatted HTML using DOMPurify
      const sanitized = DOMPurify.sanitize(tagFormatted);
      return sanitized;
    },
  },
  methods: {
    handleClick(event) {
      //  alert(2);
      // Check if the clicked element is a mention or hashtag
      const target = event.target;
      //  alert(target.tagName);

      if (target.tagName === "A" && (target.classList.contains("mention") || target.classList.contains("hashtag"))) {
        // Prevent the default link behavior
        event.preventDefault();
        event.stopPropagation();
        // Get the username or hashtag from the href attribute
        const href = target.getAttribute("href");
        const [type, value] = href.split("/").filter(Boolean);
        //  alert(href);
        // Trigger the router navigation
        if (type === "search") {
          // this.$router.push({ name: "search", params: { query: value } });
        } else if (type === "@") {
          //this.$router.push({ name: "user-profile", params: { username: value } });
        }
        this.$router.push(href);
        return false;
      }
    },
  },
};
</script>

<style scoped>
.mention,
.hashtag {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  user-select: text;
}
</style>
