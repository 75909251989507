/* eslint-disable no-console */

// See:
// https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip

import { register } from "register-service-worker";

const cacheBust = process.env.APP_BUILD_DATE || "MISSING_APP_BUILD_DATE"; // provided by webpack, prevents cloudflare caching of the ress.

if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    // service-worker.js?v=${cacheBust}`, { works well, but weird behavior for users using all while refresh is happening
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" + "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered(registration) {
      // console.log("Service worker has been registered.");
      console.log("Service worker has been registered and now polling for updates.");
      setInterval(() => {
        registration.update();
      }, 60 * 1000); // every 5 seconds
    },

    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated(registration) {
      console.log("New content is available; please refresh. Dispatching swUpdated event.");
      document.dispatchEvent(new CustomEvent("swUpdated", { detail: registration }));
    },
    offline() {
      console.log("No internet connection found. App is running in offline mode.");
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });

  if (navigator.serviceWorker) {
    navigator.serviceWorker.addEventListener("controllerchange", () => {
      window.location.reload(true); // kinda-force refresh everything. also breks the statte, so we could use a modall...
    });
  }
}
