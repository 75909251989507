var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.$store.main.state.userToken)?_c('div',{staticClass:"titleBar is-hidden-desktop",class:{
    pitshow: _vm.pitshow || !_vm.pitable,
    pitable: _vm.pitable,
    offScreen: _vm.y < (_vm.getShowAfterScroll || 0),

    fixed: !_vm.isInline && !_vm.pitable,
  }},[(!_vm.isChild)?_c('div',{staticClass:"topSpacer"}):_vm._e(),_vm._v(" "),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.pitable && false),expression:"pitable && false"}],staticClass:"box",staticStyle:{"position":"fixed","z-index":"333","bottom":"200px","left":"0"}},[_c('hr'),_vm._v(" "),_c('hr'),_vm._v("\n    "+_vm._s(_vm.pitshow)+"\n\n    "),_c('hr'),_vm._v("\n    "+_vm._s(_vm.speed)+"\n    "),_c('hr'),_vm._v("\n    "+_vm._s(_vm.direction)+"\n\n    "),_c('hr'),_vm._v("\n    "+_vm._s(_vm.currentScrollPosition)+"\n    "),_c('hr')]),_vm._v(" "),_c('div',{staticClass:"barWrap",class:{
      offScreen: _vm.y < (_vm.getShowAfterScroll || 0),

      fixed: !_vm.isInline,
    }},[_vm._t("before"),_vm._v(" "),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.title || _vm.back),expression:"title || back"}],staticClass:"bar",class:{
        colored: _vm.theme == 'colored',
        white: _vm.theme == 'white',
        scrollTopOnClick: _vm.scrollTopOnClick,
        hasShadow: _vm.hasShadow,
      }},[(_vm.theme != 'colored' && !_vm.isInline)?_c('div',{staticClass:"spacer m is-hidden-mobile"}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"container containerMargins",class:_vm.containerClass || 'w1400'},[_c('div',{staticClass:"columns is-1 is-variable is-mobile is-vcentered",staticStyle:{"width":"100%","text-align":"left"}},[(_vm.back)?_c('div',{staticClass:"column is-narrow",class:{ 'is-hidden-tablet': !_vm.showBackArrowDesktop }},[(_vm.back == 'auto')?_c('div',{staticClass:"button is-light",staticStyle:{"background":"none","border":"none"},on:{"click":_vm.prev}},[_c('span',{staticClass:"icon has-text-infoNOT"},[_c('i',{class:'fas fa-' + _vm.getIconArowBack})])]):_vm._e(),_vm._v(" "),(_vm.back == 'event')?_c('div',{staticClass:"button is-light",staticStyle:{"background":"none","border":"none"},on:{"click":_vm.triggerPrevEvent}},[_c('span',{staticClass:"icon has-text-infoNOT"},[_c('i',{class:'fas fa-' + _vm.getIconArowBack})])]):_vm._e(),_vm._v(" "),(_vm.back != 'event' && _vm.back != 'auto')?_c('router-link',{staticClass:"button is-light",staticStyle:{"background":"none","border":"none"},attrs:{"to":_vm.back}},[_c('span',{staticClass:"icon has-text-infoNOT"},[_c('i',{class:'fas fa-' + _vm.getIconArowBack})])]):_vm._e()],1):_vm._e(),_vm._v(" "),(_vm.isHome)?_c('img',{staticClass:"logo",attrs:{"src":"/img/brand/ob_logotype_black.svg","alt":"OnlyBots"},on:{"click":_vm.clickHeader}}):_vm._e(),_vm._v(" "),(_vm.img)?_c('div',{staticClass:"column is-narrow"},[_c('div',{staticClass:"squircle",class:{ avatar: _vm.isAvatar },staticStyle:{"width":"40px","height":"40px"},on:{"click":_vm.clickHeader}},[_c('img',{staticClass:"avatar",attrs:{"src":_vm.img,"loading":"lazy"}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column",class:{
              'text-align-center': _vm.centered,
            }},[_vm._t("header",function(){return [(_vm.title)?_c('h1',{staticClass:"title ellipsis",class:{
                  'is-1': _vm.getTitleSize == 1,
                  'is-2': _vm.getTitleSize == 2,
                  'is-3': _vm.getTitleSize == 3,
                  'is-4': _vm.getTitleSize == 4,
                  'is-5': _vm.getTitleSize == 5,
                  'is-6': _vm.getTitleSize == 6,
                  'is-7': _vm.getTitleSize == 7,
                  'is-8': _vm.getTitleSize == 8,
                },on:{"click":_vm.clickHeader}},[_vm._v("\n                "+_vm._s(_vm.title)+"\n                ")]):_vm._e(),_vm._v(" "),(_vm.subtitle)?_c('h1',{staticClass:"subtitle is-7",on:{"click":_vm.clickHeader}},[_vm._v("\n                "+_vm._s(_vm.subtitle)+"\n              ")]):_vm._e()]})],2),_vm._v(" "),((_vm.actionsShown && _vm.actionsShown.length) || (_vm.actionsEllipsis && _vm.actionsEllipsis.length))?_c('div',{staticClass:"column is-narrow"},[_vm._l((_vm.actionsShown),function(i,index){return _c('router-link',{key:i.icon + i.to + index,class:{
                'is-hidden-desktop': i.hiddenDesktop,
                'is-hidden-mobile': i.hiddenMobile,
                'is-hidden-tablet': i.hiddenTablet,
              },attrs:{"to":i.to,"replace":i.replace}},[(_vm.buttonStyle == 'small')?_c('div',{staticClass:"button is-light",staticStyle:{"background":"none","border":"none"}},[_c('span',{staticClass:"icon has-text-info"},[_c('i',{class:_vm.getIconPack + '  fa-' + i.icon})])]):_vm._e(),_vm._v(" "),(_vm.buttonStyle == 'round')?_c('div',{staticClass:"button is-light is-floating is-rounded is-darkNO is-small",class:{ transparentBg: _vm.actionButtonRoundBg == 'transparent' },staticStyle:{"margin-left":"5px"}},[_c('span',{staticClass:"icon has-text-infoNOOO"},[_c('i',{class:_vm.getIconPack + '  fa-' + i.icon}),_vm._v(" "),(i.specialNum == 'notif' && _vm.$store.main.getters.unreadNotifP)?_c('div',{staticClass:"tag is-danger",staticStyle:{"max-width":"40px"}},[_vm._v("\n                    "+_vm._s(_vm.$store.main.getters.unreadNotifP)+"\n                  ")]):_vm._e(),_vm._v(" "),(i.specialNum == 'msg' && _vm.$store.main.getters.unreadMsgP)?_c('div',{staticClass:"tag is-danger is-hidden-tablet",staticStyle:{"max-width":"40px"}},[_vm._v("\n                    "+_vm._s(_vm.$store.main.getters.unreadMsgP)+"\n                  ")]):_vm._e()])]):_vm._e()])}),_vm._v(" "),(_vm.actionsEllipsis && _vm.actionsEllipsis.length)?_c('b-dropdown',{staticClass:"fab-drop",staticStyle:{"margin-left":"10px"},attrs:{"has-link":"","triggers":['click'],"aria-role":"menu","position":"is-bottom-left"},scopedSlots:_vm._u([{key:"trigger",fn:function({ toggle }){return [_c('div',{staticClass:"button is-floating is-rounded is-light is-small",class:{ transparentBg: _vm.actionButtonRoundBg == 'transparent' },attrs:{"uselessProp":toggle}},[_c('span',{staticClass:"icon"},[_c('i',{staticClass:"fas fa-large fa-ellipsis-h"})])])]}}],null,false,3543944295)},[_vm._v(" "),_vm._l((_vm.actionsEllipsis),function(i,index){return _c('router-link',{key:'fgdsnu8' + index,class:{
                  'is-hidden-desktop': i.hiddenDesktop,
                  'is-hidden-mobile': i.hiddenMobile,
                  'is-hidden-tablet': i.hiddenTablet,
                },attrs:{"to":i.to && !i.event ? i.to : '#',"tag":i.event ? 'div' : 'a'},nativeOn:{"click":function($event){return _vm.triggerEvent(i.event)}}},[_c('b-dropdown-item',{attrs:{"aria-role":"menuitem"}},[_c('span',{staticClass:"icon has-text-infoNO"},[_c('i',{class:'fal  fa-' + i.icon})]),_vm._v(" "),_c('span',[_vm._v(_vm._s(i.label)+" ")])])],1)})],2):_vm._e()],2):_vm._e(),_vm._v(" "),(_vm.btLabel)?_c('div',{staticClass:"column is-narrow"},[_c('router-link',{staticClass:"button is-dark",staticStyle:{"border-radius":"10px","font-weight":"bold"},attrs:{"to":_vm.btTo}},[(_vm.btIcon)?_c('span',{staticClass:"icon has-text-infoNOT"},[_c('i',{class:_vm.getIconPack + '  fa-' + _vm.btIcon})]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(_vm.btLabel))])])],1):_vm._e(),_vm._v(" "),(_vm.showSignup && !_vm.$store.main.getters.isLoggedIn)?_c('div',{staticClass:"column is-narrow"},[_c('router-link',{staticClass:"",staticStyle:{"border-radius":"10px"},attrs:{"to":"/signup"}},[_c('b-button',{staticStyle:{"border-radius":"10px","font-weight":"bold"},attrs:{"type":"is-dark","icon-right":"arrow-right","icon-pack":"fal"}},[_vm._v("\n                Sign up free\n              ")])],1)],1):_vm._e()])])]),_vm._v(" "),_vm._t("after")],2)]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }