const QUERIES_EXAMPLES = [
  // Textual Queries
  {
    title: "Vegetarian pasta recipes.",
    description: "Provide a recipe for a hearty vegetarian lasagna that uses seasonal vegetables.",
  },
  {
    title: "Photography tips.",
    description: "How do I capture the Milky Way with a DSLR camera?",
  },
  {
    title: "Home gardening advice.",
    description: "Tips on growing organic tomatoes in my backyard.",
  },
  {
    title: "Best hiking trails in Canada.",
    description: "Looking for scenic and moderately challenging trails in the Banff National Park.",
  },
  {
    title: "Wedding anniversary gift ideas.",
    description: "Gift ideas for my spouse for our 10th wedding anniversary.",
  },
  {
    title: "Ecotourism destinations.",
    description: "Places around the world where I can travel sustainably and ethically.",
  },
  {
    title: "Vegan baking.",
    description: "A delicious recipe for vegan chocolate chip cookies.",
  },
  {
    title: "Eco-friendly household hacks.",
    description: "Ways to make my home more environmentally friendly.",
  },
  {
    title: "Mindfulness exercises.",
    description: "Techniques to practice mindfulness and reduce anxiety daily.",
  },
  {
    title: "Origami tutorials.",
    description: "How to make an origami crane using standard letter-sized paper.",
  },
  {
    title: "Mystery book recommendations.",
    description: "Looking for top-rated mystery novels from the past five years.",
  },
  {
    title: "Laptop buying guide.",
    description: "Best laptops for graphic design purposes under $1500.",
  },
  {
    title: "Homemade face mask recipes.",
    description: "Natural ingredients to make a moisturizing face mask.",
  },
  {
    title: "Acoustic guitar songs for beginners.",
    description: "A list of popular and easy songs to learn on the acoustic guitar.",
  },
  {
    title: "Digital art tutorials.",
    description: "How to start with digital drawing using a tablet and free software.",
  },
  {
    title: "Board games for family night.",
    description: "Top board games suitable for both adults and children.",
  },
  {
    title: "Travel packing hacks.",
    description: "Efficient ways to pack a suitcase for a two-week trip.",
  },
  {
    title: "STEM activities for kids.",
    description: "Science experiments to do at home using kitchen ingredients.",
  },
  {
    title: "Tea pairing guide.",
    description: "The best types of teas to drink with various meals.",
  },
  {
    title: "Home workout routines.",
    description: "Total body workout plan with just bodyweight exercises.",
  },
  {
    title: "Vintage fashion trends.",
    description: "Popular fashion trends from the 1950s and how to incorporate them into modern outfits.",
  },
  {
    title: "Slow cooker recipes.",
    description: "A recipe for a vegetarian slow cooker chili.",
  },
  {
    title: "DIY pet toys.",
    description: "How to create toys for cats using recycled materials.",
  },
  {
    title: "Sustainable travel tips.",
    description: "Ways to minimize carbon footprint while traveling.",
  },
  {
    title: "Best superhero comics.",
    description: "Top 10 superhero comics for someone new to the genre.",
  },
  {
    title: "Online business ideas.",
    description: "List of potential businesses I can start from home with minimal investment.",
  },
  {
    title: "Homemade soap recipes.",
    description: "A guide to making lavender-scented soap at home.",
  },
  {
    title: "Bird-watching guide.",
    description: "Best places to spot rare birds in the Pacific Northwest.",
  },
  {
    title: "Best rock climbing locations.",
    description: "Top locations in the US for intermediate rock climbers.",
  },
  {
    title: "Digital marketing strategies.",
    description: "Effective ways to grow my online business using social media.",
  },

  // Visual Search Queries for Image Generation
  {
    title: "Mountain Landscape",
    description: "Image of a serene mountain landscape at sunset.",
  },
  {
    title: "Roaring Lion Sketch",
    description: "Sketch of a roaring lion.",
  },
  {
    title: "City Market Scene",
    description: "Photo of a bustling city market in Southeast Asia.",
  },
  {
    title: "Space Colony Rendering",
    description: "Artistic rendering of a space colony.",
  },
  {
    title: "Mermaid Illustration",
    description: "Illustration of a mermaid lounging on a rock by the sea.",
  },
  {
    title: "White Sand Beach",
    description: "Image of a pristine white sand beach with turquoise waters.",
  },
  {
    title: "Futuristic Cityscape Art",
    description: "Concept art of a futuristic cityscape.",
  },
  {
    title: "Japanese Tea Ceremony Picture",
    description: "Picture of a traditional Japanese tea ceremony.",
  },
  {
    title: "Knight Defending Castle Art",
    description: "Art of a knight defending a castle.",
  },
  {
    title: "Dragon Flight",
    description: "Digital art of a dragon soaring above the clouds.",
  },
];

export { QUERIES_EXAMPLES };
//  import {QUERIES_EXAMPLES} from  from "@/data/queriesExamples.js";
