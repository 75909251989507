<template>
  <div class="full-page-search is-hidden-desktop" :class="{ isSearching: isSearching }">
    <div class="search-bar">
      <input
        ref="searchInput"
        v-model="query"
        @focus="isSearching = true"
        @blur="onInputBlur"
        @keyup="search"
        @keyup.esc="hideSearchResults"
        placeholder="Search..."
        class="search-input"
        icon="fas fa-search"
        icon-pack="fas"
      />

      <!--

         <b-field label="Search" class="control">
          <b-input
            placeholder="Search for a user, post, or topic"
            icon="fas fa-search"
            icon-pack="fa"
            v-model="search"
            @input="searchInput"
          ></b-input>
        </b-field>

    -->
      <button v-if="isSearching" @click="hideSearchResults" class="cancel-btn">Cancel</button>
    </div>
    <div v-if="isSearching" class="search-results">
      <div v-if="searchResults.length === 0" class="no-results">
        <div v-if="query.length > 0" class="no-results">No results found</div>
        <div v-else class="no-results">Search for something</div>
      </div>
      <div v-else class="results">
        <bot-list
          :bots="searchResults"
          :results-visible="resultsVisible"
          @click="keepResultsVisible"
          :page-size="10"
          layout="list"
        >
          <!-- pass #empty template slot 
          <template #empty>
            <div class="empty no-results">No results found</div>
          </template>-->
        </bot-list>

        <div v-for="(result, index) in searchResults" :key="index" class="result"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      query: this.initValue || "",
      searchResults: [],
      isSearching: false,
      resultsVisible: false,
      hideResultsTimeoutId: null,
    };
  },
  props: {
    initValue: {
      type: String,
      default: "",
    },
  },
  methods: {
    async search() {
      // perform search and update searchResults
      var q = this.query;
      if (!q) return (this.searchResults = []);

      this.searchResults = await window.API.searchBots({ search: this.query });
      // ["result 1", "result 2", "result 3"];
    },
    onInputBlur() {
      this.hideResultsTimeoutId = setTimeout(() => {
        //  if (!this.keepSearchResultsVisible) {
        this.showSearchResults = false;
        this.hideSearchResults();
        // this.$refs.searchInput.blur();
        // }
      }, 500);
    },
    hideSearchResults() {
      this.blurSearchInput();
      if (this.resultsVisible) {
        this.resultsVisible = false;
      } else {
        this.isSearching = false;
        // wait for transition to finish before resetting query and searchResults
        this.query = "";
        this.searchResults = [];
        /*
        setTimeout(() => {
          this.query = "";
          this.searchResults = [];
        }, 600);*/
        //need to wait for click to ficich, it's blurring on mousedown, executing on mouseup
      }
    },
    clearHideResultsTimeout() {
      clearTimeout(this.hideResultsTimeoutId);
    },
    onSearchResultsClose() {
      this.showSearchResults = false;
      this.clearHideResultsTimeout();
    },
    blurSearchInput() {
      if (this.$refs.searchInput && this.$refs.searchInput.matches(":focus")) {
        this.$refs.searchInput.blur();
      }
    },
    keepResultsVisible() {
      this.resultsVisible = true;
    },
  },
};
</script>

<style scoped>
.full-page-search {
  position: relative;
}
.search-input {
  width: 100%;
  height: 50px;
  padding: 0 16px;
  font-size: 20px;
  border: none;
  border-bottom: 1px solid #ddd;
  box-sizing: border-box;
}

.isSearching .search-bar {
  position: fixed;
  background: red;
  top: 0;
  left: 0;
  width: 100%;
  height: 90px;
}

.search-results {
  background: blue;
}
.cancel-btn {
  position: absolute;
  top: 0;
  right: 16px;
  height: 50px;
  padding: 0 16px;
  font-size: 20px;
  border: none;
  background-color: transparent;
}
.search-results {
  position: fixed;
  top: 50px;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  background-color: #fff;
  overflow: auto;
  padding: 16px;
  transition: opacity 0.3s;
  opacity: 1;
  z-index: 1000;
}
.search-results.fade-out {
  opacity: 0;
  pointer-events: none;
}
.no-results {
  font-size: 24px;
  text-align: center;
  margin-top: 50px;
}
.results {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 50px;
}
.result {
  font-size: 20px;
  padding: 16px;
  background-color: #f9f9f9;
  border-radius: 8px;
}
</style>
