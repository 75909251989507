<template>
  <div
    v-if="$store.main.state.userToken"
    class="titleBar is-hidden-desktop"
    :class="{
      pitshow: pitshow || !pitable,
      pitable: pitable,
      offScreen: y < (getShowAfterScroll || 0),

      fixed: !isInline && !pitable,
    }"
  >
    <!--    -->
    <div class="topSpacer" v-if="!isChild"></div>

    <div class="box" style="position: fixed; z-index: 333; bottom: 200px; left: 0" v-show="pitable && false">
      <hr />
      <hr />
      {{ pitshow }}

      <hr />
      {{ speed }}
      <hr />
      {{ direction }}

      <hr />
      {{ currentScrollPosition }}
      <hr />
    </div>

    <div
      class="barWrap"
      :class="{
        offScreen: y < (getShowAfterScroll || 0),

        fixed: !isInline,
      }"
    >
      <slot name="before"></slot>
      <section
        v-show="title || back"
        class="bar"
        :class="{
          colored: theme == 'colored',
          white: theme == 'white',
          scrollTopOnClick: scrollTopOnClick,
          hasShadow: hasShadow,
        }"
      >
        <!-- fix for top margin on desktop with colored frame border.-->
        <div class="spacer m is-hidden-mobile" v-if="theme != 'colored' && !isInline"></div>

        <div class="container containerMargins" :class="containerClass || 'w1400'" style="">
          <div class="columns is-1 is-variable is-mobile is-vcentered" style="width: 100%; text-align: left">
            <div class="column is-narrow" v-if="back" :class="{ 'is-hidden-tablet': !showBackArrowDesktop }">
              <!-- show the button back. now decide if it's a link, or if we invoke browser-previous-->
              <div v-if="back == 'auto'" @click="prev" class="button is-light" style="background: none; border: none">
                <span class="icon has-text-infoNOT">
                  <!-- platform specefic icon! yeah! -->
                  <i :class="'fas fa-' + getIconArowBack"></i>
                </span>
              </div>
              <div
                v-if="back == 'event'"
                @click="triggerPrevEvent"
                class="button is-light"
                style="background: none; border: none"
              >
                <span class="icon has-text-infoNOT">
                  <!-- platform specefic icon! yeah! -->

                  <i :class="'fas fa-' + getIconArowBack"></i>
                </span>
              </div>
              <router-link
                v-if="back != 'event' && back != 'auto'"
                :to="back"
                class="button is-light"
                style="background: none; border: none"
              >
                <span class="icon has-text-infoNOT">
                  <!-- platform specefic icon! yeah! -->

                  <i :class="'fas fa-' + getIconArowBack"></i>
                </span>
              </router-link>
            </div>

            <img
              v-if="isHome"
              src="/img/brand/ob_logotype_black.svg"
              class="logo"
              @click="clickHeader"
              alt="OnlyBots"
            />

            <div class="column is-narrow" v-if="img">
              <div
                class="squircle"
                :class="{ avatar: isAvatar }"
                style="width: 40px; height: 40px"
                @click="clickHeader"
              >
                <!--  style="padding:5px; background:#fff; width:100px; height:100px;" -->
                <img :src="img" class="avatar" loading="lazy" />
                <!-- 
                <b-image
                  :src="img"
                  class="avatar"
                  loading="lazy"
                  :height="400"
                  style="height: 200px"
                  :src-fallback="imgFallback || 'https://only-bots.com/img/default_avatar.png'"
                />-->
              </div>
            </div>

            <div
              class="column"
              :class="{
                'text-align-center': centered,
              }"
            >
              <slot name="header">
                <!-- USE this to pass complete elements instead of title...-->
                <h1
                  class="title ellipsis"
                  :class="{
                    'is-1': getTitleSize == 1,
                    'is-2': getTitleSize == 2,
                    'is-3': getTitleSize == 3,
                    'is-4': getTitleSize == 4,
                    'is-5': getTitleSize == 5,
                    'is-6': getTitleSize == 6,
                    'is-7': getTitleSize == 7,
                    'is-8': getTitleSize == 8,
                  }"
                  @click="clickHeader"
                  v-if="title"
                >
                  {{ title }}
                  <!--  <verifiedBadge v-if="verifiedBadgeUser"></verifiedBadge>
                 - {{ y }}   -->
                </h1>
                <h1 class="subtitle is-7" v-if="subtitle" @click="clickHeader">
                  {{ subtitle }}
                </h1>
              </slot>
            </div>

            <div
              class="column is-narrow"
              v-if="(actionsShown && actionsShown.length) || (actionsEllipsis && actionsEllipsis.length)"
            >
              <router-link
                v-for="(i, index) in actionsShown"
                :to="i.to"
                :replace="i.replace"
                class=""
                :class="{
                  'is-hidden-desktop': i.hiddenDesktop,
                  'is-hidden-mobile': i.hiddenMobile,
                  'is-hidden-tablet': i.hiddenTablet,
                }"
                :key="i.icon + i.to + index"
              >
                <div v-if="buttonStyle == 'small'" class="button is-light" style="background: none; border: none">
                  <span class="icon has-text-info">
                    <i :class="getIconPack + '  fa-' + i.icon"></i>
                  </span>
                </div>
                <div
                  v-if="buttonStyle == 'round'"
                  class="button is-light is-floating is-rounded is-darkNO is-small"
                  :class="{ transparentBg: actionButtonRoundBg == 'transparent' }"
                  style="margin-left: 5px"
                >
                  <span class="icon has-text-infoNOOO">
                    <i :class="getIconPack + '  fa-' + i.icon"></i>

                    <div
                      class="tag is-danger"
                      style="max-width: 40px"
                      v-if="i.specialNum == 'notif' && $store.main.getters.unreadNotifP"
                    >
                      {{ $store.main.getters.unreadNotifP }}
                    </div>
                    <div
                      class="tag is-danger is-hidden-tablet"
                      style="max-width: 40px"
                      v-if="i.specialNum == 'msg' && $store.main.getters.unreadMsgP"
                    >
                      {{ $store.main.getters.unreadMsgP }}
                    </div>
                    <!--
                    <div class="tag is-danger" style="max-width:40px;" v-if="i.specialNum == 'msg' && $store.main.getters.unreadNotifP">
                      {{ $store.main.getters.unreadNotifP }}
                    </div> -->
                  </span>
                </div>
              </router-link>

              <!-- DROP DOWN ELIPSIS-->
              <b-dropdown
                v-if="actionsEllipsis && actionsEllipsis.length"
                has-link
                :triggers="['click']"
                aria-role="menu"
                class="fab-drop"
                position="is-bottom-left"
                style="margin-left: 10px"
              >
                <template #trigger="{ toggle }">
                  <div
                    class="button is-floating is-rounded is-light is-small"
                    :uselessProp="toggle"
                    :class="{ transparentBg: actionButtonRoundBg == 'transparent' }"
                  >
                    <span class="icon">
                      <i class="fas fa-large fa-ellipsis-h"></i>
                    </span>
                  </div>
                </template>
                <!-- -- v-if="!i.hidden && (!i.admin || $store.main.getters.isAdmin)" -->
                <router-link
                  v-for="(i, index) in actionsEllipsis"
                  :to="i.to && !i.event ? i.to : '#'"
                  :tag="i.event ? 'div' : 'a'"
                  class=""
                  :class="{
                    'is-hidden-desktop': i.hiddenDesktop,
                    'is-hidden-mobile': i.hiddenMobile,
                    'is-hidden-tablet': i.hiddenTablet,
                  }"
                  @click.native="triggerEvent(i.event)"
                  :key="'fgdsnu8' + index"
                >
                  <b-dropdown-item aria-role="menuitem">
                    <span class="icon has-text-infoNO">
                      <i :class="'fal  fa-' + i.icon"></i>
                    </span>
                    <span>{{ i.label }} </span>
                  </b-dropdown-item>
                </router-link>
                <!--
                <b-dropdown-item aria-role="menuitem" v-clipboard="() => 'https://invt.co' + url" v-clipboard:success="clipboardSuccessHandler">
                  <span class="icon  ">
                    <i class="fal fa-link"></i>
                  </span>
                  <span>Copy link</span>
                </b-dropdown-item>
  
                <router-link :to="url" class=" ">
                  <b-dropdown-item aria-role="MEHHHH" tag="name">
                    <span class="icon  ">
                      <i class="fal fa-external-link"></i>
                    </span>
                    <span>View </span>
                  </b-dropdown-item>
                </router-link>
  
                <router-link :to="'hehehehe'" class=" ">
                  <b-dropdown-item aria-role="menuitem">
                    <span class="icon  ">
                      <i class="fal fa-retweet"></i>
                    </span>
                    <span>Repost</span>
                  </b-dropdown-item>
                </router-link>
  -->
                <!--
           <b-dropdown-item aria-role="menuitem"> </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem">Stats</b-dropdown-item>
          <b-dropdown-item aria-role="menuitem">
            <span class="icon is-small">
                <i class="fal fa-thumbtack"></i>
              </span>
  
             <span><span v-if="pinned">Unpin</span><span v-else>Pin to top</span></span>
           </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem">Repost</b-dropdown-item>
          <b-dropdown-item aria-role="menuitem">About {{productName}}</b-dropdown-item>
          <b-dropdown-item aria-role="menuitem">Remove  </b-dropdown-item>
        -->
              </b-dropdown>
            </div>

            <div class="column is-narrow" v-if="btLabel">
              <router-link :to="btTo" class="button is-dark" style="border-radius: 10px; font-weight: bold">
                <span class="icon has-text-infoNOT" v-if="btIcon">
                  <i :class="getIconPack + '  fa-' + btIcon"></i>
                </span>
                <span>{{ btLabel }}</span>
              </router-link>
            </div>
            <div class="column is-narrow" v-if="showSignup && !$store.main.getters.isLoggedIn">
              <router-link to="/signup" class="    " style="border-radius: 10px">
                <b-button
                  type="is-dark"
                  icon-right="arrow-right"
                  icon-pack="fal"
                  style="border-radius: 10px; font-weight: bold"
                >
                  Sign up free
                </b-button>
              </router-link>
            </div>
          </div>
        </div>
      </section>
      <slot name="after"></slot>
    </div>
  </div>
</template>
<script>
//import Navbar from '../_ext/popup/components/Navbar'; //NEED REFACTORING!
//import verifiedBadge from "@/components/e/verifiedBadge.vue";

import { throttle, debounce } from "lodash";

export default {
  name: "titlebar",
  components: {
    //   verifiedBadge,
    //  Navbar: Navbar,
  },
  props: [
    "title",
    "subtitle",
    "img",
    "imgFallback",
    "isAvatar",
    "activeDot",
    "verifiedBadgeUser",
    "inline",
    "back",
    "showBackArrowDesktop",
    "backArrowIconDesktop",
    "showDesktop",
    "showAfterScroll",
    "theme",
    "btLabel",
    "btTo",
    "iconPack",
    "btIcon",
    "centered",
    "containerClass",
    "showSignup",
    "scrollTopOnClick",
    "hasShadow",
    "headerTitleLink",
    "titleSize",
    "actionsEllipsis",
    "actionButtonStyle",
    "actionButtonRoundBg",
    "actions", //icon-buttons
    "isHome",
    "isHome2",
    "isChild", //:{default:false},
    "pitable",
  ],
  data() {
    return {
      beLazy: false,
      currentScrollPosition: 0,
      lastScrollPosition: 0,
      lastScrollTimestamp: 0,
      pitshow: false, //relative pos on load
      direction: "NONE", // "UP", "DOWN", or "NONE"
      speed: 0, // in pixels per ms
    };
  },
  watch: {
    pitable(newValue) {
      if (newValue) {
        window.addEventListener("scroll", this.handleScroll);
      } else {
        window.removeEventListener("scroll", this.handleScroll);
      }
    },
  },
  mounted() {
    if (this.pitable) {
      window.addEventListener("scroll", this.handleScroll);
    }
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  computed: {
    /*getBack() {
        if(this.back == 'auto'){
          //TODO: get previous
        }
        return this.back || 'small'; //default
      },*/

    actionsShown() {
      if (!this.actions) return [];
      var filtered = this.actions.filter((i, index) => {
        return !i.hidden; // && (!i.admin || this.$store.main.getters.isAdmin);
      });

      filtered = filtered.filter((i, index) => {
        return i.to; // Invalid when using router-link
        // TODO: SUPPORT ACTIONS
      });

      return filtered;
    },

    actionsEllipsisShown() {
      return this.actionsEllipsis.filter((i, index) => {
        return !i.hidden && (!i.admin || this.$store.main.getters.isAdmin);
      });
    },
    getShowAfterScroll() {
      return this.showAfterScroll || 0; // || 'small'; //default
    },
    isInline() {
      return Boolean(this.inline); // || 'small'; //default
    },
    buttonStyle() {
      return this.actionButtonStyle || "small"; //default
    },
    getIconPack() {
      return this.iconPack || "fas"; //default
    },
    getIconArowBack() {
      var isDesk = window.innerWidth > 900;
      if (this.backArrowIconDesktop && isDesk) return this.backArrowIconDesktop; //times
      if (this.$store.main.getters.isApple) return "chevron-left";
      return "arrow-left"; //default
    },

    getTitleSize() {
      if (!this.titleSize) return 6;
      return Number(this.titleSize);
    },
    y() {
      return this.$store.main.getters.scrollY;
    },
  },
  methods: {
    handleScroll: throttle(function () {
      if (!this.pitable) return;

      const currentScrollPosition = window.pageYOffset || document.documentElement.scrollTop;
      this.currentScrollPosition = currentScrollPosition;
      const currentTimestamp = new Date().getTime();

      const timeElapsed = currentTimestamp - this.lastScrollTimestamp;
      const distanceScrolled = Math.abs(currentScrollPosition - this.lastScrollPosition);

      // Compute speed in pixels/ms
      this.speed = distanceScrolled / timeElapsed;

      const SPEED_THRESHOLD = 0.1;
      const SCROLL_THRESHOLD = 500; // Distance threshold to always show the navbar

      // Determine the scroll direction
      this.direction = currentScrollPosition > this.lastScrollPosition ? "DOWN" : "UP";

      var isTopOfThePage = currentScrollPosition == 0; //we'll remove the sticky behavior if scrolled back to top, for next scroll down

      if (
        isTopOfThePage ||
        (this.direction === "DOWN" && this.speed > SPEED_THRESHOLD && currentScrollPosition > SCROLL_THRESHOLD)
      ) {
        // If scrolled downwards quickly and haven't passed the threshold, hide navbar
        this.pitshow = false;
      } else if (this.direction === "UP") {
        // If scrolled upwards beyond the SCROLL_THRESHOLD or moving up with speed greater than SPEED_THRESHOLD
        if (currentScrollPosition > SCROLL_THRESHOLD || this.speed > SPEED_THRESHOLD) {
          this.pitshow = true;
        }
      }

      /*
      fixed until 500px, then hide + reshow
        if (this.direction === "UP") {
        // If scrolled upwards beyond the SCROLL_THRESHOLD or moving up with speed greater than SPEED_THRESHOLD
        if (currentScrollPosition <= SCROLL_THRESHOLD || this.speed > SPEED_THRESHOLD) {
          this.pitshow = true;
        }
      } else if (
        this.direction === "DOWN" &&
        this.speed > SPEED_THRESHOLD &&
        currentScrollPosition > SCROLL_THRESHOLD
      ) {
        // If scrolled downwards quickly and haven't passed the threshold, hide navbar
        this.pitshow = false;
      }

      */

      console.log("currentScrollPosition:----  ", currentScrollPosition);

      this.lastScrollPosition = currentScrollPosition;
      this.lastScrollTimestamp = currentTimestamp;
    }, 200),

    prev() {
      console.log("GO PREVV title bar");
      //check if we're going back to the same site... or diff page...
      this.$router.go(-1); //goes back to google.com sometimes...
      //alert(543);
    },
    triggerPrevEvent() {
      console.log("GO PREVV title bar event");
      this.$emit("clickPrev"); //will close modal, or do something within parent compo
    },
    triggerEvent(n) {
      if (!n) return true; //continue event cycle
      console.log(" itle bar event:: ", n);
      this.$emit(n); //will close modal, or do something within parent compo;
      return false; //dont follow link.
    },

    clickHeader(d) {
      // alert(334);
      // this.triggerEvent('clickHeader');
      if (this.headerTitleLink) {
        // alert(this.headerTitleLink);
        return this.$router.push(this.headerTitleLink);
      }
      if (!this.scrollTopOnClick) return null;
      window.scroll({ top: 0, left: 0, behavior: "smooth" }); //go back to top baby!
    },
  },
};
</script>
<style scoped>
img.avatar {
  border-radius: 50%;
  object-fit: cover;
  height: 40px;
  width: 50px;
}
.text-align-center .title {
  margin: auto;
}
.logo {
  height: 38px;
  height: 28px;
  margin-left: 10px;
  display: block;
}

.topSpacer {
  height: 56px; /*  covers the space under the */
  background: whitesmoke;
  display: none;
}
.titleBar.fixed .topSpacer {
  display: block;
}
.bar {
  background: transparent;
  width: 100%;
  height: 56px;
}
.barWrap.fixed {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 6;
  top: 0; /* start transitioning from position '0' instead of 'auto' */
  transition: top 0.1s ease-out; /* only transition top property */
}
.barWrap.offScreen {
  top: -90px;
}

.barWrap.fixed .bar {
  padding-top: 8px;
  padding-bottom: 8px;
}

.bar.hidden {
  display: none !important;
}

.bar.colored {
  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
  padding-top: 7px;
  padding-bottom: 8px;
}

.bar.white {
  background: white;
}
#app.scrolled .bar {
  border-bottom: 1px #ccc solid;
  box-shadow: rgb(0 0 0 / 10%) 0px 7px 9px -7px;
}

.bar.hasShadow {
  box-shadow: rgb(0 0 0 / 10%) 0px 7px 9px -7px;
}

.scrollTopOnClick .title,
.scrollTopOnClick img,
.scrollTopOnClick .subtitle {
  cursor: pointer;
}

.title {
  max-width: 400px;
}
@media screen and (max-width: 728px) {
  .title {
    max-width: 200px; /* required to trigger elispsis */
  }
}

.pitable {
  position: relative;
  top: -100px;
  top: 0px;
  width: 100%;
  transition: top 0.3s;
  width: 100%;
  transition: top 0.3s;
  /*
  border: red 4px green;  */
  opacity: 1;
}
/*  for  the pit ar scrollup */
.pitable.pitshow {
  position: sticky; /*  unfortunately dont work with all layout where navbar maybe nested in a child... like inbox */
  position: fixed;
  top: 0;
  /* border: red 4px solid; */

  opacity: 1;
  z-index: 3;
}
</style>
