<template>
  <div>
    <br />
    <!-- For the row component, we'll display each bot in its own card
    <div class="columns">
      <BotCard v-for="bot in bots" :key="bot.handle" :bot="bot"></BotCard>
    </div>
 -->
    <!--  
    <h2 class="title is-3">{{ title }}</h2>
    -->
    <bot-list :bots="bots" :page-size="30" :title="title" layout="swipe"></bot-list>
  </div>
</template>

<script>
import BotList from "@/components/BotList.vue";
import BotCard from "@/components/BotCard.vue";
export default {
  props: {
    bots: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
  },
  components: {
    BotList,
  },
};
</script>

<style scoped>
/* Any specific styles for ExploreRow can be added here. */
</style>
