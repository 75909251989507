<template>
  <div>
    <br />
    <br />
    <h4 class="title is-4">{{ title }}</h4>
    <div class="buttons">
      <router-link :to="tag.to" class="button is-primary is-light" v-for="tag in tags" :key="tag.id">
        <span class="icon is-small">
          <i :class="'far fa-' + tag.icon"></i>
        </span>
        <span> {{ tag.label }}</span>
      </router-link>
    </div>
    <br />
  </div>
</template>

<script>
export default {
  props: {
    tags: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped>
/* You can add any specific styles for this component here. */
</style>
