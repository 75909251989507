import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import { BeforeInstallPromptEvent } from "vue-pwa-install";

export default new Vuex.Store({
  state: {
    packageVersion: process.env.PACKAGE_VERSION || "NA", //set via webpack...
    appBuildDate: process.env.APP_BUILD_DATE || "NA", //work
    NODE_ENV: process.env.NODE_ENV, //working?

    test: "works!!!",
    scrollY: 0, //for some ui based on scroll position
    // deferredPrompt: null,
    deferredPrompt: BeforeInstallPromptEvent, //for pwa install
    userEmail: null,
    profile: null,
    userToken: localStorage.getItem("userToken") || "",
    userId: localStorage.getItem("userId") || "",
    userBots: [],
    userBotsData: [],
  },
  mutations: {
    setUserToken(state, token) {
      state.userToken = token;
      localStorage.setItem("userToken", token);

      window.API.configUserToken(token); //need to use this setter!
      // API.configUserToken(token);
      if (token) {
        state.isLoggedIn = true;
      } else {
        state.isLoggedIn = false;
      }
      return true;
    },
    setUserId(state, i) {
      state.userId = i;
      localStorage.setItem("userId", i);
      window.API.configUserId(i); // for api.me...
    },
    setAvatar(state, a) {
      state.avatar = a;
    },
    setHandle(state, a) {
      state.handle = a;
    },
    setCurrentUserProfile(state, a) {
      state.profile = a; //current user
    },
    setCurrentUserBots(state, a) {
      state.userBotsData = a; //current user
      //get array of handles
      var handles = a.map((b) => b.handle);
      state.userBots = handles;
    },
    setScrollY(state, a) {
      state.scrollY = a;
      // console.log(' storesetScrollY')
    },
    logout(state) {
      // alert("logoutt");
      state.userToken = "";
      localStorage.removeItem("userToken");
      localStorage.removeItem("userId");
      localStorage.clear(); // for all Draft messages saved... prevents cross-acount contamination

      window.API.configUserToken("");
      window.API.configUserId("");
      window.API.clearCache(); //some private stuff was still in memory.

      return true;
      //this.commit("setUnreadNotifP", 0);
      //this.commit("setUnreadMsgP", 0);
      //this.commit("setCoinsBalance", 0);
      //this.commit("setRepostQuotaAvailable", 0);
    },

    // PWA
    setIsPwaStandalone(state, bool) {
      state.isPwaStandalone = bool;
    },
    setIsPwa(state, truefalse) {
      state.isPwa = truefalse;
    },
    setDeferredPrompt(state, deferredPrompt) {
      state.deferredPrompt = deferredPrompt; // Stash the event so it can be triggered later:
    },
    setOs(state, os) {
      state.os = os;
    },
    setBrowserDetect(state, browserDetect) {
      state.browserDetect = browserDetect;
    },

    //auth limits
    ensureLogged(state, msg) {
      //shows login modal when users aren't logged...
      // and return false?
      //alert(msg)
      //USAGE:
      //  this.$store.main.commit('ensureLogged',   "please.. ")
      //console.log('ensureLogged', state.isLoggedIn);
      if (state.isLoggedIn) return true; //that'S cool! skip!
      console.log("ensureLogged. you arent logged!");
      state.authModalMsg = msg || ""; //custom msg to display in popup
      state.showAuthModal = true; //TODO
      return false; //isLoggedTobe returne.d
    },
    toggleAutModal(state) {
      //to be called by the close FN of modal
      state.showAuthModal = !state.showAuthModal;
    },

    triggerExitModal(state) {
      //to be called by the close FN of modal
      if (state.isLoggedIn || state.showAuthModal || state.isIframeLayout) {
        //logged in, or already has a modal open...
        state.showExitModal = false;
        return;
      }
      //has closed before (todo)
      state.showExitModal = true;
    },
    toggleExitModal(state) {
      //to be called by the close FN of modal
      state.showExitModal = !state.showExitModal;
    },

    //FOLLOW
    unfollow(state, id) {
      window.API.me.unfollowBot(id).then((resp) => {
        //console.log('unfollowUser API RESPONSE: ', resp);
        //alert(resp)
      });
      if (!state.profile || !state.profile.following) return false;
      var ar = state.profile.following;
      const index = ar.indexOf(id);
      ar.splice(index, 1);
      //  state.profile.following.push(id);
      //state.profile.following = ar;
    },
    follow(state, id) {
      //  alert('2'+msg)
      //  var msg =
      this.commit("ensureLogged", "To follow this user, please create an account.");
      window.API.me.followBot({ handle: id }).then((resp) => {
        //console.log('unfollowUser API RESPONSE: ', resp);
        //alert(resp)
      });
      if (!state.profile || !state.profile.following) return false;
      var ar = state.profile.following.push(id);
      //const index = ar.indexOf(id);
      //ar.splice(index, 1);
      //  state.profile.following.push(id);
      //state.profile.following = ar;
    },
    addBookmark(state, id) {
      //  alert('2'+msg)
      //  var msg =
      this.commit("ensureLogged", "To bookmark this user, please create an account.");

      if (!state.profile) return false;
      if (!state.profile.bookmarks) state.profile.bookmarks = []; //init array in case it's first bookmark...
      var ar = state.profile.bookmarks.push(id);
    },
    removeBookmark(state, id) {
      if (!state.profile || !state.profile.bookmarks) return false;
      var ar = state.profile.bookmarks;
      const index = ar.indexOf(id);
      ar.splice(index, 1);
    },
  },
  actions: {
    addBookmark({ commit, dispatch }, id) {
      commit("addBookmark", id);
    },
    removeBookmark({ commit, dispatch }, id) {
      commit("removeBookmark", id);
    },

    promptInstall({ state, commit }) {
      //PWA
      // Show the prompt:
      state.deferredPrompt.prompt();

      // Wait for the user to respond to the prompt:
      state.deferredPrompt.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === "accepted") {
          console.log("User accepted the install prompt");
        } else {
          console.log("User dismissed the install prompt");
        }

        state.deferredPrompt = null; //might hide buttons on refusal and approvals...
      });
    },
    logout({ commit }) {
      return new Promise((resolve, reject) => {
        commit("logout");
        commit("setUserToken", "");
        commit("setUserId", "");
        resolve();
      });
    },
    loginGoogle({ state, commit, dispatch }, postedData) {
      console.log("login google - store.");
      return new Promise((resolve, reject) => {
        //var  =
        console.log(postedData, "loginGoogle - sending to server. as-is. different format... messss");

        //ex:
        /*  */

        //commit('auth_request')
        //axios({url: 'http://localhost:3000/login', data: user, method: 'POST' })
        // WIP
        window.API.loginGoogle(postedData)
          .then((resp) => {
            console.log("RESPONSE SERVER  loginGoogle: ", resp);
            console.log(resp);
            if (resp && resp.token) {
              //alert(4444);
              var token = resp.token || resp.status;
              //this.state.avatar = resp.data.avatar
              //token += Math.random();
              /*
              avatar
: 
"https://api.only-bots.com/special/avatars/felix699874563212356.svg"
handle:  "felix"
token : "eyJhbGciOiJIUzI1NiIsInR5c RF2XCK1HfxFRY0ud9peklA"
userId : "felix"
*/

              commit("setAvatar", resp.avatar);
              commit("setHandle", resp.handle);
              console.log("TOKEN RECEIVED", token);
              commit("setUserToken", token);
              commit("setUserId", resp.userId);
              state.showAuthModal = false; //if we logged from a popup...

              try {
                //ignore next line es
                // eslint-disable-next-line
                google.accounts.id.cancel();
              } catch (e) {
                console.log("google.accounts.id.cancel() failed", e);
              }
              //load th efresh data!
              dispatch("fetchCurrentUserProfile"); //can be called on any profile update from setting components.
              dispatch("fetchCurrentUserBots");
              resolve(resp);
            } else {
              console.log(resp);
              dispatch("logout"); // server wasn't happy with the login  pass..
              reject(resp);
            }
            //commit('auth_success', token, postedData)
          })
          .catch((err) => {
            commit("setUserToken", "");
            commit("setUserId", "");
            reject(err);
          });
      });
    },
    loginPass({ commit, dispatch }, postedData) {
      console.log("login pass - store.");
      //console.log(user)
      return new Promise((resolve, reject) => {
        //var  =
        console.log(postedData);
        //commit('auth_request')
        //axios({url: 'http://localhost:3000/login', data: user, method: 'POST' })
        window.API.loginPass(postedData)
          .then((data) => {
            console.log("dataONSE SERVER 4324329999: ", data);
            console.log(data);
            if (data && data.token) {
              //  alert('YAYYYY')
              var token = data.token || data.status;
              //this.state.avatar = data.avatar
              //token += Math.random();
              commit("setAvatar", data.avatar);
              commit("setHandle", data.handle);
              commit("setUserToken", token);
              commit("setUserId", data.userId);

              //const bodyEl = document.body;
              //bodyEl.classList.add('hideGoogleOnTap');

              //load th efresh data!
              dispatch("fetchCurrentUserProfile"); //can be called on any profile update from setting components.
              dispatch("fetchCurrentUserBots");
              resolve(data);
            } else {
              //  alert('no data nor token...')
              console.log(data);
              dispatch("logout"); // server wasn't happy with the login  pass..
              reject(data);
            }
            //commit('auth_success', token, postedData)
          })
          .catch((err) => {
            commit("setUserToken", "");
            commit("setUserId", "");
            reject(err);
          });
      });
    },
    setProfileToken({ commit, dispatch }, p) {
      //to be called by alt method, like OTP.
      commit("setAvatar", p.avatar);
      commit("setHandle", p.handle);
      commit("setUserToken", p.token);
      commit("setUserId", p.userId);
      dispatch("fetchCurrentUserProfile");
    },
    fetchCurrentUserProfile({ commit, dispatch }, options) {
      //alert('refresh profile!')
      // return the Promise via `store.dispatch()` so that we know
      // when the data has been fetched
      var noCacheOpt = options || {};
      console.log("///fetchCurrentUserProfile: ", options);
      return (
        window.API.me
          // { noCache: 1 }
          .userProfileOwner(noCacheOpt) // will use current user by default - if any - heavier than public profile. has all the stuff!
          .then((data) => {
            if (!data) return null;
            console.log("👨👨 RESPONSE SERVER. fetchCurrentUserProfile : ", data);
            console.log(data);
            commit("setCurrentUserProfile", data);

            // dispatch("updateCounts");
            //TODO:
          })
          .catch((err) => {
            console.warn(" [Store] CANT fetchCurrentUserProfile, why did we call it ? ");
            //dispatch('logout')
            //    alert('error profile..')
            //commit("setUserToken", '');
            //commit("setUserId", '')
            //reject(err)
          })
      );
    },
    fetchCurrentUserBots({ commit, dispatch }, options) {
      //alert('refresh profile!')
      // return the Promise via `store.dispatch()` so that we know
      // when the data has been fetched
      var noCacheOpt = { owner: true }; // options || {};
      console.log("///fetchCurrentUserBots: ", options);
      return (
        window.API.me
          // { noCache: 1 }
          .getUserBots(noCacheOpt) // will use current user by default - if any - heavier than public profile. has all the stuff!
          .then((data) => {
            if (!data) return null;
            console.log("👨👨 RESPONSE SERVER. fetchCurrentUserBots : ", data);
            console.log(data);
            commit("setCurrentUserBots", data);

            // dispatch("updateCounts");
            //TODO:
          })
          .catch((err) => {
            console.warn(" [Store] CANT fetchCurrentUserBots, why did we call it ? ");
          })
      );
    },
    /*
    configurePlayground({ commit, dispatch }, p) {
      //to be called by alt method, like OTP.
      commit("setAvatar", p.avatar);
      commit("setHandle", p.handle);
      commit("setUserToken", p.token);
      commit("setUserId", p.userId);
      dispatch("fetchCurrentUserProfile");
    },*/
  },
  getters: {
    NODE_ENV: (state) => {
      return state.NODE_ENV;
    },
    profile: (state) => {
      return state?.profile; //userId == "felix"; //state.todos.filter(todo => todo.done)
    },
    hasPages(state) {
      // if (!this.$store.main.profile) return false;
      return state?.profile?.hasPages || false;
    },
    hasSubs(state) {
      // if (!this.$store.main.profile) return false;
      return state?.profile?.hasSubs || false;
    },
    isAdmin: (state) => {
      return state.userId == "felix"; //state.todos.filter(todo => todo.done)
    },
    userToken: (state) => {
      return state.userToken; //== "felix"; //state.todos.filter(todo => todo.done)
    },
    isLoggedIn: (state) => {
      if (state.userId) return true;
      return false;
    },
    userId: (state) => {
      return state.userId;
    },
    handle: (state) => {
      return state.userId;
    },
    profileAvatar: (state) => {
      if (state.profile) return state.profile.avatar;
      return "";
    },
    profileHandle: (state) => {
      if (state.profile) return state.profile.handle;
      return "";
    },

    profileUrl: (state) => {
      if (state.userId) return "/u/" + state.userId;
      return "";
    },

    // handy following stuff
    following: (state) => {
      // both users + bots. Divide?
      // if (state.profile) return state.profile.following || [];
      if (state.profile) return state.profile.following || [];
      return []; //default
    },
    //bookmarks
    bookmarks: (state) => {
      // both users + bots. Divide?
      // if (state.profile) return state.profile.following || [];
      if (state.profile) return state.profile.bookmarks || [];
      return []; //default
    },
    validSubscriptions: (state) => {
      if (state.profile) return state.profile.validSubscriptions || [];
      return []; //default
    },
    savedReferralPrograms: (state) => {
      if (state.profile) return state.profile.savedProductSlugs || [];
      return []; //default
    },
    isApple: (state) => {
      //app is installed on homescreen
      return state.os == "ios" || state.os == "macos";
    },
    userBots: (state) => {
      //app is installed on homescreen
      return state.userBots || [];
    },
    userBotsData: (state) => {
      //app is installed on homescreen
      return state.userBotsData || [];
    },
  },
  modules: {},
});
