import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const API = window.API;

const state = {
  interlocutors: [],
  conversations: {},
  currentConversation: null,
};

const mutations = {
  SET_INTERLOCUTORS(state, interlocutors) {
    state.interlocutors = interlocutors;
  },
  SET_CONVERSATIONS(state, { interlocutorID, conversations }) {
    state.conversations = {
      ...state.conversations,
      [interlocutorID]: conversations,
    };
  },
  ADD_CONVERSATION(state, { interlocutorID, conversation }) {
    const conversations = [...state.conversations[interlocutorID], conversation];
    state.conversations = {
      ...state.conversations,
      [interlocutorID]: conversations,
    };
  },
  SET_CURRENT_CONVERSATION(state, conversation) {
    state.currentConversation = conversation;
  },
  ADD_MESSAGE(state, { interlocutorID, convoID, message }) {
    const conversations = state.conversations[interlocutorID].map((convo) => {
      if (convo.id === convoID) {
        return {
          ...convo,
          messages: [...convo.messages, message],
        };
      } else {
        return convo;
      }
    });
    state.conversations = {
      ...state.conversations,
      [interlocutorID]: conversations,
    };
  },
};

const actions = {
  async fetchInterlocutors({ commit }, { limit, offset }) {
    const interlocutors = await API.getInterlocutors(limit, offset);
    commit("SET_INTERLOCUTORS", interlocutors);
  },
  async fetchInterlocutorConversations({ commit }, interlocutorID) {
    const conversations = await API.getInterlocutorConvos(interlocutorID);
    commit("SET_CONVERSATIONS", { interlocutorID, conversations });
  },
  async fetchConversation({ commit }, { interlocutorID, convoID }) {
    const conversation = await API.getConvo(interlocutorID, convoID);
    commit("SET_CURRENT_CONVERSATION", conversation);
  },
  async replyToConversation({ commit, state }, { interlocutorID, convoID, message }) {
    const conversation = state.conversations[interlocutorID].find((convo) => convo.id === convoID);
    const responseMessage = { text: message, sender: "user" };
    conversation.messages.push(responseMessage);
    conversation.loading = true; // set loading flag to true
    commit("SET_CONVERSATIONS", { interlocutorID, conversations: state.conversations[interlocutorID] });
    const botResponse = await API.replyConvo(convoID, message);
    const botMessage = { text: botResponse.message, sender: "bot" };
    conversation.messages.push(botMessage);
    conversation.loading = false; // turn off loading flag
    commit("SET_CONVERSATIONS", { interlocutorID, conversations: state.conversations[interlocutorID] });
  },
  async startConversation({ commit }, interlocutorID) {
    const botResponse = await API.startConvo(interlocutorID);
    const conversation = { id: botResponse.id, interlocutor: interlocutorID, messages: [] };
    const responseMessage = { text: botResponse.message, sender: "bot" };
    commit("ADD_CONVERSATION", { interlocutorID, conversation });
    commit("SET_CURRENT_CONVERSATION", conversation);
    commit("ADD_MESSAGE", { interlocutorID, convoID: conversation.id, message: responseMessage });
  },
  selectConversation({ commit, state }, { interlocutorID, convoID }) {
    const conversation = state.conversations[interlocutorID].find((convo) => convo.id === convoID);
    commit("SET_CURRENT_CONVERSATION", conversation);
  },
};

const getters = {
  interlocutors: (state) => state.interlocutors,
  conversations: (state) => state.conversations,
  currentConversation: (state) => state.currentConversation,
  interlocutorLastMessage: (state) => (interlocutorID) => {
    const conversations = state.conversations[interlocutorID];
    if (!conversations) return null;
    const lastConvo = conversations[conversations.length - 1];
    if (!lastConvo) return null;
    const lastMessage = lastConvo.messages[lastConvo.messages.length - 1];
    return lastMessage ? lastMessage.text : null;
  },
};

export default new Vuex.Store({
  state,
  mutations,
  actions,
  getters,
  plugins: [
    createPersistedState({
      key: "onlybots-inbox",
      paths: ["interlocutors"], // Only persist the `interlocutors` state, convos are too big...
    }),
  ],
});
/*
import createPersistedState from "vuex-persistedstate";

export default new Vuex.Store({
  plugins: [createPersistedState()],
});
*/
