<template>
  <footer
    v-if="showFooter && !isLoggedIn && !isOnRouteWithoutFooter"
    :class="{ hidden: !isMounted }"
    class="promo-footer"
    :style="inlineStyle"
  >
    <div class="cta-buttons">
      <button @click="closeFooter" class="close-btn">
        <i class="fa fa-times"></i>
      </button>
    </div>
    <div class="container" ref="promoFooter">
      <!-- 
      <img
        class="main-img"
        src="https://miro.medium.com/v2/resize:fit:0/5c50caa54067fd622d2f0fac18392213bf92f6e2fae89b691e62bceb40885e74"
        loading="lazy"
        role="presentation"
      />-->
      <div class="columns" style="align-items: center">
        <div class="desc column">
          <router-link to="/" class="footpp">
            <img src="/img/brand/ob_logotype_black.svg" class="logo" style="" />
          </router-link>
          <h2>
            Sign up to discover the future of AI and supercharge your productivity.
            <!--  
            Sign up to discover the best that generative AI has to offer.
        -->
          </h2>
        </div>
        <div v-for="plan in plans" :key="plan.title" class="column is-narrow">
          <div class="plan">
            <h2 class="title is-5">
              {{ plan.title }}
              <i v-if="plan.premium" class="fa fal fa-sparkles"></i>
            </h2>
            <hr />
            <div v-for="feature in plan.features" :key="feature" class="feature">
              <p>
                <i class="fa fal fa-check"></i>
                <span> {{ feature }}</span>
              </p>
            </div>
            <hr />
            <router-link :to="plan.to" class="button is-dark is-rounded" style="margin-top: 0px">
              <span>{{ plan.bt || "Get started" }}</span>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>
<script>
import { throttle, debounce } from "lodash";

export default {
  data() {
    return {
      showFooter: true, // !localStorage.getItem("footerClosed"),
      isMounted: false,
      isOnRouteWithoutFooter: true,
      scrollTop: 0,
      contentHeight: 200, //default
      plans: [
        {
          title: "Free",
          bt: "Sign up for free",
          to: "/signup",
          features: [
            //  " No ads.",
            "Talk with some AIs.", // "Talk with hundreds of AIs.",

            "Browse the best AI content. No ads.",
            //"Organize your knowledge with lists and highlights.",
            "Share your AI art. Find your audience.",
          ],
        },
        {
          title: "Membership",
          bt: "Try for $9/week",
          premium: true,
          to: "/signup?next=pricing",
          features: [
            "All premium chat bots.",
            "Generate images.",

            //  "Downloadable resources and e-books.",
            "Generative search engine (alpha)",
            "Priority support and feedback.",
          ],
        },
      ],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
    setTimeout(() => {
      this.isMounted = true;
    }, 2000);
    this.checkRoute();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.updateScroll);
  },
  computed: {
    isLoggedIn() {
      return this.$store.main.state.userToken;
    },
    isMobile() {
      return window.innerWidth < 769;
    },

    inlineStyle() {
      if (!this.isMobile) return {}; // No style set when not on mobile

      let offsetValue = this.scrollTop;
      if (offsetValue > this.contentHeight) {
        offsetValue = this.contentHeight;
      }

      let offset = offsetValue ? `calc(50vh - ${offsetValue}px)` : "50vh";
      return {
        top: offset,
      }; /*

      if (!this.isMobile) return {}; // No style set when not on mobile

      let offset = this.scrollTop ? `calc(50vh - ${this.scrollTop}px)` : "50vh";
      return {
        top: offset,
      };*/
    },
    /*
    contentHeight() {
      return this.$refs.promoFooter ? this.$refs.promoFooter.clientHeight : 0;
    },*/
  },
  watch: {
    $route: "checkRoute",
  },
  methods: {
    updateScrollRaw() {
      this.scrollTop = window.scrollY || document.documentElement.scrollTop;
      var ch = this.$refs.promoFooter ? this.$refs.promoFooter.clientHeight : 333;
      var vh = window.innerHeight;
      const PADDING_BOTTOM_MOBILE = 100;
      this.contentHeight = PADDING_BOTTOM_MOBILE + ch - vh / 2;
    },
    updateScroll: throttle(function () {
      this.updateScrollRaw();
    }, 100),
    checkRoute() {
      var no = [
        "pricing",
        "login",
        "signup",
        "Home",
        "signin",
        "reset",
        "forgot",
        "subscribe",
        "gptCreate",
        "gptEdit",
        "gptSaved",
        "gptReview",
      ];
      this.isOnRouteWithoutFooter = no.includes(this.$route.name);
    },
    closeFooter() {
      this.showFooter = false;
      localStorage.setItem("footerClosed", true);
    },
  },
};
</script>
<style scoped>
.promo-footer {
  z-index: 99;
  padding: 20px;
  background: #f9f9f9;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  transform: translateY(100%);
  transition: transform 0.5s;
  /*
  background: white
    url("https://miro.medium.com/v2/resize:fit:0/5c50caa54067fd622d2f0fac18392213bf92f6e2fae89b691e62bceb40885e74");
    */

  background: white url("/img/brand/bg-foot.jpg");
  background: #111;
  background: #c6ff00;
  background: #c5fff1;
  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);

  padding-bottom: 100px;
  /*
  background-position: bottom;
  */
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 1px #444 solid;
}

hr {
  opacity: 1;
  height: 1px;
  border-top: 0;
}

.promo-footer:not(.hidden) {
  transform: translateY(0);
}

.cta-buttons {
  text-align: right;
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.desc {
  text-align: center;
  margin-top: 20px;
}

.desc h2 {
  font-size: 1.5em;
  margin-top: 10px;
}

.plan {
  border: 1px solid #ddd;
  padding: 20px;
  margin-top: 20px;
  background: white;
  color: black;
  min-width: 300px;
}

.feature {
  display: flex;
  align-items: center;
  margin-top: 10px;
}

.feature img.logo {
  margin-right: 10px;
}

.logo {
  height: 22px;
}
@media screen and (max-width: 768px) {
  .desc h2 {
    font-size: 18px;
  }
}
@media screen and (min-width: 768px) {
  .logo {
    height: 42px;
  }
  .desc {
    text-align: left;
  }
  .content {
    flex-direction: row;
    justify-content: space-between;
  }

  .desc,
  .plan {
    /* width: 48%; */
  }
}

.close-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 1.5em;
}
</style>
