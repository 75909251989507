<template>
  <div
    class="emptyWrap"
    :class="{
      fixed: false,
    }"
  >
    <div class="spacer" :class="spacer || 'xl'" />
    <slot name="before"></slot>
    <img class="img" :src="imgSrc" v-if="img" alt="" loading="lazy" />

    <section
      v-show="title"
      class="empty"
      :class="{
        empty: true,
        //colored: theme == 'colored',
      }"
    >
      <span class="icon has-text-infoNOT" v-if="icon" style="font-size: 30px; margin-bottom: 0.3em">
        <i :class="'fal fa-' + icon"></i>
      </span>

      <slot name="header">
        <!-- USE this to pass complete elements instead of title...-->
        <h1
          class="title"
          :class="{
            'is-4': !size || size == 'l', //default size
            'is-5': size == 'm',
            'is-6': size == 's',
          }"
        >
          {{ title }}
          <!--   - {{ y }}   -->
        </h1>
        <h4
          class="subtitle"
          v-if="sub"
          :class="{
            'is-6': !size || size == 'l', //default size
            'is-7': size == 'm',
            'is-7': size == 's',
          }"
          v-html="sub"
        ></h4>
      </slot>

      <div class=" " v-if="btTo">
        <router-link :to="btTo" class="  " style="">
          <b-button :label="btLabel" :type="btType" :icon-left="btIcon ? btIcon : null" icon-pack="fas" />
          <!--
            <span class="icon has-text-infoNOT " v-if="btIcon">
              <i :class="'fas fa-' + btIcon"></i>
            </span>
            <span>{{ btLabel }}</span> -->
        </router-link>
      </div>
      <div class=" " v-if="hrefTo">
        <a :href="hrefTo" class="  " style="" target="_blank">
          <b-button :label="btLabel" :type="btType" :icon-left="btIcon ? btIcon : null" icon-pack="fas" />
          <!--
            <span class="icon has-text-infoNOT " v-if="btIcon">
              <i :class="'fas fa-' + btIcon"></i>
            </span>
            <span>{{ btLabel }}</span> -->
        </a>
      </div>
      <div style="margin-top: 5px" v-if="secondaryTo">
        <router-link :to="secondaryTo" class="  " style="margin-top: 10px">
          <b-button
            :label="secondaryLabel || 'More...'"
            type="is-lightNO"
            :icon-left="secondaryIcon ? secondaryIcon : null"
            icon-pack="fas"
          />
        </router-link>
      </div>

      <div v-if="btGoogle">
        <authGoogle page="login" label="Continue  with Google "></authGoogle>
      </div>
    </section>
    <slot name="after"></slot>
    <div class="spacer" :class="spacer || 'xl'" />
  </div>
</template>
<script>
//import Navbar from '../_ext/popup/components/Navbar'; //NEED REFACTORING!

export default {
  name: "titlebar",
  components: {
    // authGoogle,
    //  Navbar: Navbar,
  },
  props: [
    "title",
    "btGoogle",
    "sub",
    "img",
    "btColor",
    "btTo",
    "hrefTo",
    "size",
    "icon",
    "spacer",
    "btLabel",
    "btIcon",
    "secondaryTo",
    "secondaryLabel",
  ],
  data() {
    return {
      beLazy: false,
    };
  },
  computed: {
    y() {
      return this.$store.main.getters.scrollY;
    },
    btType() {
      var c = this.btColor;
      if (!c) return "is-info";
      if (c == "default") return "";
      if (c == "dark") return "is-dark";
      if (c == "light") return "is-light";
      return c;
    },
    imgSrc() {
      var i = this.img;
      if (i == "grow") return "https://myapp.com/img/abs2/grow.png"; //https://myapp.com/img/abs2/grow.png
      if (i == "good") return 'https://myapp.com/img/abs2/automatic-referral.png"';
      if (i == "money") return "https://myapp.com/img/abs2/easy-money.png";
      if (i == "friend") return "https://myapp.com/img/abs2/friendship2.png";
      if (i == "friend") return "https://myapp.com/img/abs2/friendship2.png";
      if (i == "shrug") return "https://cdn1.onlybots.cc/path/to/upload/folderONbzo7g4Pu___shrug1.png";
      return this.img;
    },
  },
  methods: {
    clickHeader(d) {
      // alert(334);
      if (!this.scrollTopOnClick) return null;
      window.scroll({ top: 0, left: 0, behavior: "smooth" }); //go back to top baby!
    },
  },
};
</script>
<style scoped>
.emptyWrap {
  width: 100%;
}

.empty,
.empty h1 {
  text-align: center;
}

.img {
  height: 150px;
  max-height: 20vw;
  display: block;
  margin: auto;
  object-fit: contain;
}
</style>
