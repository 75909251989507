<template>
  <div v-if="isAdmin && true">
    <div v-if="data">
      <b-button @click="show = !show" size="is-small" type="is-dark" icon-left="code" icon-pack="fas">
        {{ labelToShow }}
      </b-button>
      <vue-json-pretty
        v-if="show"
        :path="'res'"
        :data="dataFiltered"
        :highlightMouseoverNode="true"
        :showDoubleQuotes="false"
        :showLength="true"
        :deep="1"
      >
      </vue-json-pretty>
    </div>
  </div>
</template>
<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";

export default {
  //name: 'Home',
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      show: this.expanded || false,
      c: "",
    };
  },
  props: ["data", "expanded", "label", "sample"],
  computed: {
    labelToShow() {
      return this.label || "view json data";
    },
    dataFiltered() {
      if (this.sample && this.data.slice) return this.data.slice(0, 6);
      return this.data;
    },

    isAdmin() {
      return this.$store.main.getters.isAdmin;
    },
  },
  methods: {
    fetchData(event) {
      this.loading = true;
    },
  },
  watch: {
    slug: function (from, to) {
      //necessary to refresh on url changes.
      this.fetchData();
    },
  },
  mounted() {
    /* to be executed when mounted */
    this.fetchData();
  },
};
</script>

<style></style>
