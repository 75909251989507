var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"emptyWrap",class:{
    fixed: false,
  }},[_c('div',{staticClass:"spacer",class:_vm.spacer || 'xl'}),_vm._v(" "),_vm._t("before"),_vm._v(" "),(_vm.img)?_c('img',{staticClass:"img",attrs:{"src":_vm.imgSrc,"alt":"","loading":"lazy"}}):_vm._e(),_vm._v(" "),_c('section',{directives:[{name:"show",rawName:"v-show",value:(_vm.title),expression:"title"}],staticClass:"empty",class:{
      empty: true,
      //colored: theme == 'colored',
    }},[(_vm.icon)?_c('span',{staticClass:"icon has-text-infoNOT",staticStyle:{"font-size":"30px","margin-bottom":"0.3em"}},[_c('i',{class:'fal fa-' + _vm.icon})]):_vm._e(),_vm._v(" "),_vm._t("header",function(){return [_c('h1',{staticClass:"title",class:{
          'is-4': !_vm.size || _vm.size == 'l', //default size
          'is-5': _vm.size == 'm',
          'is-6': _vm.size == 's',
        }},[_vm._v("\n        "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),(_vm.sub)?_c('h4',{staticClass:"subtitle",class:{
          'is-6': !_vm.size || _vm.size == 'l', //default size
          'is-7': _vm.size == 'm',
          'is-7': _vm.size == 's',
        },domProps:{"innerHTML":_vm._s(_vm.sub)}}):_vm._e()]}),_vm._v(" "),(_vm.btTo)?_c('div',{staticClass:""},[_c('router-link',{staticClass:"",attrs:{"to":_vm.btTo}},[_c('b-button',{attrs:{"label":_vm.btLabel,"type":_vm.btType,"icon-left":_vm.btIcon ? _vm.btIcon : null,"icon-pack":"fas"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.hrefTo)?_c('div',{staticClass:""},[_c('a',{staticClass:"",attrs:{"href":_vm.hrefTo,"target":"_blank"}},[_c('b-button',{attrs:{"label":_vm.btLabel,"type":_vm.btType,"icon-left":_vm.btIcon ? _vm.btIcon : null,"icon-pack":"fas"}})],1)]):_vm._e(),_vm._v(" "),(_vm.secondaryTo)?_c('div',{staticStyle:{"margin-top":"5px"}},[_c('router-link',{staticClass:"",staticStyle:{"margin-top":"10px"},attrs:{"to":_vm.secondaryTo}},[_c('b-button',{attrs:{"label":_vm.secondaryLabel || 'More...',"type":"is-lightNO","icon-left":_vm.secondaryIcon ? _vm.secondaryIcon : null,"icon-pack":"fas"}})],1)],1):_vm._e(),_vm._v(" "),(_vm.btGoogle)?_c('div',[_c('authGoogle',{attrs:{"page":"login","label":"Continue  with Google "}})],1):_vm._e()],2),_vm._v(" "),_vm._t("after"),_vm._v(" "),_c('div',{staticClass:"spacer",class:_vm.spacer || 'xl'})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }