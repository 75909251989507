<template>
  <div>
    <div v-if="layout == 'swipe'">
      <div class="columns title-and-nav is-mobile">
        <div class="column">
          <h4 v-if="title" class="title is-4">{{ title }}</h4>
        </div>
        <div class="column is-narrow">
          <!-- two groupped buefy button to navigate the swiper component -->
          <div class="btns" v-if="visibleBots.length > slidesPerView">
            <b-button icon-left="fas fa-chevron-left" type="is-light" @click="prev"></b-button>
            <b-button icon-right="fas fa-chevron-right" type="is-light" @click="next"></b-button>
          </div>
        </div>
      </div>

      <swiper-container
        class="mySwiper"
        pagination="true"
        pagination-clickable="true"
        space-between="0"
        :slides-per-view="slidesPerView"
        ref="mySwiper"
      >
        <swiper-slide v-for="bot in visibleBots" :key="bot.id">
          <bot-card :bot="bot" :include-links="includeLinks" layout="card"></bot-card>
        </swiper-slide>
      </swiper-container>

      <!-- 
    <swiper-container
      class="mySwiper"
      pagination="true"
      pagination-clickable="true"
      space-between="30"
      slides-per-view="3"
    >
      <swiper-slide>Slide 2</swiper-slide>
      <swiper-slide>Slide 3</swiper-slide>
      <swiper-slide>Slide 4</swiper-slide>
      <swiper-slide>Slide 5</swiper-slide>
      <swiper-slide>Slide 6</swiper-slide>
      <swiper-slide>Slide 7</swiper-slide>
      <swiper-slide>Slide 8</swiper-slide>
      <swiper-slide>Slide 9</swiper-slide>
    </swiper-container>  -->
    </div>

    <div v-if="layout == 'minilist'" class="minilist">
      <bot-card
        v-for="bot in visibleBots"
        :key="bot.handle"
        :bot="bot"
        :include-links="includeLinks"
        :prefix="prefix"
        :noDescription="noDescription"
        :layout="layout"
        class="minilist-item"
      ></bot-card>
    </div>

    <div v-if="layout == 'grid'" class="columns is-mobile" :class="{ 'is-multiline': !nowrap, nowrap: nowrap }">
      <bot-card
        v-for="bot in visibleBots"
        :key="bot.handle"
        :bot="bot"
        :include-links="includeLinks"
        :prefix="prefix"
        :noDescription="noDescription"
        class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd"
      ></bot-card>
    </div>

    <div v-if="layout == 'cardManage'" class="columns is-multiline is-mobile">
      <bot-card
        v-for="bot in visibleBots"
        :layout="layout"
        :key="bot.handle"
        :bot="bot"
        :include-links="includeLinks"
        :prefix="prefix"
        :noDescription="noDescription"
        class="column is-12-mobile is-6-tablet is-6-desktop is-4-fullhd"
      ></bot-card>
    </div>
  </div>
</template>

<script>
import BotCard from "./BotCard.vue";
//lodash
import _ from "lodash";

/*
// import function to register Swiper custom elements
import { register } from "swiper/element/bundle";
// register Swiper custom elements
register();
*/
export default {
  components: {
    BotCard,
  },
  props: {
    bots: {
      type: Array,
      required: true,
    },
    pageSize: {
      type: Number,
      default: 10,
    },
    includeLinks: {
      type: Boolean,
      default: true,
    },
    title: {
      type: String,
      default: "",
    },
    layout: {
      type: String,
      default: "grid",
    },
    prefix: {
      type: String,
      default: "",
    },
    noDescription: {
      type: Boolean,
      default: false,
    },
    nowrap: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: 1,
      windowWidth: window.innerWidth,
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("resize", this.onResize);
    });
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },

  //watch slide per vie wand trigger swiperEl.slidesPerView = 3
  watch: {
    slidesPerView(a, b) {
      console.log("slidesPerView changed", a, b);
      //const swiperEl = document.querySelector("swiper-container");
      if (this.$refs.mySwiper && this.$refs.mySwiper.swiper) this.$refs.mySwiper.swiper.update();
    },
  },
  computed: {
    slidesPerView() {
      //determine based on screen size how many cards to show
      if (this.windowWidth < 768) {
        return 2;
      } else if (this.windowWidth < 1024) {
        return 3;
      } else if (this.windowWidth < 1280) {
        return 3;
      } else if (this.windowWidth < 1440) {
        return 4;
      } else {
        return 4;
      }
    },
    visibleBots() {
      var bots = this.bots;
      bots = _.compact(bots);
      const startIndex = (this.currentPage - 1) * this.pageSize;
      const endIndex = startIndex + this.pageSize;
      return bots.slice(startIndex, endIndex);
    },
    totalPages() {
      return Math.ceil(this.bots.length / this.pageSize);
    },
  },
  methods: {
    onResize() {
      this.windowWidth = window.innerWidth;
    },
    prev() {
      const swiperEl = document.querySelector("swiper-container");
      this.$refs.mySwiper.swiper.slidePrev();
      // swiperEl.swiper.slidePrev();
    },
    next() {
      const swiperEl = document.querySelector("swiper-container");
      //swiperEl.swiper.slideNext();
      this.$refs.mySwiper.swiper.slideNext();
    },
    goToPage(page) {
      this.currentPage = page;
    },
  },
};
</script>
<style scoped>
.title-and-nav,
.title-and-nav .column {
  padding-bottom: 0;
  align-items: flex-end;
}

.nowrap {
  overflow: hidden;
}
</style>
