<template>
  <form @submit.prevent="submitForm">
    <b-field>
      <b-input v-model="email" name="email" type="email" placeholder="Your Email" required></b-input>
    </b-field>
    <!-- 
    <b-field>
      <b-input v-model="name" type="text" placeholder="Name" required></b-input>
    </b-field> -->
    <b-field>
      <b-textarea v-model="summary" placeholder="Briefly describe your project"></b-textarea>
    </b-field>
    <b-field>
      <b-button type="is-dark" @click="submitForm" style="font-weight: bold">Join waitlist</b-button>
    </b-field>
    <b-field v-if="submitted">
      <p class="has-text-centered has-text-primary">Thank you for signing up and spreading the world about OnlyBots</p>
    </b-field>
  </form>
</template>

<script>
export default {
  data() {
    return {
      email: "",
      name: "",
      summary: "",
      submitted: false,
    };
  },
  methods: {
    async submitForm() {
      var a = await window.API.registerWaitlist({ email: this.email });
      //  alert(4);

      //retured router push to /thank-you
      this.$router.push("/thank-you");

      this.submitted = true;
      //buefy toast
      this.$buefy.toast.open({
        message: "Thank you for signing up!",
        type: "is-dark",
        position: "is-bottom",
        duration: 9000,
        queue: false,
      });
      /*
      try {
        const response = await axios.post("/api/waitlist", {
          email: this.email,
          name: this.name,
          summary: this.summary,
        });
        if (response.status === 200) {
          this.submitted = true;
        }
      } catch (error) {
        console.error(error);
      }
    },
    */
    },
  },
};
</script>
