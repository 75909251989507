<template>
  <div class="pricing-table">
    <div
      v-for="plan in plans"
      :key="plan.id"
      :class="['pricing-plan', plan.highlight ? 'is-success' : '', plan.id == 3 ? 'is-link' : '']"
    >
      <div class="plan-header">{{ plan.name }}</div>
      <div class="plan-price">
        <span class="plan-price-amount">
          <span class="plan-price-currency">{{ plan.currency }}</span
          >{{ plan.price }}
        </span>
        <div style="display: inline-block; text-align: left">
          <span> one-time</span>
          <!--  
          <span> per users</span><br />-->
        </div>
      </div>
      <div class="plan-items">
        <div v-for="(item, index) in plan.items" :key="index" class="plan-item">{{ item }}</div>
      </div>
      <div class="plan-footer">
        <div v-if="plan.free" class="plan-free">No signup required</div>
        <div v-else class="plan-btn">
          <!--  
          <button class="button is-fullwidth" :disabled="plan.currentPlan">Current plan</button> -->
          <a :href="plan.call" target="_blank" class="button is-fullwidth"> Get started </a>
          <!--
          <button class="button is-fullwidth" @click="email(plan.name)">Get started</button> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    email(plan) {
      window.location.href = `mailto:team@qualitygpts.com?subject=Custom GPT (${plan} plan) on qualitygpts.com ${this.source}&body=Hi! \nI'd like to order a custom GPT! \n  `;
      // window.location.href = "mailto:
    },
  },
  computed: {
    source: function () {
      var r = this.$route.query.ref;
      if (r) {
        return "[via " + r + "]";
      } else {
        return "";
      }
      return "s";
    },
  },
  data() {
    return {
      plans: [
        /* {
          id: 1,
          name: "Free",
          price: 0,
          currency: "$",
          items: ["1 team inbox", "2 team members", "1000 monthly messages", "-", "-", "-"],
          currentPlan: true,
          highlight: false,
          free: true,
        },*/
        {
          id: 2,
          name: "Basic",
          price: 500,
          currency: "$",
          call: "https://superpeer.com/menard/-/basic-gpt",
          items: [
            //

            "One custom GPT",
            "30-minute call",
            "Simple data sources",
            //"Browse the web",
            //  "Basic actions",
            "Friendly support",

            "Basic actions only",
            "Available on ChatGPT",

            //   "-",
            //   "-",
          ],
          btn: "Get started",
          currentPlan: false,
          highlight: false,
        },
        {
          id: 2,
          name: "Plus",
          price: 1200,
          currency: "$",
          call: "https://superpeer.com/menard/-/plus-gpt",
          items: [
            //
            "One advanced GPT",
            "1-hour call",
            "Complex data sources",
            //"Browse the web",
            //  "Basic actions",
            "Priority support",

            "Custom actions",
            "Available on ChatGPT",
            // "-",
            //  "-",
          ],
          currentPlan: false,
          highlight: true,
        },
        {
          id: 3,
          name: "Pro",
          price: 3000,
          currency: "$",
          call: "https://superpeer.com/menard/-/pro-gpt",
          items: [
            //
            "One advanced GPT",
            "1-hour call",
            "Complex data sources",
            //"Browse the web",
            //  "Basic actions",
            "Priority support",

            "Complex actions and workflows",
            "API or ChatGPT integration",
            // "-",
            //   "-",
          ],
          currentPlan: false,
          highlight: false,
        },
      ],
    };
  },
};
</script>
<style scoped>
.plan-items {
}
.plan-item {
  margin: 2px 10px;
}
</style>
