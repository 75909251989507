<template>
  <div>
    <div v-if="isRecording" class="voice-recording-overlay">
      <b-icon icon="microphone" pack="far" class="large-microphone-icon"></b-icon>
      <p class="voice-text">{{ tempSpeechInput }}</p>
      <!-- Displaying the tempSpeechInput here 
    
        {{ isFocused }} ==isFocused
    
       {{ isAutocompleteOpen }} ==isAutocompleteOpen
    
        {{ focussedOnLoad }} == focussedOnLoad
    <hr />
    {{ hasInteracted }} ==hasInteracted
    --></div>

    <form @submit.prevent="onSubmit" :class="{ size, isFocused: isFocused, notFocused: !isFocused }">
      <div
        class="the-box"
        :class="{
          open: isAutocompleteOpen || isFocused,
          closed: !isAutocompleteOpen,
        }"
        @click="clickWholeThing"
      >
        <div class="horiDivider"></div>
        <b-field label="" class="field-row">
          <div class="the-input">
            <!--

                  @keyup.enter.native="onSubmit"
                  icon-right="search"
              icon-right-clickable
              @icon-right-click="onSubmit"

               :icon-left="isFocused ? 'search' : ''"

                :clearable="query != ''"

                              @focus="handleFocus"
              @blur="handleBlur"
            -->
            <myAutocomplete
              ref="autocompleteInput"
              class="autocompleteInput"
              :icon="isAutocompleteOpen ? 'search' : ''"
              icon-pack="fal"
              :value="query"
              @input="updateQuery"
              :data="autocompleteSuggestions"
              :placeholder="placeholder"
              field="value"
              :open-on-focus="openDropdown"
              @typing="fetchSuggestions"
              @select="onSelect"
              @keyup.enter.native="onSubmit"
              @open="isAutocompleteOpen = true"
              @close="isAutocompleteOpen = false"
              type="search"
              :size="size"
            >
              <template slot-scope="props">
                <div class="media" :key="props.option.icon + props.option.label">
                  <div class="media-left">
                    <img
                      v-if="props.option.avatar"
                      :src="props.option.avatar"
                      class="avatar"
                      style="width: 19px; height: 19px; margin-right: 4px"
                    />
                    <b-icon v-else :icon="props.option.icon" pack="far"></b-icon>
                  </div>

                  <div class="media-content">
                    <span v-html="props.option.label"></span>
                    <!--  
                    <span class="tag is-info is-light" v-if="props.option.isBot">bot</span>
                  -->

                    <span class="tag is-success is-light" v-if="props.option.isHuman">human</span>
                  </div>
                </div>
              </template>
            </myAutocomplete>
          </div>

          <div class="rightBts">
            <div class="sep"></div>
            <b-button v-if="supportsSpeechAPI" @click="startSpeechRecognition">
              <b-icon icon="microphone" pack="far"></b-icon>
            </b-button>
            <b-button @click="search">
              <b-icon icon="search" pack="far"></b-icon>
            </b-button>
          </div>
        </b-field>
      </div>
      <div class="reserveSpaceForInput"></div>
    </form>
    <!--   {{ autocompleteSuggestions }}
 
        <form @submit.prevent="formSubmit">
          <b-field label=" ">
            <div class="the-input">
              <b-input
                v-model="query"
                @keyup.enter="formSubmit"
                type="search"
                size="is-large"
                rounded
                placeholder="Search for anything"
                icon="search"
                icon-pack="fal"
                icon-right="search"
                icon-right-clickable
                @icon-right-click="formSubmit"
              ></b-input>
            </div>
          </b-field>

 
        </form>
      -->
  </div>
</template>

<script>
/* global webkitSpeechRecognition */

import { debounce } from "lodash";
import myAutocomplete from "./myAutocomplete.vue"; //alternative to b-autocomplete

export default {
  components: {
    myAutocomplete,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    focussedOnLoad: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: "is-normal",
    },
  },
  data() {
    return {
      query: this.value,
      isFocused: false,
      isAutocompleteOpen: false,
      tempSpeechInput: "",

      isRecording: false,
      autocompleteSuggestions: [],
      recognition: null, // For holding the speech recognition instance
      hasInteracted: false,
    };
  },
  computed: {
    openDropdown() {
      if (!this.focussedOnLoad) return true; ///default bahavior
      return this.hasInteracted;
    },
    supportsSpeechAPI() {
      return "webkitSpeechRecognition" in window;
    },
    /*
    isAutocompleteOpen() { NO WORKING - not reacitve...
      return this.$refs.autocompleteInput ? this.$refs.autocompleteInput.isActive : false;
    },*/
  },
  watch: {
    value(newVal) {
      this.query = newVal; // Update the query when the value prop changes
      this.fetchSuggestions(this.query);
    },

    //watch route and this.blurInput();
    $route(to, from) {
      this.blurInput();
    },
  },
  mounted() {
    this.query = this.value; // Set the query to the value prop
    //fetch suggestions when the component is mounted
    if (this.focussedOnLoad) {
      //SET FOCUS ON INPUT //TODO
      this.$refs.autocompleteInput.$refs.input.focus();
    }
    this.fetchSuggestions(this.query);

    //every sec on this.$refs.autocompleteInput.isActive
    /*
    setInterval(() => {
      // console.log(this.$refs.autocompleteInput.isActive);
    }, 1000);

    this.$nextTick(() => {
      //DIRTY WATCHER... Idealy, fix in autocoplete by triggering event on isActive change...
      const autocompleteEl = this.$refs.autocompleteInput.$el;
      const observer = new MutationObserver((mutationsList) => {
        for (let mutation of mutationsList) {
          if (mutation.type === "childList") {
            // Check if dropdown is now visible or some condition
            this.handleDropdownChildChange();
            setTimeout(() => {
              this.handleDropdownChildChange();
            }, 100);
            setTimeout(() => {
              this.handleDropdownChildChange();
            }, 200);
            setTimeout(() => {
              this.handleDropdownChildChange();
            }, 300);
            setTimeout(() => {
              this.handleDropdownChildChange();
            }, 500);
            setTimeout(() => {
              this.handleDropdownChildChange();
            }, 1000);
          }
        }
      });

      observer.observe(autocompleteEl, { attributes: true, childList: true, subtree: true });
    });*/
  },

  methods: {
    clickWholeThing() {
      this.hasInteracted = true;
      this.$refs.autocompleteInput.isActive = true;
    },

    handleDropdownChildChange() {
      // !!! HEAVY LOGIC!
      // TODO!!
      //   console.log("handleDropdownChildChange", this.$refs.autocompleteInput.isActive);
      if (this.$refs.autocompleteInput.isActive) {
        this.isAutocompleteOpen = true;
      } else {
        this.isAutocompleteOpen = false;
      }
    },
    updateQuery(newValue) {
      this.hasInteracted = true;
      this.$emit("input", newValue); // Emit input event to support v-model in parent
    },
    handleFocus() {
      this.isFocused = true; //not in use anymore...
    },
    handleBlur() {
      this.isFocused = false;
    },
    /*
    fetchSuggestions(newQuery) {
      this.getAutocomplete(newQuery); // Call the method to fetch suggestions
    }, */
    fetchSuggestions: debounce(function (newQuery) {
      this.getAutocomplete(newQuery); // Call the method to fetch suggestions
    }, 300), // This will delay the function by 300 milliseconds

    onSubmit() {
      //this.openDropdown = false; // Set to false to close the dropdown
      this.search();
      /*
      setTimeout(() => {
        this.openDropdown = true; // Reset back to true after a tick to allow for future focus-based dropdown opening
      }, 2000);*/
      /*
      this.$nextTick(() => {
        this.openDropdown = true; // Reset back to true after a tick to allow for future focus-based dropdown opening
      });*/

      //this.search();
    },
    onSelect(option) {
      if (option) {
        console.log("SELECTED ITEM: ", option);
        if (option.to) {
          this.$router.push(option.to);
          return false;
        }
        this.query = option.value || option.label; // Set the query to the selected label
        // this.$emit("input", this.query);
        //this.search();
        // this.$emit("search", option.label); // Emit search event with the selected label
      } else {
        console.log("ERROR onSelect128 options == null: ", option);
      }
    },
    blurInput() {
      // this.$refs.autocompleteInput.clickedOutside();
      this.$refs.autocompleteInput.isActive = false;
      /*
      const autoCompleteEl = this.$refs.autocompleteInput.$el;
      const inputEl = autoCompleteEl.querySelector("input");

      if (inputEl) {
        inputEl.blur();
      }*/
      // this.$refs.autocompleteInput.$refs.input.blur();
      //this.$refs.autocompleteInput.$el.blur();
      //  this.$refs.autocompleteInput.blur();
      //trigger event on element
      /*
      if (
        this.$refs.autocompleteInput &&
        this.$refs.autocompleteInput.$refs &&
        this.$refs.autocompleteInput.$refs.input
      ) {
        this.$refs.autocompleteInput.$refs.input.blur();
      }*/
      //this.$refs.autocompleteInput.dispatchEvent(new Event("blur"));
    },
    search() {
      console.log("call search sevent.");
      var q = String(this.query).trim();
      if (q.length < 1) return;
      this.blurInput();
      this.$emit("search", q); // Emit search event to the parent
    },
    getAutocomplete(s) {
      window.API.getSearchAutocomplete({ search: s, user: this.$store.main.getters.userId }).then((ar) => {
        const NB_AUTO_SHOWN = 10;
        var sliced = ar.slice(0, NB_AUTO_SHOWN);
        this.autocompleteSuggestions = sliced;
      });
    },

    //
    startSpeechRecognition() {
      console.log("startSpeechRecognition");
      if (!("webkitSpeechRecognition" in window)) {
        alert("Web Speech API not supported by this browser");
        return;
      }

      if (!this.recognition) {
        const recognition = new webkitSpeechRecognition();
        recognition.lang = "en-US";
        recognition.interimResults = true; // Setting this to true
        recognition.maxAlternatives = 1;

        recognition.onresult = (event) => {
          const isFinal = event.results[event.results.length - 1].isFinal;
          const speechResult = event.results[event.results.length - 1][0].transcript;

          let interimTranscript = "";

          for (let i = 0; i < event.results.length; i++) {
            const transcriptSegment = event.results[i][0].transcript;
            interimTranscript += transcriptSegment;
          }

          this.tempSpeechInput = interimTranscript;

          // this.tempSpeechInput = speechResult; // Updating tempSpeechInput with interim results

          if (isFinal) {
            this.query = speechResult; // Transfer the final result to the query
            this.recognition.stop();
            this.$nextTick(() => {
              this.search();
            });
          }
        };

        recognition.onspeechend = () => {
          this.recognition.stop();
        };

        recognition.onstart = () => {
          this.isRecording = true;
        };

        recognition.onend = () => {
          this.isRecording = false;
          this.tempSpeechInput = "";
        };

        recognition.onerror = (event) => {
          console.log("onerror", event);
          if (event.error === "network") {
            alert(
              "There was a network error when trying to use the voice recognition. Please check your internet connection."
            );
          } else {
            alert("Error occurred in recognition: " + event.error);
          }
        };

        this.recognition = recognition;
      }

      this.recognition.start();
    },
  },
};
</script>
<style scoped>
.reserveSpaceForInput {
  height: 48px;
  background: red;

  background: none;
}
.the-box {
  box-shadow: 0 2px 8px 1px rgba(64, 60, 67, 0.1);
  border-color: rgba(223, 225, 229, 0);
  border-radius: 23px;
  margin-top: 10px;
  max-width: 700px;
  width: 90vw;
  margin-bottom: 15px;
  padding: 3px;
  background: greenyellow;
  background: white;
  position: absolute;
  width: -webkit-fill-available;
  left: 29px; /* set to zero when box open, to compensate for loupe */

  right: 100px;
  z-index: 1;
}

.the-box.open {
  height: 430px;
  left: 0px;
}

.isFocused .the-box,
.the-box:hover {
  box-shadow: 0 2px 8px 1px rgba(64, 60, 67, 0.3);
  border-color: rgba(223, 225, 229, 0.8);
}

.notFocused .the-box {
  padding-left: 12px;
}

.horiDivider {
  display: none;
  height: 1px;
  background: #e0e0e0;

  z-index: 4666;
  margin-left: 10px;
  margin-right: 10px;
  position: relative;
  top: 43px;
}
.the-box.open .horiDivider {
  display: block;
}

.field-row {
}

.the-input {
  width: 100%;
  /*
  box-shadow: 0 2px 8p;x 1px rgba(64, 60, 67, 0.24);
  border-color: rgba(223, 225, 229, 0);
  border-radius: 200px;

  margin-top: 25px;
  max-width: 700px;
  */
}
/* on table size, make smaller width using media query */

@media screen and (max-width: 900px) {
  .the-input {
    width: 95vw;
    max-width: 95vw;
    margin-left: 1vw;
  }
}

.autocompleteInput >>> input {
  border: none;
  box-shadow: none;
  box-shadow: none;
  border: none;
  font-size: 16px;
  border: 1px red solid;
  border: none;
  background: transparent;
}

.rightBts {
  display: flex;
  align-items: center;
  padding-right: 5px;
}

.sep {
  border-left: 1px solid #d8e5e2;
  margin-left: 2px;
  margin-right: 6px;
  height: 27px;
}
.rightBts .button {
  height: 25px;
  border: none;
  background: none;
}
.rightBts .button:hover {
  color: rgb(18, 220, 0);
}

.voice-recording-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.7); /* semi-transparent background */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000; /* ensure it's above everything else */
}

.large-microphone-icon {
  font-size: 50px;
  margin-bottom: 20px;
  color: #fff;
}

.voice-text {
  color: #fff;
  font-size: 20px;
  text-align: center;
  max-width: 80%;
  word-wrap: break-word;
}

.the-input >>> b {
  font-weight: bold;
}

.the-input >>> input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  cursor: pointer;

  height: 20px;
  width: 20px;

  /* Remove default */
  -webkit-appearance: none;

  /* Now your own custom styles */
  height: 14px;
  width: 14px;
  display: block;
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAAn0lEQVR42u3UMQrDMBBEUZ9WfQqDmm22EaTyjRMHAlM5K+Y7lb0wnUZPIKHlnutOa+25Z4D++MRBX98MD1V/trSppLKHqj9TTBWKcoUqffbUcbBBEhTjBOV4ja4l4OIAZThEOV6jHO8ARXD+gPPvKMABinGOrnu6gTNUawrcQKNCAQ7QeTxORzle3+sDfjJpPCqhJh7GixZq4rHcc9l5A9qZ+WeBhgEuAAAAAElFTkSuQmCC);
  /* setup all the background tweaks for our custom icon */
  background-repeat: no-repeat;
  opacity: 0.3;

  /* icon size */
  background-size: 14px;
}

.the-input >>> .autocomplete .dropdown-content {
  max-height: 400px;
  box-shadow: none;
}

.media-content {
  font-size: 16px !important;
}
</style>
