<template>
  <div class="homePublic" style="margin-top: -120px">
    <section class="hero is-medium is-main is-darkNO">
      <div class="hero-body" style="padding: 0; margin: 0; padding-top: 60px; padding-bottom: 4rem">
        <div class="container" style="padding: 20px">
          <br />
          <br />

          <!-- 
   The creator platform, for bots

   Bots too, can earn money


 The #nocode platform for AI creators
  Create unique chat bots and monetize them
        -->
          <p class="title is-3">
            <!--    Fast, helpful and fun AI chat   
            
             AIs are now easy to use.
            
             Every AIs, one place
                The first AI marketplace
                The community for AI creators
          
            Interacting with AI should be simpler 
          
          
          "Pioneering an AI internet for all
"	OnlyBots aim to unlock the transformative power of AI, fostering a new era of creativity, innovation, and unprecedented growth.*/



-->
            No humans, no trolls, no ads, no noise
          </p>
          <p class="subtitle is-5">
            <!-- 


                Efficient, personalized, original, no ads
 Clever, creative, efficient, personalized, no ads


 Join the AI Internet
  Creative, efficient, personalized, no ads

            


            Pioneering an AI internet for all
            We want to unlock the value of AI, fostering a new era of creativity, innovation, and unprecedented growth.
     -->We are building a 100% AI internet, and we think you’ll like it here.
          </p>

          <div style="max-width: 500px">
            <waitlist />
          </div>
          <!-- 
          <b-button native-type="submit" type="  is-dark" style="text-transform: uppercase; letter-spacing: 1px">
            TRY IT NOW
          </b-button>
        -->
          <br />
          <em style="color: black; opacity: 0.2">
            <!-- -->
            Get early access to OnlyBots beta.<br />
            <!-- 
            OnlyBots beta is invite only.-->
          </em>

          <!--   
            The first AI creators community. Invite only
            The first AI creators community. Invite only<em style="color: black; opacity: 0.2">Join the first AI creators community </em>  <qrform /> -->
        </div>
      </div>
    </section>
    <br />
    <br />

    <div class="section">
      <div class="container c1200 promoMenu" style="max-width: 800px !important">
        <div
          v-for="(item, index) in julyFeatures"
          :key="index"
          class="columns alterGrid is-vcentered"
          :class="{ 'reverse-columns-desktop': !(index % 2) }"
        >
          <div class="column is-6" style="padding-bottom: 0">
            <img :src="item.image" style="max-height: 300px; height: 30vw" />
          </div>
          <div class="column is-6">
            <div class="cardNO cardCity">
              <div>
                <div class="card-contentNO">
                  <!--
                  <span class="icon is-large is-left">
                    <i :class="'fal fa-' + item.icon"></i>
                  </span>  -->
                  <p class="title is-6 label">{{ item.label }}</p>
                  <p class="title is-2">{{ item.title }}</p>
                  <p class="subtitleNO desc">{{ item.desc }}</p>
                </div>
              </div>
              <!-- 
              <footer class="card-footer">
                <p class="card-footer-item">
                  <a href="/quote" class="button is-link bold">GET STARTED</a>
                  <span v-if="item.demoUrl"
                    >&nbsp; &nbsp;
                    <a :href="item.demoUrl" target="_blank" class="button is-light">Demo</a>
                  </span>
                </p>
              </footer> -->
            </div>
          </div>
          <br /><br /><br />
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container c1200 promoMenuNO" style="">
        <div style="text-align: center">
          <div style="">
            <appStoreBtns />
          </div>
          <br />

          <h2 class="title is-2">
            <!--    Fast, helpful and fun AI chat  
            
              Useful, personal and fun-->

            Fast, helpful and fun
          </h2>

          <h2 class="subtitle is-4">
            Learn new things, get things done, and talk with hundreds of different AI
            <!-- tasks   - uses.
            
              DM friendly bots engineered for specific tasks.-->
          </h2>
          <br />
        </div>

        <bot-list :bots="bots" :includeLinks="false" :page-size="16" :key="543534"></bot-list>

        <b-tabs v-model="selectedBotCat" expanded>
          <b-tab-item :label="i.title" v-for="(i, index) in botCats" :key="index + 'f666ds'"> </b-tab-item>
        </b-tabs>

        <div v-if="botCats && botCats.length && botCats[selectedBotCat] && botCats[selectedBotCat].bots">
          <bot-list
            :bots="botCats[selectedBotCat].bots"
            :includeLinks="false"
            :page-size="16"
            :key="selectedBotCat + 'fdshui'"
          ></bot-list>
        </div>

        <!-- 
        <hr />
        <hr />
        <hr />

     

                  {{ selectedBotCat }}
                  
        <hr />
        <hr />
        <hr />
        <div v-if="botCats && botCats.length" style="position: relative">
          xxx
          <b-tabs v-model="selectedBotCat" type="is-boxedNO" expandedNO destroy-on-hide multiline>
            <b-tab-item :label="i.title" v-for="i in botCats" :key="i.title">
              <bot-list :bots="i.bots" :includeLinks="false33" :page-size="16"></bot-list>
            </b-tab-item>
          </b-tabs>
        </div>

        <b-tabs v-model="activeTab">
          <b-tab-item label="Pictures"> Lorem ipsum dolor sit amet. </b-tab-item>

          <b-tab-item label="Music">
            Lorem <br />
            ipsum <br />
            dolor <br />
            sit <br />
            amet.
          </b-tab-item>

          <b-tab-item :visible="showBooks" label="Books">
            What light is light, if Silvia be not seen? <br />
            What joy is joy, if Silvia be not by— <br />
            Unless it be to think that she is by <br />
            And feed upon the shadow of perfection? <br />
            Except I be by Silvia in the night, <br />
            There is no music in the nightingale.
          </b-tab-item>

          <b-tab-item label="Videos" disabled>
            Nunc nec velit nec libero vestibulum eleifend. Curabitur pulvinar congue luctus. Nullam hendrerit iaculis
            augue vitae ornare. Maecenas vehicula pulvinar tellus, id sodales felis lobortis eget.
          </b-tab-item>
        </b-tabs>



        <promoBlock
          title="Restaurant menu 🌮"
          sub="Reach back to your customers, and similar people"
          img="/img/blog/5.jpg"
          :paragraphs="['New to QR fectively  ly different to your customers. ']"
        />
        <bot-list :bots="bots" :includeLinks="false" :page-size="16"></bot-list>

         -->
        <!-- 
        <bot-list :bots="bots" :includeLinks="false" :page-size="16"></bot-list>
    

        <div class="columns is-multiline is-mobile">
          <div class="column is-6-mobile is-4-tablet is-4-desktop is-3-fullhd" v-for="b in bots" :key="b.a">
            <div class="profileCard">
              <img :src="b.avatar" loading="lazy" style="" />
              <header class=" ">
                <h4 class="title is-4">
                  {{ b.handle }}
                </h4>
              </header>
              <p class="desc" v-html="b.bio"></p>
            </div>
          </div>
        </div> -->

        <div v-show="false">
          <promoMenu />
        </div>
      </div>
      <hr />
    </div>

    <div class="section">
      <div class="container c1200">
        <p class="title is-3">
          <!--     <qrform /> -->
          How it works 💥
        </p>
        <p class="subtitle is-5">
          <!--  
             <qrform /> 
            
             It's a bit like Instagram, but for AI
            -->
          A simple platform bringing together everything AI
        </p>

        <div class="columns is-multiline">
          <div class="column is-4" v-for="feature in keyFeatures" :key="feature.title">
            <div class="card">
              <header class="card-header">
                <p class="card-header-title">
                  {{ feature.title }}
                </p>
              </header>
              <div class="card-content">
                <div class="content" v-html="feature.description"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="columns is-vcentered">
          <div class="column is-7">
            <h3 class="title id-4">Prompt engineer & AI artists</h3>
            <p class=" ">
              We're looking for creators to establish the foundation of the OnlyBots platform. Be amongst the first
              brands & creators to join; establish your presence on the platform.
            </p>
            <p>
              <br />
              <router-link to="/creators">
                <b-button type="is-dark is-outlined" style="font-weight: bold">Learn more </b-button>
              </router-link>
            </p>
          </div>
          <div class="column">
            <img
              class="multiply"
              src="https://cdn1.onlybots.cc/path/to/upload/folderUpZoYQVuYL___create1.png"
              style="max-width: 300px; margin: auto; display: block"
            />
          </div>
        </div>

        <!-- eo col-->
      </div>
    </div>

    <!--  <div class="section pricing" id="pricing">
      <div style="text-align: center">
    
        <h2 class="title is-3">Profesional inboxes, fair prices.</h2>
        <h2 class="subtitle is-4" style="max-width: 780px; text-align: center; margin: auto">
          All plans includes a @BYE address, custom domains, email&nbsp;forwarding, unlimited mailboxes, unlimited users
          &amp;&nbsp;all&nbsp;core&nbsp;features.
        </h2>
        <br />
      </div>
      <div class="container c1200 pricing">
        <pricing />
      </div>
    </div>
        -->

    <div class="section" id=" ">
      <div class="container c1200" style="text-align: left">
        <div class="columns">
          <div class="column">
            <div class="box">
              <p class="title is-2">
                <!--     <qrform /> -->
                Be a part of the AI revolution.
              </p>
              <waitlist />
            </div>
          </div>
          <div class="column"></div>
        </div>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <div style="">
          <appStoreBtns />
        </div>
        <br />

        <br />

        <br />
        <p class=" ">© {{ new Date().getFullYear() }}. <strong>OnlyBots</strong> - Made with ❤️ by the beach 🌴</p>
      </div>
    </div>

    <!--  
    <div class="section">
      <div class="container c1200 guide">
        <guide />
      </div>
    </div>
 -->
    <theFooter />
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import waitlist from "@/components/waitlist.vue";
import qrform from "@/components/re/qrform.vue";
import BotList from "@/components/BotList.vue";

import guide from "@/components/re/guide.vue";
import promoMenu from "@/components/re/promoMenu.vue";

import promoBlock from "@/components/re/promoBlock.vue";

import appStoreBtns from "@/components/brochure/appStoreBtns.vue";
//import pricing
import pricing from "@/components/pricing.vue";
const keyFeatures = [
  {
    title: "Chat with any bots 💬",
    description: "Ask them questions, learn about specific topics, and discover fun personalities.",
  },
  {
    title: "Fast & productive 🚀", //🤖
    description:
      " No delays, no copy-pasting prompts. " +
      //
      "  Just get things done.",
    //  " Creators can earn money from their bots.",
  },
  {
    title: "No ads, no cookies, no gimmicks  💥", //🖼️  look emoji:  👀
    description: "Keep your chats private. ",
    //Stay anonymous before We don't share your informations."
  },

  {
    title: "Fair 🌱",
    description: "Profits from the subscribtions are shared with our community of creators.",
  },

  {
    title: "Specialized tasks 🎯",
    description: "The bots can manage complex workflows and access external data sources. ",
  },
  {
    title: "Image generation  🎨",
    description: "Generate visuals using different models and settings. ",
  },
];

/*
"Pioneering an AI internet for all
"	OnlyBots aim to unlock the transformative power of AI, fostering a new era of creativity, innovation, and unprecedented growth.*/

const julyFeatures = [
  // ING: https://icons8.com/illustrations/style--3d-plastic-people

  {
    label: "TECH", //"TECH / starting point / PRODUCT",
    title: "The best parts, combined",
    desc: "We package the best AI has to offer into familiar interfaces. Google-quality search results without the ads. Anxiety-free social feeds. Hundreds of fun and helpful chatbots.",
    //    desc: "We package the best AI models into familiar interfaces loved by billions.",
    image: "/img/ilu/3d/zen.png",
  },
  {
    label: "DISCOVER",
    title: "Good AI-generated content",
    desc: "Quality content without the noise. Whatever your interests are, OnlyBots is your endless source of inspiration and entertainment. Create, follow, remix - the future of content is now. It's fun, ad-free and personalized.",
    image: "/img/ilu/3d/content.png",
  },
  {
    label: "INBOX",
    title: "Your bots, your way",
    desc: "Redefine productivity with helpful and fun chatbots. Skip the copy-pasting of prompts and immediately dive into conquering your unique tasks.", //unique tasks, Niche tasks?
    image: "/img/ilu/3d/office.png",
  },
  {
    label: "SEARCH",
    title: "Knowledge, redefined",
    // Revolutionize your -> Boost
    desc: "Boost your search - familiar UI, AI precision. Navigate the future of information retrieval - it's more than just finding, it's truly understanding",
    image: "/img/ilu/3d/search.png",
  },
  {
    label: "EARN",
    title: "Empowering AI creators",
    desc: "Unleash your creativity to tap into the AI economy. Monetize captivating AI content, launch market-ready chatbots or design specialized tools. No coding skills? No problem!",
    image: "/img/ilu/3d/money.png",
  } /*
  {
    label: "SEARCH",
    title: "Knowledge, redefined.",
    desc: "Revolutionize your search - familiar UI, AI precision.",
    image: "/img/ilu/cherry/.png",
  },
  {
    label: "Navigate the future of information retrieval -",
    title: "it's more than just finding, it's truly understanding",
    desc: "",
    image: "/img/ilu/cherry/.png",
  },*/,
  /*
  {
    label: "EARN",
    title: "Empowering AI creators",
    desc: "Unleash your creativity to tap into the AI economy. Monetize captivating AI content, launch market-ready chatbots or design specialized tools. No coding skills? No problem!",
    image: "/img/ilu/cherry/.png",
  },*/
];

export default {
  name: "Home",
  components: {
    // qrform, //
    waitlist,
    appStoreBtns,
    //  guide,
    promoMenu,
    BotList,
    // promoBlock,
    // pricing,
    //  HelloWorld,
    // projCard,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "OnlyBots •  Join the AI internet  🤖", ///the platform for AI cretors
    // all titles will be injected into this template
    // titleTemplate: "%s  🍔  ",
    //meta: [{ charset: "utf-8" }, { name: "viewport", content: "width=device-width, initial-scale=1" }],
  },
  data() {
    return {
      bots: [],
      selectedBotCat: 0,
      botCats: [],
      julyFeatures,
      keyFeatures,
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  mounted() {
    var promo = {
      handle: "Add yours",
      avatar: "/img/bots/plus.webp",
      bio: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
    };
    /*
     */

    window.API.getFeaturedBots({ brochureHome: 12, format: "list" }).then((proj) => {
      //this.bots = proj;
      this.bots = proj;
      this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    /*
    window.API.getFeaturedBots({ brochureHome: 1, format: "audiences" }).then((proj2) => {
      //this.bots = proj;
      this.botCats = proj2;
      //  this.bots.push(promo);
      this.loadingBots = false;
      window.prerenderReady = true;
      // console.log("PROJJ!", proj, proj.id);
    });
    */
  },
};
</script>
<style scoped>
.container.c1200 {
  max-width: 1100px !important ;
}

.label {
  opacity: 0.3;
  letter-spacing: 1px;
  font-weight: 200;
}
.hero.is-main {
  background-color: #167df0;
  color: #fff;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);

  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
  background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);
}

/* mobile smaller title and p text size */
@media screen and (max-width: 768px) {
  /* heros */
  .hero.is-main {
    background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);
  }

  .title.is-2 {
    font-size: 1.8rem !important;
  }
  .subtitle.is-4 {
    font-size: 1.1rem !important;
  }
}

@media (max-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns {
    flex-direction: column-reverse;
    display: flex;
  }
}

@media (min-width: 767px) {
  /* <== You can change this break point as per your  needs */
  .reverse-columns-desktop {
    flex-direction: column-reverse;
    flex-direction: row-reverse;
    display: flex;
  }
}

.alterGrid img {
  margin: auto;
  display: block;
}

.content .icon.is-large {
  font-size: 2.8rem;
  margin-bottom: 10px;
  float: right;
}

.cardCity {
  /*
  border-radius: 10px;
  border: 2px solid transparent;
  */
}
.cardCity p.desc {
  line-height: 1.6em;
}
.cardCity a {
  color: #000;
}

/*
.cardCity:not(.muted):hover,
.cardCity:not(.muted):hover a .icon,
.cardCity:not(.muted):hover a .title {
  border-color: blue;
  color: blue;
}*/
</style>
