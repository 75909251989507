<template>
  <div class=" ">
    <titleBar
      title=" "
      :pitable="true"
      :inline="true"
      :back="false"
      :showBackArrowDesktop="true"
      subtitle=""
      :centered="false"
      theme="white"
      :showSignup="false"
      containerClass="donotuse"
      :showDesktop="true"
      :showAfterScroll="0"
      :scrollTopOnClick="true"
      actionButtonStyle="small"
      actionButtonRoundBg="transparent"
      iconPack="far"
      :isHome="false"
      :actions="[
        {
          icon: 'search',
          to: '/explore',
          hidden: false,
          hiddenTablet: false,
        },
        {
          icon: 'bell',
          to: '/notifications',
          hidden: false,
          hiddenTablet: false,
        },
        /*  {
          icon: 'comment',
          to: '/messages',
          hidden: false,
          hiddenTablet: false,
        },*/
      ]"
    />

    <div class="search-container">
      <div class="search-form" style="">
        <div>
          <div class="logo-wrap">
            <img src="/img/brand/ob_logotype_fill.svg" alt=" " class="logo_search_home" style="" />
          </div>
          <!--  

            filter: invert(1); filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg); color: red
            
    <svg style="color: red" fill="blue">
            <use xlink:href="/img/brand/ob_logotype_fill.svg#img" href="/img/brand/ob_logotype_fill.svg#img"></use>
          </svg>



          TODO: size of input for home... bigger, classes are already there.
          -->

          <br />
          <searchInput
            v-model="search"
            @search="performSearch"
            style="text-align: left; width: 645px; position: relative"
            :focussedOnLoad="isDesktop == true"
            :placeholder="placeholder"
            size="is-largeTODO"
          />

          <form @submit.prevent="performSearch">
            <!-- 
            <b-field>
              <b-input
                class="search-input"
                v-model="search"
                @keyup.enter="performSearch"
                placeholder="  "
                icon="fas fa-search"
                expanded
                rounded
                size="is-large"
              ></b-input>
            </b-field>
             -->
            <br />

            <span class="buttons" style="display: block">
              <b-button type="is-successNO" style="padding: 4px 38px" :native-type="!search ? 'button' : 'submit'">
                Search
              </b-button>
              <!--   <b-button type="is-dark" outlined> Explore <b-icon icon="caret-down" /></b-button>

           Button with dropdown icon to toggle dropdown content -->

              <b-dropdown :trigger="verticalDropOpen" position="is-bottom-left" style="text-align: left">
                <template #trigger>
                  <b-button type="is-text" outlined style="text-decoration: none">
                    <!--  
                    <b-icon
                      v-show="selectedVertical.label != 'Everything'"
                      :icon="selectedVertical.icon"
                      style="margin-right: 10px"
                    />
                  
                      <b-icon pack="fal" icon="caret-down" style="margin-left: 10px" />
                    -->

                    {{ selectedVertical.labelHome || selectedVertical.label }}
                    <b-icon pack="far" icon="caret-down" style="margin-left: 3px" />
                  </b-button>
                </template>

                <b-dropdown-item
                  v-for="(vertical, index) in verticals"
                  :key="index"
                  :aria-role="'listitem'"
                  @click="verticalDropdownIndex = index"
                >
                  <b-icon pack="far" :icon="vertical.icon" />
                  {{ vertical.labelHome || vertical.label }}
                </b-dropdown-item>
              </b-dropdown>
            </span>

            <br />

            Explore:
            <span v-for="(link, index) in exploreLinks" :key="index">
              <router-link :to="link.path" class="exploreLink">{{ link.name }}</router-link
              ><span v-if="index !== exploreLinks.length - 1">, </span>
            </span>

            <br /><br />
            <br /><br />
            <br /><br />
            <br /><br />
          </form>
        </div>
      </div>
      <div class="arrow" style="text-align: center">
        Explore our features <br />
        <b-icon icon="arrow-circle-down" pack="fal" size="is-large"></b-icon>

        <!-- your arrow content or image -->
      </div>
    </div>

    <div class="section">
      <div class="container w1000">
        <div class="text-align-center">
          <p class="title is-2">Ask anything</p>

          <p class="subtitle is-4">
            Ask your questions – short, long or anything in between. The more precise you ask, the better the answer.
          </p>
          <br />
          <br />
        </div>

        <div class="columns is-multiline is-mobile">
          <div class="column is-4-desktop is-6-mobile" v-for="item in QUERIES_EXAMPLES.slice(0, 3)" :key="item.title">
            <queryExampleCard :item="item" @search="handleSearchFromExample" />
          </div>
        </div>
      </div>
    </div>

    <div class="section">
      <div class="container">
        <FeaturedBots />
        <!-- 
        <div class="columns is-multiline">
          <div class="column is-3-desktop is-12-tablet">
            <h1 class="title is-3">Your APIs</h1>
            <h1 class="subtitle is-5">Plan, prototype and manage API projects.</h1>
          

            <router-link :to="{ path: $store.main.state.userToken ? '/new' : '/login' }" active-class="is-active" exact>
              <b-button rounded>Add a new API</b-button>
            </router-link>
          </div>
          <div class="column">
            <div class="columns is-multiline">
              <div class="column is-6-tablet is-6-desktop is-4" v-for="i in projs" :key="i.id">
              
                <router-link :to="{ path: '/' + i.id }" active-class="is-active" exact>
                  <projCard :proj="i" />
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <router-link :to="{ path: '/explore' }">
          <b-button type="is-info is-outlined">Explore Public examples</b-button>
        </router-link>-->
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
import FeaturedBots from "@/components/FeaturedBots.vue";
import searchInput from "@/components/search/searchInput.vue";
import queryExampleCard from "@/components/search/queryExampleCard.vue";
import { SEARCH_VERTICALS } from "@/data/searchVerticals.js";
import { QUERIES_EXAMPLES } from "@/data/queriesExamples.js";

import _ from "lodash";
export default {
  name: "Home",
  components: {
    FeaturedBots,
    queryExampleCard,
    searchInput,
    //  HelloWorld,
    //   projCard,
  },
  data() {
    return {
      QUERIES_EXAMPLES: _.shuffle(QUERIES_EXAMPLES),
      isDesktop: false,
      verticalDropOpen: false,
      projs: [],
      loadingProj: true,
      publi: [],
      search: "",
      exploreLinks: [
        { name: "Bots", path: "/bots" },
        { name: "Images", path: "/images" },

        { name: "Templates", path: "/templates" },
        { name: "Articles", path: "/articles" },
      ],
      verticals: SEARCH_VERTICALS.filter((i) => i.featured),
      /*
      [
        { label: "Everything", icon: "search" },
        { label: "Images", icon: "image" },
        { label: "Bots", icon: "user-friends" },
        { label: "Chats", icon: "comments" },
      ],*/
      verticalDropdownIndex: 0,
      // loadingOps: true,
    };
  },
  computed: {
    selectedVertical() {
      return this.verticals[this.verticalDropdownIndex];
    },
    vertical() {
      return this.selectedVertical.id;
    },
    placeholder() {
      var def = "Search anything";
      return this.selectedVertical.placeholder || def;
    },
  },
  methods: {
    async performSearch() {
      this.isLoading = true;
      var q = String(this.search).trim();
      //  alert(3);
      // perform your search here, then update results and isLoading accordingly
      // the following is a placeholder and should be replaced with your own search code

      //redirect to search page
      //withj ?q=serch

      var params = { q: q };
      params.v = this.vertical;
      this.$router.push({ path: "/search", query: params });
    },
    handleSearchFromExample(ex) {
      this.$router.push({ path: "/search", query: { q: ex.description } });
    },
    detectDevice() {
      // Checking for a common mobile width. You can adjust as needed.
      if (window.innerWidth >= 768) {
        this.isDesktop = true;
      }
    },
  },
  created() {
    this.detectDevice();
  },
  mounted() {
    /*
    window.API.getPublicProjects().then((proj) => {
      this.publi = proj;
      this.loadingProjPubli = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/

    var u = this.$store.main.state.userId;

    //  this.projs = proj;
    this.loadingProj = false;

    /*
    window.API.getUserProjects(u).then((proj) => {
      this.projs = proj;
      this.loadingProj = false;
      // console.log("PROJJ!", proj, proj.id);
    });*/
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
<style scoped>
.logo-wrap {
  display: flex;
  flex-direction: column;
  align-items: baseline;
  min-height: 150px;
  max-height: 300px;
  height: calc(100vh - 628px);
}
.logo_search_home {
  width: 50vw;
  max-width: 300px;
  margin: auto;
  display: block;
  margin-bottom: 30px;
}

.search-container {
  /*
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-content: flex-start;
  align-items: center;
  */
  position: relative;
  height: 98vh;
  min-height: 400px;
}

/* on mobile, container is only 80% of the screen */
@media (max-width: 1024px) {
  .search-container {
    height: 80vh;
  }
}

.search-form {
  max-width: 700px;
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.search-form img {
  display: block;
}

.arrow {
  position: absolute;
  bottom: 0;
  /* assuming you want the arrow centered horizontally */
  left: 50%;
  transform: translateX(-50%);
}

.search-input {
  width: 80vw;
  max-width: 600px;
}

.exploreLink {
  color: #2fb185;
}
.exploreLink:hover {
  text-decoration: underline;
  color: #000;
}
</style>
