<template>
  <span>
    <template v-if="noLink">
      <span><slot /></span>
    </template>
    <template v-else>
      <router-link v-bind="$props"> <slot /> </router-link>
      <!--
        <a v-if="isExternalLink" v-bind="$attrs" :href="to" target="_blank">
          <slot />
        </a>
        <router-link v-else v-bind="$props" custom v-slot="{ isActive, href, navigate }">
          <a v-bind="$attrs" :href="href" @click="navigate" :class="isActive ? activeClass : inactiveClass">
            <slot />
          </a>
        </router-link>
        -->
    </template>
  </span>
</template>

<script>
//import { RouterLink } from 'vue-router';
//import VueRouter from 'vue-router';
//console.log(VueRouter, RouterLink);

var oriProps =
  "to replace append tag active-class exact exact-path exact-path-active-class event exact-active-class aria-current-value".split(
    " "
  );

export default {
  name: "AppLink",
  inheritAttrs: false,

  props: [
    // add @ts-ignore if using TypeScript
    // ...RouterLink.props,
    //...oriProps,
    "to",
    "replace",
    "append",
    "tag",
    "active-class",
    "exact",
    "exact-path",
    "exact-path-active-class",
    "event",
    "exact-active-class",
    "aria-current-value",
    "inactiveClass",

    //: Boolean //to programaticaly disable behavior of link. Replace with SPAN that has same slot
    "noLink",
  ],

  computed: {
    isExternalLink() {
      return typeof this.to === "string" && this.to.startsWith("http");
    },
  },
};
</script>
