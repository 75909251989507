<template>
  <nav class="app-bar is-hidden-desktop" v-if="$store.main.state.userToken">
    <div class="left-actions">
      <div v-if="isHome" class="logo-container">
        <router-link to="/">
          <img src="/img/brand/ob_logotype_black.svg" class="logo" alt="Logo" />
        </router-link>
      </div>
      <div v-else class="title-container">
        <h1>{{ title }}</h1>
      </div>
      <div class="actions-container">
        <div v-for="(action, index) in left" :key="index" class="action">
          <router-link :to="action.href" class="action-link">
            <i :class="action.icon"></i>
            <span v-if="action.badge" class="badge">{{ action.badge }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div class="right-actions">
      <div v-for="(action, index) in right" :key="index" class="action">
        <router-link :to="action.href" class="action-link">
          <i :class="action.icon"></i>
          <span v-if="action.badge" class="badge">{{ action.badge }}</span>
        </router-link>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "AppBar",
  props: {
    title: {
      type: String,
      required: true,
    },
    left: {
      type: Array,
      default: () => [],
    },
    right: {
      type: Array,
      default: () => [],
    },
    isHome: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
.logo {
  height: 40px;
}
.app-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;
  padding: 0 16px;
  background-color: var(--NAV_BG_COLOR);
  color: #000000;
}

.left-actions {
  display: flex;
  align-items: center;
}

.logo-container {
  margin-right: 16px;
}

.title-container {
  font-size: 24px;
  margin-right: 16px;
}

.actions-container {
  display: flex;
  align-items: center;
}

.action {
  background: white;
  height: 48px;
  margin-right: 10px;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 28px;
  display: inline-block;
}

.action-link {
  display: flex;
  align-items: center;
  position: relative;
}

.action-link:hover .badge {
  opacity: 1;
}

.badge {
  position: absolute;
  top: -6px;
  right: -6px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 18px;
  min-width: 18px;
  padding: 0 4px;
  border-radius: 9px;
  background-color: #f44336;
  color: #fff;
  font-size: 12px;
  font-weight: bold;
  line-height: 1;
  opacity: 0.8;
  transition: opacity 0.2s ease-in-out;
}
</style>
