<template>
  <div class="homewrap">
    <HomeLogged v-if="$store.main.state.userToken" />
    <div v-else>
      <Login v-if="$route.query.source == 'homescreen'" />

      <HomeGpt v-else-if="isGpt" />
      <HomePublic v-else />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
//import HelloWorld from "@/components/HelloWorld.vue";
//import projCard from "@/components/projCard.vue";
import HomeLogged from "@/views/HomeLogged.vue";
import HomePublic from "@/views/HomePublic.vue";
import HomeGpt from "@/views/HomeGpt.vue";
import Login from "@/views/Login.vue";
export default {
  name: "Home",
  components: {
    //  HelloWorld,
    Login,
    HomePublic,
    HomeGpt,
    HomeLogged,
    // projCard,
  },
  data() {
    return {
      projs: [],
      loadingProj: true,
      // loadingOps: true,
    };
  },
  computed: {
    isGpt() {
      //if domain is GPT.com then return true
      const DEBUG_GPT_HOME_DEV = true;
      if (
        window.SITE == "qualitygpts.com" ||
        window.location.hostname == "qualitygpts.com" ||
        (DEBUG_GPT_HOME_DEV && window.location.hostname == "localhost")
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {
    /*
    window.API.getProjectOperations(this.$route.params.project).then((ops) => {
      var opId = this.$route.params.operation;
      this.ops = ops;
      this.loadingOps = false;
      this.op = ops.filter((i) => i.operationId == opId)[0]; //TODO: validaiton
    });*/
  },
};
</script>
