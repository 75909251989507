<template>
  <div>
    <div class="section" v-if="!loading">
      <div class="container" style="max-width: 1000px">
        <div v-for="(block, index) in blocks" :key="block.blockType + index">
          <ExploreTopicTags v-if="block.blockType === 'topicTags'" :tags="block.tags" :title="block.title" />

          <ExploreRow v-if="block.blockType === 'row'" :bots="block.bots" :title="block.title" />
          <BlockContent :item="block" v-if="block.blockType === 'blockContent'" />

          <div v-if="block.blockType === 'tripleCards'" class=" ">
            <h4 class="title is-4">{{ block.title }}</h4>
            <div class="columns">
              <div
                class="column"
                :class="index == 0 ? 'is-5' : ''"
                v-for="(bot, index) in block.bots"
                :key="bot.handle"
              >
                <bot-card :bot="bot" :index="index" :include-links="true" class="" layout="photo"></bot-card>
              </div>
            </div>
          </div>

          <!-- Add other components and conditions for other blockTypes... -->
        </div>

        <!-- for each cats show buttons, felx dipslya -->
      </div>
    </div>
    <!-- 
    <theFooter /> -->
  </div>
</template>

<script>
import FullPageSearch from "@/components/e/fullPageSearch.vue";
import BotList from "@/components/BotList.vue";
import cats from "@/data/categories.js";
//lodash
import _ from "lodash";
/*
const cats = [
  { label: "All", value: "all", icon: "globe" },
  ///utility, entertainement, assistance, education
  { label: "Entertainment", value: "entertainment", icon: "grin" },
  { label: "Utility", value: "utility", icon: "wrench" },
  { label: "Assistance", value: "assistance", icon: "user" },
  //shopping
  { label: "Shopping", value: "shopping", icon: "shopping-cart" },
  //brands
  { label: "Brands", value: "brands", icon: "building" }, //user-headset concirege bell, bag

  //{ label: "Fun", value: "fun", icon: "smile" },
  // { label: "Education", value: "education", icon: "graduation-cap" },
];*/
/*
Utility and assistance are similar in some ways, but they represent different ways chatbots can be used as functional tools.

Utility chatbots are designed to perform specific tasks or functions such as scheduling appointments, making reservations, placing orders etc. Their main purpose is to make certain processes easier, more convenient and efficient, but they do not aim to have deeper connections with end-users.

On the other hand, assistance chatbots are designed to offer personalized support, advice or guidance to end-users. Assistance chatbots come in different types such as Customer service chatbots, Health & Wellness chatbots to name few. They provide personalized communication modalities and emotional connections,, Sometimes these chatbots are blended with AI levels to provide context-based snippets like insurance broker chatbots, financial advisory chatbots, healthcare or mental well-being chatbots from there combining the sheer efficiencies of machine learning or big data analytics.

In summary, Utility, and Assistant category are related, But assistance chatbots focus more on personalizing the communication and possibly reducing the need for human operatives while Utility chatbots focus more on function rather than personalization.

*/

import BotCard from "@/components/BotCard.vue";

import ExploreTopicTags from "@/components/explore/ExploreTopicTags.vue";
import ExploreRow from "@/components/explore/ExploreRow.vue";

import BlockContent from "@/components/explore/BlockContent.vue";

export default {
  components: {
    // FullPageSearch,
    ExploreTopicTags,
    ExploreRow,
    // BotList,
    BotCard,
    BlockContent,
    //  FullPageSearch,
  },
  props: {
    blocks: {
      type: Array,
      default: () => {
        return [];
      },
    },
    /*
    collections: {
      type: Array,
      default: () => {
        return [];
      },
    },
    topics: {
      type: Array,
      default: () => {
        return [];
      },
    },*/
  },
  data() {
    return {
      search: "",
      loading: true,
      bots: [],
      suggestedUsers: [
        { id: 1, name: "John Smith" },
        { id: 2, name: "Jane Doe" },
        { id: 3, name: "Bob Johnson" },
      ],
      trendingHashtags: [
        { id: 1, name: "javascript" },
        { id: 2, name: "vuejs" },
        { id: 3, name: "bulma" },
      ],
    };
  },

  methods: {
    searchBots() {
      // console.log("searching for", this.search);
      this.$router.push(`/explore-bots/${this.$route.params.cat || "all"}/${this.search}`);
      // this.$router.push({ name: "search", params: { query: this.search } });
    },
  },
  mounted() {
    window.API.searchBots().then((proj) => {
      this.bots = proj;
      this.loading = false;
      // console.log("PROJJ!", proj, proj.id);
    });
  },
  //cats
  computed: {
    cats() {
      return cats;
    },
    topicsShown() {
      return this.topics;
    },
    /*
    tripleCards() {
      var bots = this.bots;
      const TOP_FEATURED = "her RoastMe RageAgainstTheAI"; //"RoastMe her one.up"// "RoastMe her one.up".split(" ");
      var items = TOP_FEATURED.split(" ");
      var ccc = items.map((c) => {
        //match bots from bots array
        var k = bots.find((b) => b?.handle == c);
        return k;
      });
      return _.compact(ccc);
    },
    collectionsShown() {
      var bots = this.bots;
      var ccc = this.collections.map((c) => {
        //match bots from bots array
        var ids = c.items.split(" ");

        //exclude empty null items using lodash
        ids = _.compact(ids);
        c.bots = ids.map((bot) => {
          //use lowercase compare
          return bots.find((b) => {
            return b?.handle.toLowerCase() == bot.toLowerCase();
          });
        });
        //delete c.items;

        return c;
      });
      return ccc;
    },*/
  },
};
</script>

<style scoped></style>
