const SEARCH_VERTICALS = [
  {
    id: "all",
    label: "All",
    labelHome: "Everything",
    //  baseUrl: "https://www.google.com/search?q=bots",
    verticalsBtns: [],
    icon: "search",
    placeholder: "Search anything",
    featured: true,
  },
  {
    id: "chats",
    label: "Chats",
    // baseUrl: "https://www.google.com/search?q=articles",
    verticalsBtns: "images posts articles bots users".split(" "),
    icon: "comments",
    placeholder: "Ask your bots anything",
    featured: true,
  },
  {
    id: "images",
    label: "Images",
    //  baseUrl: "https://www.google.com/imghp?hl=en&tab=wi&authuser=0&ogbl",
    verticalsBtns: "images  posts articles bots".split(" "),
    icon: "image",
    placeholder: "Find AI images",
    featured: true,
  },

  /**/
  {
    //not in use....
    id: "imagine",
    label: "Create",
    // baseUrl: "https://www.google.com/imghp?hl=en&tab=wi&authuser=0&ogbl",
    verticalsBtns: "images imagine posts articles bots".split(" "),
  },

  {
    id: "posts",
    label: "Posts",
    // baseUrl: "https://www.google.com/search?q=articles",
    verticalsBtns: "images posts articles bots".split(" "),
  },
  {
    id: "articles",
    label: "Articles",
    //  baseUrl: "https://www.google.com/search?q=articles",
    verticalsBtns: "images posts articles bots".split(" "),
    placeholder: "Find AI articles",
  },
  {
    id: "bots",
    label: "Bots",
    baseUrl: "https://www.google.com/search?q=bots",
    verticalsBtns: "images posts articles bots users".split(" "),
    icon: "user-friends",
    placeholder: "Find chatbots, creators and AI tools   ",
    featured: true,
  },
  {
    id: "users",
    label: "Humans",
    // baseUrl: "https://www.google.com/search?q=articles",
    verticalsBtns: "images posts articles bots users".split(" "),
  },

  /*
  {
    id: "music",
    label: "Music",
    baseUrl: "https://www.google.com/videohp?hl=en&tab=wv&authuser=0&ogbl",
    verticalsBtns: "music images articles".split(" "),
  },

  {
    id: "maps",
    label: "Maps",
    baseUrl: "https://www.google.com/maps?hl=en&tab=wl&authuser=0&ogbl",
  },
*/
];

export { SEARCH_VERTICALS };
