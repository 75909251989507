<template>
  <div>
    <b-sidebar
      class="sidebar"
      :class="{ rail: isRail }"
      position="static"
      :mobile="mobile"
      :expand-on-hover="expandOnHover"
      :reduce="reduce"
      :delay="expandWithDelay ? 500 : null"
      type="is-light"
      open
    >
      <div class="p-1">
        <div class="block">
          <div
            class="logolink"
            :class="{
              isSearchHome: $route.path == '/',
            }"
            @click="clickLogo"
          >
            <img
              :src="isRail ? '/img/brand/logo_icon_1.png' : '/img/brand/ob_logotype_fill.svg'"
              alt=" "
              class="logo_sidebar"
            />
          </div>
          <!--   <img src="/img/brand/ob_logotype_black.svg" alt=" " class="logo_sidebar" /> -->
        </div>
        <b-menu class="is-custom-mobile menuWrap" v-if="!isRail">
          <b-menu-list label="">
            <b-menu-item
              v-for="item in filteredMenuItems"
              :key="item.label"
              :tag="item.tag"
              :to="item.to"
              :icon-pack="item.iconPack"
              :icon="item.icon"
              :label="item.label"
            ></b-menu-item>

            <!-- 

       


            <b-menu-item tag="router-link" to="/" icon-pack="far" icon="search" label="Search"></b-menu-item>

            <b-menu-item
              v-if="isLoggedIn"
              tag="router-link"
              to="/images/new"
              icon-pack="far"
              icon="sparkles"
              label="Create"
            ></b-menu-item>

            <div style="padding-top: 20px" />

            <b-menu-item tag="router-link" to="/latest" icon-pack="far" icon="newspaper" label="Feed"></b-menu-item>

            <b-menu-item tag="router-link" to="/messages" icon-pack="far" icon="comment" label="Chats"></b-menu-item>

            <b-menu-item
              v-if="isLoggedIn"
              tag="router-link"
              to="/bookmarks"
              icon-pack="far"
              icon="bookmark"
              label="Saved"
            ></b-menu-item>

            <b-menu-item
              tag="router-link"
              :to="'/explore'"
              icon-pack="far"
              icon="compass"
              label="Explore"
            ></b-menu-item>

                 -->

            <!-- Following ?-->

            <!-- 
     <hr />
            <hr />

              july 2023
            <b-menu-item tag="router-link" to="/latest" icon-pack="far" icon="newspaper" label="Feed"></b-menu-item>

            <b-menu-item
              tag="router-link"
              :to="$store.main.getters.hasPages || $store.main.getters.hasSubs ? '/bots' : '/explore'"
              icon-pack="far"
              icon="user-friends"
              label="Bots"
            ></b-menu-item>

            <b-menu-item tag="router-link" to="/messages" icon-pack="far" icon="comment" label="Chats"></b-menu-item>

            <b-menu-item
              v-if="isLoggedIn"
              tag="router-link"
              to="/bookmarks"
              icon-pack="far"
              icon="bookmark"
              label="Saved"
            ></b-menu-item>

            <b-menu-item
              v-if="isLoggedIn"
              tag="router-link"
              to="/generate"
              icon-pack="far"
              icon="sparkles"
              label="Generate"
            ></b-menu-item>

              -->
          </b-menu-list>

          <!--  
 

           -->
        </b-menu>

        <b-menu class="is-custom-mobile menuWrap m3Rail" v-else>
          <b-menu-list label="">
            <b-menu-item
              v-for="item in filteredMenuItems"
              :key="item.label"
              :tag="item.tag"
              :to="item.to"
              :icon-pack="item.iconPack"
              :icon="item.icon"
              :label="item.label"
            ></b-menu-item>
          </b-menu-list>
        </b-menu>

        <br />
        <div class="action-cta">
          <router-link to="/new-bot" v-show="false">
            <b-button rounded class=" " icon-pack="fas" type="is-dark" icon-left="plus-circle"
              >Create Bot
            </b-button></router-link
          >

          <b-dropdown size="is-large" aria-role="list" v-show="false">
            <template #trigger="{ active }">
              <b-button
                rounded
                label="Create"
                :class="active ? 'arrow-up' : 'arrow-down'"
                class="is-fullwidth"
                icon-pack="fas"
                type="is-dark"
                icon-left="plus-circle"
              />
            </template>

            <b-dropdown-item has-link> <router-link to="/generate">Image</router-link></b-dropdown-item>

            <b-dropdown-item has-link>
              <router-link to="/new-bot">New Bot</router-link>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <div class="boxNO" style="padding-left: 20px" v-if="!isRail && botsShown && botsShown.length">
          <hr style="margin: 10px 10px" />
          <!--    <hr />  Shortcuts -->
          <bot-list :bots="botsShown" :page-size="30" :pginate="true" layout="minilist"></bot-list>
        </div>

        <!--  USER MENU frop up  -->

        <b-dropdown
          aria-role="list"
          :position="isRail ? ' is-top-right' : ' is-top-left'"
          class="user-menu"
          style=""
          v-if="isLoggedIn && $store.main.getters.profileAvatar"
        >
          <template #trigger>
            <div class="user-menu-trigger">
              <div class="av-txt">
                <img
                  class="user-avatar avatar"
                  style="background: #04e9d6; border-radius: 50%; height: 45px; width: 45px; margin-right: 8px"
                  :src="$store.main.getters.profileAvatar || 'https://bulma.io/images/placeholders/128x128.png'"
                />

                <div class=" ">
                  <h3>{{ $store.main.getters.handle || "Me" }}</h3>
                </div>
              </div>
              <div class="dotIcon" style="margin-right: 10px">
                <b-icon icon="ellipsis-h" icon-pack="fas"></b-icon>
              </div>
            </div>
            <!-- 
            <b-menu-item icon-pack="fal" icon="user-circle" :label="$store.main.getters.handle || 'Me'"></b-menu-item> -->
          </template>

          <b-dropdown-item
            aria-role="listitem"
            v-for="i in moreMenuVisible"
            :key="i.to"
            :has-link="i.to ? true : false"
          >
            <router-link :to="i.to">
              <div class="media">
                <b-icon class="media-left" :icon="i.icon"></b-icon>
                <div class="media-content">
                  <h3>{{ i.label }}</h3>
                  <small>{{ i.desc }}</small>
                </div>
              </div>
            </router-link>
          </b-dropdown-item>

          <b-dropdown-item :value="false" aria-role="listitem" @click="logUserOut">
            <div class="media">
              <b-icon class="media-left" icon="sign-out"></b-icon>
              <div class="media-content">
                <h3>Log out</h3>
                <!--  
              <small>Only friends can see</small> -->
              </div>
            </div>
          </b-dropdown-item>
        </b-dropdown>

        <!-- link to user profile 
             Logged as
             
        <router-link :to="'/u/' + $store.main.state.userId">
          <div class=" ">
            <div class="profile-info">
              <div class="profile-name">{{ $store.main.state.userId }}</div>
            </div>
          </div>
        </router-link>-->
      </div>
    </b-sidebar>
  </div>
</template>

<script>
export default {
  data() {
    return {
      expandOnHover: false,
      expandWithDelay: false,
      mobile: "hidden",
      reduce: false, //TODO: make responsive
      bots: [],
      screenH: window.innerHeight,
      userPreferRail: true,
    };
  },
  computed: {
    filteredMenuItems() {
      const menuItems = [
        { tag: "router-link", to: "/", iconPack: "far", icon: "search", label: "Search" },
        { tag: "router-link", to: "/explore", iconPack: "far", icon: "compass", label: "Discover" },
        { tag: "router-link", to: "/latest", iconPack: "far", icon: "newspaper", label: "Feed" },

        {
          tag: "router-link",
          to: "/images/new",
          iconPack: "far",
          icon: "sparkles",
          label: "Create",
          requiresAuth: true,
        },
        { tag: "router-link", to: "/bots", iconPack: "far", icon: "user-friends", label: "Bots" },
        { tag: "router-link", to: "/messages", iconPack: "far", icon: "comment", label: "Chats" },
        { tag: "router-link", to: "/bookmarks", iconPack: "far", icon: "bookmark", label: "Saved", requiresAuth: true },
      ];
      if (this.isLoggedIn) {
        return menuItems;
      } else {
        return menuItems.filter((item) => !item.requiresAuth);
      }
    },

    isRail() {
      return this.userPreferRail || window.screen.width < 1024;
    },
    nbBotsShown() {
      //calculate based on screen size height
      const HEADER_H = 600;
      const BOT_H = 43; // 1050 / 30;
      const BOTTOM_MARGIN = 200;
      var nb = Math.floor((window.innerHeight - HEADER_H - BOTTOM_MARGIN) / BOT_H);
      // nb = nb - 3; //spacing for 3
      nb = Math.min(5, nb); //minimum to show
      return nb;
      return 3;
    },
    hasPagesOrSubs() {
      return this.$store.main.getters.hasPages || this.$store.main.getters.hasSubs;
    },
    botsShown() {
      var listPromoAddBot = {
        handle: "Create a bot",
        avatar: "/img/bots/plus.webp",
        bio: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
        url: "/new-bot",
      };

      var listPromoViewMore = {
        handle: this.hasPagesOrSubs ? "View all my bots" : "Discover more",
        avatar: "/img/bots/plus.webp",
        bio: "Design your own bot, and share it with the world", // "Launch a bot for your brand, or just for fun.   ",
        url: "/bots/me",
      };

      var bots = this.bots;

      //filter by category
      if (this.$route.params.cat && this.$route.params.cat != "all") {
        bots = bots.filter((b) => b.category == this.$route.params.cat);
      }
      // only take x bots
      bots = bots.slice(0, this.nbBotsShown);

      bots.push(listPromoViewMore);
      return bots;
    },
    betaCreator: function () {
      return this.$store.main.getters.profile?.betaCreator;
    },
    hasPages() {
      // if (!this.$store.main.profile) return false;
      return this.$store.main.state?.profile?.hasPages || false;
    },
    isLoggedIn() {
      return this.$store.main.state.userToken;
    },
    moreMenuVisible() {
      return this.moreMenu.filter((item) => !item.hidden);
    },

    moreMenu() {
      return [
        {
          label: "Your Profile",
          icon: "user-circle",
          iconPack: "fal",
          to: this.$store.main.getters.profileUrl,
        },
        {
          label: "Your Bots",
          icon: "user-friends",
          iconPack: "fal",
          to: "/bots/me",
          hidden: !this.$store.main.getters.hasPages,
        },

        {
          label: "Notifications",
          icon: "bell",
          iconPack: "fal",
          to: "/notifications",
        },

        {
          label: "Account Settings",
          icon: "cog",
          iconPack: "fal",
          to: "/settings",
        },

        {
          label: "Billing",
          icon: "credit-card",
          iconPack: "fal",
          to: "/billing",
        },
        /*
        {
          label: "Page traffic",
          icon: "analytics",
          iconPack: "fas",
          to: "/pages",
          hidden: !this.hasPages,
        },

        {
          label: "Leaderboard",
          icon: "users-crown",
          iconPack: "fal",
          to: "/leader",
        },
        {
          label: "Your activity",
          icon: "history",
          iconPack: "fal",
          to: "/activity",
        },*/

        {
          label: "Refer",
          icon: "gift",
          iconPack: "fal",
          to: "/refer",
        },

        {
          label: "Report a problem",
          icon: "siren-on",
          iconPack: "fal",
          to: "/report-problem",
        },
        /*
        {
          label: "Logout",
          icon: "sign-out",
          iconPack: "fal",
          logout: true,
        },*/
      ];
    },
  },
  mounted() {
    this.loadBots();
  },
  methods: {
    /*
    scrollToDiv() {
      const element = document.getElementById("pricing");
      element.scrollIntoView({ behavior: "smooth" });
    },*/
    clickLogo() {
      //  alert(this.$route.path);
      var p = this.$route.name;
      // alert(p);
      if (p == "Home" || p == "Search") {
        //scroll to top
        // window.scrollTo(0, 0);
        //smooth trans
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        //  alert(33);
        return false;
      } else {
        this.$router.push("/");
      }
    },
    loadBots() {
      //for quick menu...
      var s = this.currentSearch; //this.$route.params.search || "";
      var opt = {
        // search: s,
        //feedAside: true,
        //DISABLE FOR NOW, to get all counts, we filter in UI, snapier with less than 10000 bots
        //   category: this.$route.params.cat || "",
      };
      this.loading = true;
      window.API.me.getUserBotsShortcuts(opt).then((proj) => {
        this.bots = proj;
        this.loadingBots = false;
        // console.log("PROJJ!", proj, proj.id);
      });
    },

    logUserOut() {
      // alert(3);

      this.$store.main
        .dispatch("logout")
        .then(() => {
          console.log(432432, "LOGGED OUT");
        })
        .catch((e) => {
          console.log(e), "EIRIR 324";
        })
        .then(() => {
          this.$router.push("/login"); //REDIRECT!
          //buefy toast
          this.$buefy.toast.open({
            message: "Logged out, see you soon!",
            type: "is-dark",
            position: "is-bottom-left",
            duration: 9000,
          });
          //alert(3532);
        });
      /*
            localStorage.removeItem("userAuthToken");
            this.$router.push("/login");
            alert('logged out. token removed brooooo')
            */
    },
  },
};
</script>

<style lang="scss">
.b-sidebar .sidebar-content {
  width: 250px !important;
  height: auto;
}

.b-sidebar.rail .sidebar-content {
  width: 88px !important;
}

.logolink {
  transition: all 0.3s ease-in-out;
}
.isSearchHome {
  opacity: 0.01;
}
.rail .isSearchHome {
  opacity: 1;
}
.isSearchHome:hover,
#app.scrolled .isSearchHome {
  opacity: 1;
}
.menuWrap {
  padding-right: 30px;
}

div hr {
  border-top: 1px solid #00000011;
  border-color: #d6dec4;
  margin: 0;
  margin: 1.5rem 0;
}
.dropdown.user-menu {
  position: absolute;
  bottom: 40px;
  left: 0;
  width: auto;
}

.user-menu-trigger {
  cursor: pointer;
  background: transparent;
  display: flex;
  width: 231px;

  background: transparent;
  margin-left: 10px;
  border-radius: 999px;
  padding: 5px;
  flex-direction: row;
  align-content: stretch;
  justify-content: space-between;
  align-items: center;
  background: #00000011;
}

.user-menu-trigger .av-txt {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.user-menu-trigger h3 {
  color: #000;
  font-size: 22px;
}

.user-menu-trigger:hover {
  background: #ffffffe0;
}

.menu-list a:hover {
  border-radius: 999px;
}

.logo_sidebar {
  height: 29px;
  margin-top: 41px;
  margin-left: 19px;
  margin-bottom: 7px;
  cursor: pointer;
}
.sidebar li .icon {
  /*
  opacity: 0.4;*/
  color: #0cd18f;
}

.sidebar .menu-list {
  font-size: 22px;
}
.sidebar .menu-list li {
  margin-top: 1px;
}
.sidebar .menu-list .icon {
  width: 1em;
  height: 1em;
  font-size: 1.3em;
  margin-right: 15px !important;
  vertical-align: text-top; /* hacky way to v-align text */
}

.sidebar .menu-list .router-link-exact-active {
  background-color: #ffffff59;
  border-radius: 70px;
  color: #000;
}
/*   garbare*/
.p-1 {
  position: fixed;
  /* width: 200px;*/
  background: transparent;
  height: 100vh;
  /*
  padding: 1em;
  */
}
.sidebar-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100%;
  // min-height: 100vh;
  .sidebar-layout {
    display: flex;
    flex-direction: row;
    min-height: 100%;
    // min-height: 100vh;
  }
}
@media screen and (max-width: 1023px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini-mobile {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 1024px) {
  .b-sidebar {
    .sidebar-content {
      &.is-mini {
        &:not(.is-mini-expand),
        &.is-mini-expand:not(:hover):not(.is-mini-delayed) {
          .menu-list {
            li {
              a {
                span:nth-child(2) {
                  display: none;
                }
              }
              ul {
                padding-left: 0;
                li {
                  a {
                    display: inline-block;
                  }
                }
              }
            }
          }
          .menu-label:not(:last-child) {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
.is-mini-expand {
  .menu-list a {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.menu-list a {
  color: inherit;
}

.action-cta .button {
  font-size: 19px;
  padding: 0px 26px;
}

.m3Rail {
  margin-top: 20px;
}
.m3Rail a {
  margin-top: 10px;
}
.m3Rail .icon-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 82px;

  font-size: 14px;
  padding: 0;
  padding-top: 8px;
  padding-bottom: 3px;
}
.m3Rail .icon-text .icon {
  margin: 0 !important;
  color: #000;
  font-size: 20px;
}

.rail .user-menu-trigger {
  width: 55px;
  margin-left: 15px;
}
.rail .user-menu-trigger .dotIcon {
  display: none;
}
.rail .user-menu-trigger h3 {
  display: none;
}

.rail .user-menu-trigger .av-txt img {
  margin-right: 0 !important;
}

.rail .logo_sidebar {
  height: 49px;
  margin-top: 15px;
  margin-left: 19px;
  margin-bottom: 17px;

  margin-left: 16px;
}
</style>
