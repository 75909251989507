<template>
  <div>
    <b-tabs v-model="selectedSection" type="is-boxed" expanded size="is-medium">
      <b-tab-item label="Restaurants" value="restaurant">
        <div>
          <promoBlock
            title="Restaurant menu 🌮"
            sub="Reach back to your customers, and similar people"
            img="/img/blog/5.jpg"
            :paragraphs="[
              'New to QR code? No problem. You can either point your QR code to your website, or a PDF menu. There are also many interactive menu solutions that you can use to create a pretty menu.  ',
              `Once you have your menu URL, just paste it here (only-bots.com), and save the generated QR code. You can then print it out and put it on your tables. `,
              `Already using a QR code in your restaurant? Great! It'll be very easy to add retargeting to it. Go to the site you used to create your QR code, and change the destination URL to the one your see under the QR code on only-bots.com. This will effectively add retargeting to your QR code menu, without it being visibly different to your customers. `,
            ]"
          />
        </div>
      </b-tab-item>
      <b-tab-item label="E-commerce" value="ecommerce">
        <promoBlock
          title="E-commerce products 👕"
          sub="Reach people who know and love your products"
          img="/img/blog/shop1.jpg"
          :paragraphs="[
            `They didn't only visit a product page, they actually scanned a QR code
        to learn more about a product. This means they are more likely to buy it.`,
            `You can create ads that are
        tailored to this audience, resulting in higher click-through rates and more conversions.`,
            `Automatically
        generate QR codes for products using simple url parameters. For example: `,
            codeSample,
            `which generates a QR code image leading points to: `,
            `<em>https://mysite.com/product33?utm_source=qr&utm_campaign=retarget&product=1234</em>`,
            `Place it on printed ads, digital displays or your actual products. All traffic from these QR codes will be automatically added to your retargeting and lookalike audience. `,
          ]"
        />
      </b-tab-item>
      <b-tab-item label="Local business" value="localbusiness">
        <promoBlock
          title="Boost your local business with QR codes"
          img="/img/blog/4.jpg"
          paragraphs="QR codes are a great way to boost your local business. By placing QR codes in strategic locations, you can direct customers to your website, social media pages, or online ordering system. With our solution, you can create QR codes that are tailored to your business and branding, helping you stand out from the competition and attract more customers."
        />
      </b-tab-item>
      <b-tab-item label="Agencies" value="agencies">
        <promoBlock
          title="Attract more clients with targeted ads"
          img="/img/blog/2b.jpg"
          paragraphs="As an agency, your clients depend on you to help them grow their business. With our targeted ads solution, you can deliver results that will make your clients happy. By creating ads that are tailored to their audience, you can help them reach more customers and increase their sales. Plus, with our reporting tools, you can easily show your clients the ROI of their ad spend."
        />
      </b-tab-item>
      <b-tab-item label="Advanced stats" value="advancedstats">
        <promoBlock
          title="Get the data you need to succeed"
          img="/img/blog/6.jpg"
          paragraphs="To succeed in today's digital landscape, you need to have the right data. Our advanced stats solution provides you with the insights you need to make informed decisions about your business. With our reporting tools, you can see how your ads are performing, which products are selling, and how your customers are interacting with your website. Use this data to optimize your strategy and grow your business."
        />
      </b-tab-item>
    </b-tabs>
  </div>
</template>

<script>
import promoBlock from "@/components/re/promoBlock.vue";

export default {
  components: {
    promoBlock,
  },
  data() {
    return {
      codeSample:
        `<pre><code>` +
        "&lt;img src=&quot;https://qr.only-bots.com/auto?fb=12345&amp;goog=456789&amp;url=https://mysite.com/product33&quot; /&gt;" +
        `</code  ></pre  >
      `,
      selectedSection: "restaurant", // Default selected section is "Restaurant"
    };
  },
};
</script>
<style>
/* tab item pad top */
.b-tabs > .tabs {
  padding-bottom: 50px;
}
</style>
