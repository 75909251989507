<template>
  <div id="app">
    <!-- 

        <Navbar> </Navbar>-->
    <!--  
      <router-link to="/">Home</router-link> |
      <router-link to="/about">About</router-link>
       {{ $store.main.state.userToken }}==this.$store.main.state.userToken
    
       
      -->

    <div v-if="showNoAccessMessage" class="noBetaAccess" style="">
      <div class="noBetaAccess container w800">
        <h1 class="title is-1">You're on the waitlist!</h1>
        <p class="subtitle is-5">Invite friends to get early access.</p>

        <b-input :value="inviteUrl" readonly style="max-width: 500px; margin: auto"></b-input>
      </div>
    </div>
    <!-- LOGGED LAYOUT, desktop -->
    <table v-if="$store.main.state.userToken" class="con" style="height: 100%">
      <tr>
        <td class="ll is-hidden-touch left-layout">
          <!-- v-show="NO_SIDEBAR_ROUTES.includes($route.params.name)"-->
          <Sidebar />
        </td>
        <td class="rr" style="width: 100%">
          <div class="route-view-wrap" style="">
            <router-view />
          </div>
        </td>
      </tr>
    </table>

    <!-- PUBLIC LAYOUT -->

    <template v-if="!$store.main.state.userToken">
      <brandedNavbar v-if="$route.name != 'Home' && $route.name != 'creators'" />
      <NavbarGpt v-else-if="isGpt" />
      <Navbar v-else style="padding: 20px"> </Navbar>

      <div class="route-view-wrap-public" style="margin: auto">
        <router-view />
      </div>
    </template>

    <!-- 
      DOCS: https://vue-bottom-navigation.netlify.app/v2/guide/swipe/
  
    <SwipeBottomNavigation
      v-if="$store.main.state.userToken && !ROUTES_NO_BOTTOM_BAR.includes($route.name || 'unamedRoute')"
      id="bottonNav"
      :swiperColor="accentColor"
      :iconColor="accentColor"
      :options="bottomNavOptions"
      class="is-hidden-desktop"
      v-model="selectedBottomNav"
      :replaceRoute="false"
    />
      -->

    <RingBottomNavigation
      v-if="$store.main.state.userToken && !ROUTES_NO_BOTTOM_BAR.includes($route.name || 'unamedRoute')"
      class="is-hidden-desktop"
      :border-color="accentColor"
      :title-color="accentColor"
      badge-color="#ff0000"
      :icon-color="accentColor"
      v-model="selectedBottomNav"
      :options="bottomNavOptions"
    ></RingBottomNavigation>

    <!-- 
   
        v-model="selectedBottomNav"
        
    -->

    <promoFooter v-if="!isLoggedIn" />
  </div>
</template>

<script>
import Navbar from "./components/nav/Navbar.vue";
import NavbarGpt from "./components/nav/NavbarGpt.vue";

import Sidebar from "./components/nav/Siderail.vue";
import brandedNavbar from "./components/nav/brandedNavbar.vue";
//import brandedNavbarGpt from "./components/nav/brandedNavbarGpt.vue";
import { SwipeBottomNavigation, RingBottomNavigation } from "bottom-navigation-vue";

import OSDetector from "detect-os";

import promoFooter from "./components/brochure/promoFooter.vue";

//import css file from bulma-pricingtable.min.css module npm
import "bulma-pricingtable/dist/css/bulma-pricingtable.min.css";

import update from "./mixins/swUpdate";

const ROUTES_NO_BOTTOM_BAR = "plans inboxDemo inboxAuto inboxConvo inboxNewConvo EditReadme".split(" ");

const NO_SIDEBAR_ROUTES = "creators otherthingbrochure".split(" ");

var OG_DEFAULT = "https://only-bots.com/img/brand/og3.png";

function isPwa() {
  return ["fullscreen", "standalone", "minimal-ui"].some(
    (displayMode) => window.matchMedia("(display-mode: " + displayMode + ")").matches
  );
}

const FORCE_SITE_DEV = "qualitygpts.com";

export default {
  mixins: [update],
  components: {
    Navbar,
    brandedNavbar,
    NavbarGpt,
    Sidebar,
    // brandedNavbarGpt,
    //  SwipeBottomNavigation,
    RingBottomNavigation,
    promoFooter,
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "OnlyBots", //"ApiBros 😎",
    // all titles will be injected into this template
    titleTemplate(s) {
      //   return focussed;
      // var t = devIcon + build; // ''
      //   if (focussed) t += n;
      //t += n;
      //t += s;
      // var t = devIcon + build + n + s;
      var t = s;
      if (s && !s.startsWith("OnlyBots")) {
        t += " • OnlyBots 🖤 "; // 💬 ⦚🎉 ⦚ ⦚
      } else {
        //it's starts with OnlyBots already... prevents "OnlyBots • OnlyBots 🎉"
      }
      return t;
    },
    //  titleTemplate: "%s - OnlyBots ", //🍔  ",
    meta: [
      { charset: "utf-8" },
      { name: "viewport", content: "width=device-width, initial-scale=1" },
      //  { property: "og:site_name", content: "OnlyBots" },
      { property: "og:type", content: "website" },
      { property: "og:image", vmid: "og:image", content: OG_DEFAULT }, //hid props act as unique id
      { property: "twitter:image", content: OG_DEFAULT },
      { property: "twitter:card", content: "summary_large_image" },
    ],
  },

  data() {
    return {
      ROUTES_NO_BOTTOM_BAR,
      NO_SIDEBAR_ROUTES,
      accentColor: "#1100ae", // for BOTTOM NAV
      selectedBottomNav: 2,

      // options: ,
    };
  },

  created() {
    //listed for browser events and set the store accordingly...
    this.$on("canInstall", (event) => {
      // Prevent Chrome 67 and earlier from automatically showing the prompt:
      event.preventDefault();

      // Stash the event so it can be triggered later:
      var deferredPrompt = event;
      this.$store.main.commit("setDeferredPrompt", deferredPrompt);
      //put it in the STORE!
    }); /* ;*/

    //Set if the app is running in browser or app mode (installed for fullscreen)
    this.$store.main.commit("setIsPwa", isPwa());

    const detector = new OSDetector();
    const detected = detector.detect();
    window.OSS = detected;
    this.$store.main.commit("setOs", detected.os);

    //  [App.vue specific] When App.vue is first loaded start the progress bar
    if (this.$Progress) this.$Progress.start();
    //  hook the progress bar to start before we move router-view
    this.$router.beforeEach((to, from, next) => {
      //  does the page we want to go to have a meta.progress object
      if (to.meta.progress !== undefined) {
        let meta = to.meta.progress;
        // parse meta tags
        if (this.$Progress) this.$Progress.parseMeta(meta);
      }
      //  start the progress bar
      if (this.$Progress) this.$Progress.start();
      //  continue to next page
      next();
    });
    //  hook the progress bar to finish after we've finished moving router-view
    this.$router.afterEach((to, from) => {
      //  finish the progress bar
      if (this.$Progress) this.$Progress.finish();
    });
  },
  computed: {
    isGpt() {
      //if domain is GPT.com then return true

      if (
        window.SITE == "qualitygpts.com" ||
        window.location.hostname == "qualitygpts.com" ||
        (FORCE_SITE_DEV == "qualitygpts.com" && window.location.hostname == "localhost")
      ) {
        return true;
      } else {
        return false;
      }
    },
    isReviews() {
      //if domain is GPT.com then return true

      if (
        window.SITE == "gpts.reviews" ||
        window.location.hostname == "gpts.reviews" ||
        (FORCE_SITE_DEV == "gpts.reviews" && window.location.hostname == "localhost")
      ) {
        return true;
      } else {
        return false;
      }
    },
    isLoggedIn() {
      return this.$store.main.state.userToken;
    },
    hasBetaAccess() {
      return this.$store.main.state.userToken && this.$store.main.state.profile?.betaAccess;
    },
    showNoAccessMessage() {
      if (!this.$store.main.state.userToken) return false;
      if (!this.$store.main.state.profile?.handle) return false; //profile not loaded yet, flickring
      if (this.$store.main.state.profile?.betaAccess) return false;
      const routesWithNoMessages = "creators otherthingbrochure".split(" ");
      if (routesWithNoMessages.includes(this.$route.name)) return false;
      return true;
    },
    inviteUrl() {
      var user = this.$store.main.state.handle || this.$store.main.state.userId;
      return `${window.location.origin}#ref-${user}`;
    },
    bottomNavOptions() {
      const PACK = "fas";
      // CAUSES:  NavigationDuplicated: Avoided redundant navigation to current location: "/messages". WHY?
      var botsRoute = this.$store.main.getters.hasPages || this.$store.main.getters.hasSubs ? "bots" : "discover";

      return [
        //   { id: 1, path: { name: "Home" }, icon: "fas fa-house", title: "Home" },
        { id: 1, path: { name: "Home" }, icon: "fas fa-search", title: "Home" },
        //generateImage
        { id: 4, path: { name: "createHome" }, icon: "fas fa-sparkles", title: "Create" },

        //  { id: 1, path: { name: "Home" }, icon: "fas fa-user-friends", title: "Explore" },
        // { id: 2, path: { name: "explore" }, icon: "fal fa-search", title: "Explore" },
        //  { id: 2, path: { name: "explore" }, icon: "fas fa-user-friends", title: "Bots" },

        //   { id: 3, path: { name: "createHome" }, icon: "fas  fa-plus", title: "Create" },
        // { id: 4, path: { name: "refer" }, icon: "fas fa-gift", title: "Invites" },
        { id: 2, path: { name: "newsfeed-new" }, icon: "fas fa-newspaper", title: "Feed" },

        //  { id: 9, path: { name: "dashboard" }, icon: "fas fa-drafting-compass", title: "Studio " },
        //  { id: 9, path: { name: botsRoute }, icon: "fas fa-user-friends", title: "Bots " },
        //  { id: 9, path: { name: "discover" }, icon: "fas fa-compass", title: "Explore " },
        { id: 4, path: { name: "inbox" }, icon: "fas fa-comment", title: "Chats" },

        //

        //  { id: 4, path: "/notifications", icon: "fas fa-bell", title: "Notification", badge: 4 },

        // { id: 2, path: "/pages", icon: "fas fa-robot", title: "Bots" },

        { id: 5, path: { name: "menuProfilePage" }, icon: "fas fa-bars", title: "Menu" },

        //  { id: 5, path: { name: "menuProfilePage" }, icon: "fas fa-user-circle", title: this.$store.main.state.userId },

        // { id: 5, path: "/u/" + this.$store.main.state.userId, icon: "fas fa-user-circle", title: this.$store.main.state.userId },
      ];
    },
  },
  mounted() {
    this.$store.main.dispatch("fetchCurrentUserProfile");
    this.$store.main.dispatch("fetchCurrentUserBots");

    // track if the page is at the top, for the border on the title bar, mobile
    this.$nextTick(function () {
      if (!this.isLoggedIn) {
        this.checkAndStoreReferral();
      }

      window.addEventListener("scroll", function () {
        var CLASS_NAME_WHEN_SCROLLED = "scrolled";
        var navbar = document.getElementById("app");
        var nav_classes = navbar.classList;
        if (document.documentElement.scrollTop >= 150) {
          if (nav_classes.contains(CLASS_NAME_WHEN_SCROLLED) === false) {
            nav_classes.toggle(CLASS_NAME_WHEN_SCROLLED);
          }
        } else {
          if (nav_classes.contains(CLASS_NAME_WHEN_SCROLLED) === true) {
            nav_classes.toggle(CLASS_NAME_WHEN_SCROLLED);
          }
        }
      });
    });
  },
  methods: {
    checkAndStoreReferral() {
      let hash = window.location.hash.replace("#", "");
      const BAD_HASH_VALUES = "top referral ref back".split(" "); // 'top referral
      if (hash && !BAD_HASH_VALUES.includes(hash)) {
        let currentDate = new Date().toISOString();
        localStorage.setItem("referral", JSON.stringify({ id: hash, date: currentDate }));
        console.log("👨👨 referral stored:", hash);
      }

      /*

       checkReferralValidity() {
      let referral = JSON.parse(localStorage.getItem("referral"));
      if (referral) {
        let storedDate = new Date(referral.date).getTime();
        let currentDate = new Date().getTime();

        if (currentDate - storedDate <= this.VALIDITY_WINDOW) {
          return referral.id;
        }
      }

      return null;
    },
    login() {
      // ... your login logic ...

      let validReferralId = this.checkReferralValidity();
      if (validReferralId) {
        window.api.me.setRefferingUser(validReferralId);
      }

      // Continue your login logic, e.g. redirecting the user, showing a message, etc.
    }*/
    },
  },
};
</script>

<style lang="scss">
/* import ig.css */

/* MAterial 3 */

/* Set the font family of the entire app */
:root {
  --ion-font-family: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif;
  --md-ref-typeface-brand: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif "Open Sans",
    "Arial", "Sans" !important;
  --md-ref-typeface-plain: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif, -apple-system,
    BlinkMacSystemFont, "Segoe UI";
  --md-ref-typeface-brand: -apple-system, BlinkMacSystemFont, "Helvetica Neue", "Roboto", sans-serif, "Open Sans";
}

:root {
  --NAV_BG_COLOR: aquamarine;
}

.noBetaAccess {
  position: fixed;
  min-height: 100vh;
  width: 100vw;
  padding: 3wh;
  z-index: 999;
  backdrop-filter: blur(20px);
  background-color: rgba(255, 255, 255, 0.7);
  text-align: center;
}
.noBetaAccess h1 {
  margin-top: 20vh;
}

.reverse-row-order {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}

.avatar {
  border-radius: 50%;
  object-fit: cover;
}

.elep1 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.elep2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.elep3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.container.c800,
.container.w800 {
  max-width: 800px !important;
}

.container.w1000 {
  max-width: 1000px !important;
}

.container.w500 {
  max-width: 500px;
}

.clear {
  clear: both;
}

.multiply {
  mix-blend-mode: multiply;
}
.pre-line {
  white-space: pre-line;
}
.left-layout {
  border-right: 1px solid #fff;
  background: aquamarine;
  background: #fffe00;
  background: linear-gradient(150deg, #ffc92f, #ff185c, #f316d5, #b81ed2);
  background: linear-gradient(139deg, #42ffe5, #55ff2b, #e4ff00);
  /*
  background: linear-gradient(139deg, #ffffff, #fbff00, #d6ff00);*/
  background-attachment: fixed;
  background: transparent;
  border-right: 1px solid transparent;
  background: #d8e5c0;
  /*

  background: linear-gradient(0deg, #ffffff, #fbff00, #d6ff00);

  background: linear-gradient(
    77deg,
    #42ffe5 10%,
    #55ff2b,
    #cbff21,
    #42ffe5,
    #55ff2b,
    #e4ff00,
    #42ffe5,
    #55ff2b,
    #e4ff00,
    #42ffe5,
    #55ff2b,
    #e4ff00,
    #42ffe5,
    #55ff2b,
    #e4ff00,
    #42ffe5 90%
  );

  background-attachment: fixed;
  background-size: 400% 400%;
  animation: shine 200s linear infinite;*/
}
@keyframes shine {
  to {
    background-position: 400%;
  }
}

kbd {
  /* keyboard shortcut styling */
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0.1em 0.5em;
  margin: 0 0.2em;
  box-shadow: 0 1px 0px rgba(0, 0, 0, 0.2), 0 0 0 2px #fff inset;
  background-color: #f7f7f7;
}

.empty {
  text-align: center;
}
.text-align-center {
  text-align: center;
}

body,
html {
  background: #fcfcfc;
  background: #f3f5f1;

  min-height: 100vh;
  --bg-color: #f3f5f1;
  --bg-color-alpha: #f3f5f1dd;
  background: var(--bg-color);
}
/* prevent bounce */
/*
body {
  overscroll-behavior-y: none;
  -webkit-overflow-scrolling: touch;
  bottom: auto;
  height: 100%;
  right: auto;
  width: auto;

}*/

html,
body {
  overscroll-behavior-y: none;
}
/* use a variabel for bg color */
.bg {
  background: #f3f5f1;
}

/*  defineBg-color  */

::selection {
  color: #000;
  background: #29ffb1;
  -webkit-background-clip: none;
  -webkit-text-fill-color: #000;
}
#app {
  font-family: "Basier Square Regular", Helvetica, Arial, sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
.regularText {
  color: #2c3e50;
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active:not(.noUnderline) {
      color: black;
      text-decoration: underline;
    }
  }
}
.navbar {
  background: transparent !important;
}

/*  GENERAL utilities  */

.coin-icon {
  color: #ffce1d;
  margin-right: 0.3em;
}

// PLUGINS
.bro_code pre {
  padding: 0; // fixes a bug in the code bloc k lib
}
.bro_error {
  background: red !important;
}
.bro_error pre code {
  background: rgb(255, 218, 218) !important;
}

.jsoneditor-menu {
  display: none !important;
}

/// PAGES
.breadcrumb.is-xlarge {
  font-size: 2rem;
  font-weight: 300;
  color: #2c3e50;
}

.breadcrumb.is-xlarge a {
  color: #000;
}

.breadcrumb .projectName a {
  color: #ccc;
}

@import url("../src/assets/ig.css");

.route-view-wrap {
  width: calc(100vw - 250px);
  min-height: 100vh;
}
/*
#bottonNav {
  display: none;
}*/

/* responsive hide max scree 960 */
@media screen and (max-width: 1060px) {
  .route-view-wrap {
    width: 100vw;
  }
}

@media screen and (max-width: 1023px) {
  body {
    // TODO: ADJUST THIS for inbox, which use MAX HEIGHT 100vh
    padding-bottom: 100px !important;
  }
  /*
  #bottonNav {
    display: flex;
  }*/
}

/*  LAYOUT 2 cols - logged-in  */

/* essential to add this as there's a default 8px margin */

body {
  margin: 0;
}

/* this is also essential to avoid a world of width-based pain */

* {
  box-sizing: border-box;
}

/*

.container-layout {
  display: flex;
  width: 100%;
}

.container-left {
  background-color: plum;
  height: 50vh;
  padding: 0.5rem 0.5rem;
  border: 1px solid transparent;
  width: 350px;
  transition: width 300ms;
}

body:has(.menubutton > input:checked) .container-left {
  width: 10rem;
}

.container-right {
  border: 1px solid lightgray;
  height: 50vh;
  flex-grow: 1;
  padding: 0.5rem 0.5rem;
}
*/
</style>
