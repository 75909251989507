/* eslint-disable */
require("dotenv").config(); // not sure it works...
const DEV = window.location.hostname == "localhost";

import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router/routes.js";
import mainStore from "./store/store.js";
import inboxStore from "./store/inbox.js";
import Meta from "vue-meta";

import _ from "lodash";

Vue.config.productionTip = false;

import "./common/md3/bundle.mjs"; //Matterial 3
//https://github.com/material-components/material-web/blob/main/docs/quick-start.md

import VueQRCodeComponent from "vue-qrcode-component";

// Register the Vue component
Vue.component("qr-code", VueQRCodeComponent);

import browserDetect from "vue-browser-detect-plugin";
Vue.use(browserDetect);

import footer from "@/components/nav/footer.vue";
Vue.component("theFooter", footer);

import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

import AppBar from "./components/AppBar.vue";
Vue.component("AppBar", AppBar);
//Vue.use(AppBar);

import TextareaAutosize from "vue-textarea-autosize"; //cant work with component import
Vue.use(TextareaAutosize);

import VueTimeago from "vue-timeago"; //https://www.npmjs.com/package/vue-timeago
Vue.use(VueTimeago, {
  name: "Timeago", // Component name, `Timeago` by default
  locale: "en", // Default locale
  // We use `date-fns` under the hood
  // So you can use all locales from it
  locales: {
    //'zh-CN': require('date-fns/locale/zh_cn'),
    //ja: require('date-fns/locale/ja')
  },
});

//INVT VERSION
import titleBar from "@/components/nav/titleBar.vue";
Vue.component("titleBar", titleBar); //make availa EVEYWHERE

import rLink from "@/components/e/rLink.vue"; //from invt
Vue.component("rLink", rLink); //make availa EVEYWHERE

import jsonDebug from "@/components/e/jsonDebug";
Vue.component("jsonDebug", jsonDebug);

import empty from "@/components/e/empty";
Vue.component("empty", empty);

import UserText from "@/components/e/UserText.vue";
Vue.component("UserText", UserText);

import BotList from "@/components/BotList.vue";
Vue.component("BotList", BotList);

//import bottomNavigationVue from "bottom-navigation-vue";
//Vue.use(bottomNavigationVue);

import Buefy, { ConfigProgrammatic } from "buefy";
import "buefy/dist/buefy.css";
import "../public/fonts/basier/style.css";

//socket stuff
import VueSocketIO from "vue-socket.io";
//const SOCKET_DOMAIN = "https://api.mid.run/";

//const SOCKET_DEBUG = true;
//const SOCKET_DOMAIN = DEV && SOCKET_DEBUG ? "http://localhost:3344/" : "https://api.only-bots.com/";

// import function to register Swiper custom elements
import { register } from "swiper/element/swiper-element.js";
// register Swiper custom elements
register();

const SANITY_TOKEN =
  "skNh2CoExQSH4kgGZsaToIfnweeWPjpSNbfiiKHgwy1Pswx8GClbpa1XU3rjguC8LE8p31aTMjXr1l1YBhri73R95XljCLHEg6sW9PUFbRItKstXW2edqkoT7AaKgrW3YLX74hEqfBK5ikI7S8hGRXghi8hnY9ZbzrqyEtKHcGTFe9z07KjN";
// https://www.sanity.io/manage/personal/project/m0yqt4j3/api#tokens

import shadow from "vue-shadow-dom";
Vue.use(shadow);

// meta , buefy
Vue.use(Meta);
Vue.use(Buefy);
ConfigProgrammatic.setOptions({
  defaultIconPack: "fas",
  // defaultIconComponent: "font-awesome-icon",
});
Vue.prototype.$scrollToTop = () => window.scrollTo(0, 0);

import CodeEditor from "simple-code-editor";
// Vue.use(CodeEditor);
//Vue.use({ components: { CodeEditor } });
/*
export default {
  components: {
    CodeEditor
  }
}
*/
/*
import { VueCsvImport } from "vue-csv-import";

new Vue({
  components: { VueCsvImport },
  el: "#app",
});*/

//import VueExcelEditor from "vue-excel-editor";
//Vue.use(VueExcelEditor);

const USER_LOCALHOST_API_IN_DEV = true;

//import apiEndpointsData from "@/data/dashapi.endpoints.json";
import apiEndpointsData from "@/spec.json";

// TODO: load from the midrun folder? The default folder?

//import dashApiEncpointsData from "https://dashapi.apibros.io/projects/dashApi.json";
//console.log(dashApiEncpointsData, 77778);

//import invitationApi from "../../../invitation/invt-npm-api/src/index.js"; //the lib, no endpoints.
//import invitationApi from "../../../invitation/invt-npm-api/dist/index.js"; //the lib, no endpoints.

// this one was working...
//import invitationApi from "invitation-api/dist/index.js";
// TODO: Port this APIBro SDK.
//import { createClient } from "midrun";
import midrun from "midrun";

import "./registerServiceWorker";

var isAdminBuild = true;
var baseUrl = "https://api.only-bots.com"; //prod   //"https://api.invitation.codes/api/v2";
if (window.location.hostname == "localhost") {
  if (USER_LOCALHOST_API_IN_DEV) {
    baseUrl = "http://localhost:8811"; //relative AF - to use on prod... + dev. Just the stupid pre-rendering that can't use it...
    //alert('LOCALhost baby!')
  } else {
    // localhost, but for devs that don't run the back. Or cafés... or bad net.
    // var baseUrl = "https://api.invitation.codes/api/v2";
  }
} else {
  //public
  // baseUrl = "https://api.invitation.codes/api/v2";
  // baseUrl = "/api/v2"; //  in prod, also use relatve path, the app static is served by the same express serverver than the api.
}
window.midrun = midrun.createClient;
//window.invitationApi = midrun.createClient; //expose constructor
API = window.API = midrun.createClient({
  endpoints: apiEndpointsData,
  useFetch: true,
  caching: true,
  throwOnError: true, //default to true, requires a catch on front-ends
  debug: true, //enables logs, they are scraped at build time, so harmless to leave.
  //apiKey: 'devapitest-JA9zovLgJi', //not in use
  clientKey: "webfrontend-dev-JA9zovLgJi222",
  // clientSecret: null, //for backenduse only...
  envelope: false, // we gotta update the webapp to use the full on
  userToken: localStorage.getItem("userToken") || "",
  userId: localStorage.getItem("userId") || "",
  timeout: isAdminBuild ? 122000 : 15000, // 120000  long for admin...
  baseUrl: baseUrl, //relative AF - to use on prod... + dev. Just the stupid pre-rendering that can't use it...
  /*
  onError: function (operationId, msg) {
 
    Toast.open({
      // programatic toast instance...
      //Vue.$buefy.toast.open({
      duration: 5000,
      message: `ADMIN: Something's not working with the server. Can't call <b>${operationId}( ${msg})</b>`,
      position: "is-bottom",
      type: "is-danger",
    });
  },*/
});

// TODO: maybe move this into a JS module?
window.createApiPlayground = function (projectId, options) {
  const PLAY_DEFAULTS = { debug: true, useFetch: true, caching: false };
  var opt = _.defaults(PLAY_DEFAULTS, options);
  var api = new midrun.createClient(opt);
  return api;
};

/*
API.getDomainProjectId().then((projectId) => {
  API.getProject(projectId).then((proj) => {
    // console.log("PROJJ!", proj, proj.id);
    Vue.$proj = proj;
  });  
  // getImplementedOperationIds;
});*/

Vue.component("CodeEditor", CodeEditor);

new Vue({
  router,

  store: {
    main: mainStore,
    inbox: inboxStore,
  },
  //  store,
  //components: { CodeEditor }, //vue 3 syntax...
  render: (h) => h(App),
}).$mount("#app");
