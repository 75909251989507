import store from "../store/store.js";
import _ from "lodash";

//LOTS of legacy stuff here...

export default async function (to, from, savedPosition) {
  var profilePagesIntersect = _.intersection(
    //The profile TABS!
    [
      "profileReferral",
      "profile",
      "profileAlt",
      "profileAbout",
      "profileSaved",
      "profileAdmin",
      "profileDiscussions",
      "profileNfts",
      "profileExchange",
      "profileOverview",
      "profileCoupons",
      "profileShops",
      "ReferralUserCodeDetail",
      "ReferralUserCodeDetailProfile",
      "ReferralRepostCodeChildProfile",
      "ReferralEditCodeChildProfile",
    ],
    [to.name, from.name]
  );

  // dash popup transition.
  // ReferralRepostCodeChildDash ➡️🔸- dashhome
  var dashboardPopupIntersect = _.intersection(
    //The profile TABS!
    ["dashhome", "ReferralRepostCodeChildDash", "ReferralUserCodeDetailDash", "ReferralEditCodeChildDash"],
    [to.name, from.name]
  );
  //  referralProgram ➡️🔸- ReferralProgramCodeDetail
  var programPopupIntersect = _.intersection(
    //The profile TABS!
    ["referralProgram", "ReferralProgramCodeDetail", "ReferralRepostCodeChildList"],
    [to.name, from.name]
  );

  console.log("🛣️  navigate : ", from.name, "➡️🔸-", to.name);
  // ReferralUserCodeDetailProfile ➡️🔸- profileReferral

  var dontScroll =
    programPopupIntersect.length == 2 || profilePagesIntersect.length == 2 || dashboardPopupIntersect.length == 2;
  //alert(profilePagesIntersect)
  if (dontScroll) {
    //user is switching from one tab to the other... don't scroll.
    console.log("dont scroll, profile tabs");
    return;
    //todo; optional. w can also check that user is the same (either matching ID or mathing slug)
  }

  if (savedPosition) {
    //Returning the savedPosition will result in a native-like behavior when navigating with back/forward buttons:
    return savedPosition;
  } else {
    var shouldBeSmooth = false; // TODO: WIP. Only do it for route transition where it make sense
    //maybe if we go to home?

    var position = { x: 0, y: 0 };
    if (shouldBeSmooth) {
      position.behavior = "smooth"; //browser stuff
    }

    //{ x: 0, y: 0, behavior:   'smooth'  };

    // document.getElementById('app').scrollIntoView();
    store.commit("setScrollY", 0); // to fix some UI state based on scroll positions.

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        resolve(position);
      }, 10); //short delay to let other events get prioritized in the refresh stack
    });
    //return ;
    //return position
    // , behavior: 'smooth', - not working..
  }
  //  return { x: 0, y: 0 };
}
