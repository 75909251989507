<template>
  <div>
    <div class="columns footlinks is-mobileNOT is-variable is-6 is-mobile is-multiline">
      <!--
          <div class="column is-half-tablet is-half-mobile  is-3-desktop    ">
            <h5>
                <router-link to="/">
                  Me
  
                </router-link>
  
          </h5>
                <nav v-show="isLoggedIn">
                  <router-link to="/">
                    <span>
                      My profile
                    </span>
                    <span class="icon has-text-infoNOT " >
                      <i class="fal fa-user-circle"></i>
                    </span>
                  </router-link>
  
                   <router-link to="/notifications">
                    <span class="icon has-text-infoNOT " >
                      <i class="fal fa-bell"></i>
                     </span>
                     <span>
                        Notifications
                     </span>
  
                  </router-link>
  
  
                  <router-link to="/stats">
                    <span>
                      Stats
                    </span>
                    <span class="icon has-text-infoNOT " >
                      <i class="fal fa-retweet"></i>
                    </span>
                  </router-link>
  
  
  
                  <router-link to="/repost">
                    <span>
                      Repost
                    </span>
                    <span class="icon has-text-infoNOT " >
                      <i class="fal fa-retweet"></i>
                    </span>
                  </router-link>
  
  
  
                  <router-link to="/add">
                    <span>
                      New post
                    </span>
                    <span class="icon has-text-infoNOT " >
                      <i class="fal fa-plus-circle"></i>
                    </span>
                  </router-link>
  
  
  
  
  
  
  
  
                  <router-link to="/logout" onclick="googSignOut();">
                     <span>
                        Log out
                     </span>
                     <span class="icon has-text-infoNOT " >
                       <i class="fal fa-exit"></i>
                      </span>
                  </router-link>
  
  
  
  
                    <router-link to="/login" class="loginModalToggle" rel="nofollow">Post a link</router-link>
                    <router-link to="/login" class="loginModalToggle" rel="nofollow">Log in</router-link>
                    <router-link to="/login" class="loginModalToggle" rel="nofollow">Sign up</router-link>
  
  
  
  
  
  
                </nav>
        </div> v-if="!isLoggedIn"-->

      <div class="column is-half-tablet is-half-mobile is-3-desktop" style="display: none">
        <h5>AIs for...</h5>
        <nav>
          <router-link to="/for/students">Students</router-link>

          <router-link to="/for/pro">Profesionals</router-link>
          <router-link to="/for/content-creators">Programmers</router-link>
          <router-link to="/for/marketers">Sales & Marketing</router-link>

          <router-link to="/for/fun">Fun</router-link>

          <!--  

 
          -->
        </nav>
      </div>
      <div class="column is-half-tablet is-half-mobile is-3-desktop" v-if="isLoggedIn">
        <h5>Me</h5>
        <nav>
          <router-link :to="$store.main.getters.profileUrl">Profile</router-link>
          <!--
          <router-link to="/bots/me">Dashboard </router-link>
             <router-link to="/messages">Inbox</router-link>
          <router-link to="/coins">Coin rewards</router-link> -->
          <router-link to="/refer">Invites </router-link>

          <router-link to="/settings">Settings</router-link>
          <router-link to="/upgrade"> Upgrade </router-link>
        </nav>
      </div>

      <!--   <div class="column is-half-tablet is-half-mobile is-3-desktop">
        <h5>Product</h5>
        <nav>
          <router-link to="/features">Overview</router-link>
          <router-link to="/extension">Browser Extension</router-link>

      
          <router-link to="/discuss" v-if="isLoggedIn"> Discuss </router-link>
          <router-link to="/people">Community</router-link>

          <router-link to="/upgrade" v-if="isLoggedIn"> Premium plans </router-link>
          <router-link to="/plans" v-if="!isLoggedIn"> Premium plans </router-link>
          <router-link to="/invite" class="  " v-if="isLoggedIn">
            <span> Refer a Friend </span>
          </router-link>
          <router-link to="/login" v-if="!isLoggedIn">Sign in</router-link>
          <router-link to="/signup" v-if="!isLoggedIn">Join</router-link>

        
  
                  <router-link to="/invite">Invite friends </router-link>
               
        </nav>
      </div> -->

      <div class="column is-half-tablet is-half-mobile is-3-desktop">
        <h5>Resources</h5>
        <nav>
          <router-link to="/creators">AI creators </router-link>

          <router-link to="/page/feedback" v-if="isLoggedIn">Report a bug 🐛</router-link>

          <!--
    <router-link to="/getting-started/faq" rel="nofollow">FAQ</router-link>
                 <router-link to="/getting-started">Getting started</router-link>
                          <router-link to="/blog/support" rel="nofollow">Help</router-link>
                        <router-link to="/submit" class=" " rel="nofollow">Post a Program</router-link>
          <router-link to="/mag/write-for-us">Write for us</router-link>
          <router-link to="/page/influencer-program">Influencer program</router-link>
          <router-link to="/page/for-businesses">For Businesses</router-link>
          <router-link to="/page/advertise">Advertise</router-link>
          -->

          <!--
                          <router-link to="/guide/why">Affiliate Partners</router-link>
                        -->
          <!--
                          <router-link to="/referral-softwares">Referral softwares</router-link>
                        -->
        </nav>
      </div>

      <div class="column is-half-tablet is-half-mobile is-3-desktop" style="display: none">
        <h5>Company</h5>
        <nav>
          <router-link to="/company/about">About</router-link>

          <router-link to="/company/jobs">Jobs</router-link>
          <router-link to="/company/invest">Invest</router-link>
        </nav>
      </div>

      <!--   <a href="https://www.buymeacoffee.com/invitation" target="_blank" rel="nofollow noopener">Buy us a coffee</a>
 
       <div class="column ">
         <h5>Tools</h5>
             <nav>
  
             </nav>
     </div>-->
    </div>
  </div>
</template>
<script>
// import footmenu from './footmenu.vue'; //NEED REFACTORING!

/*
import YoutubeTranscript from "youtube-transcript";

YoutubeTranscript.fetchTranscript("Ez8Q68h9i4M").then(console.log);


import { getSubtitles } from "youtube-captions-scraper";

getSubtitles({
  videoID: "Ez8Q68h9i4M", // youtube video id
  lang: "enr", // default: `en`
}).then((captions) => {
  console.log(captions, 1111111);
});*/

export default {
  name: "footmenu",
  components: {
    //  Navbar: Navbar,
  },
  computed: {
    profile() {
      return this.$store.main.getters.profile;
    },
    isLoggedIn() {
      return this.$store.main.getters.isLoggedIn;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
};
</script>
<style>
.foot h5 {
  font-size: 20px;
  font-weight: bold;
  color: #ccc;
}
.footlinks a {
  display: block;
}

.footlinks nav a {
  display: block;
  margin-top: 10px;
  color: #999;
}
.footlinks nav a:hover {
  color: white;
}

.foot .foot .inviteBrand img {
  max-height: 70px;
  margin-right: 3rem;
  margin-bottom: 20px;
}
.foot .inviteBrand {
  margin-bottom: 1rem;
}
.foot {
  padding-top: 4rem;
  padding-bottom: 1rem;
}

.foot .socialicon {
  font-size: 1.5rem;
  margin-right: 10px;
  opacity: 0.3;
}
</style>
