var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"BlockContent",class:{
    [_vm.item.layout]: true,
    [_vm.item.bgColor]: true,
    [_vm.item.blockClass]: true,
    fullscreen: _vm.item.fullscreen,
  },style:(_vm.sectionStyles)},[_c('div',{staticClass:"container"},[(_vm.item)?_c('div',{staticClass:"theBlock"},[_c('div',{staticClass:"columns is-vcentered",class:{
          'reverse-row-order': _vm.item.reverse,
        }},[(!_vm.isHero && _vm.item.photo600)?_c('div',{staticClass:"column",class:{ 'is-3': _vm.item.layout == 'banner' }},[_c('figure',{staticClass:"image inCol",class:{
              gray: !_vm.item.noFilter,
            }},[_c('img',{attrs:{"src":_vm.item.photo600 || _vm.img.photo,"alt":_vm.item.title || _vm.item.name,"loading":"lazy"}})])]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"column",class:{
            'text-align-center': _vm.isHero,
          }},[(_vm.isHero && _vm.item.secondaryImg)?_c('figure',{staticClass:"image secondaryImg"},[_c('img',{attrs:{"src":_vm.item.secondaryImg}})]):_vm._e(),_vm._v(" "),(_vm.item.subtitle)?_c('h3',{staticClass:"subtitle is-5",staticStyle:{"color":"#c5c5c5"}},[_vm._v("\n            "+_vm._s(_vm.item.subtitle)+"\n          ")]):_vm._e(),_vm._v(" "),_c('h1',{staticClass:"title",class:{
              'is-1': _vm.item.layout == 'hero',
              'is-2': _vm.item.layout != 'hero',
            }},[_vm._v("\n            "+_vm._s(_vm.item.title)+"\n\n            ")]),_vm._v(" "),_c('div',{staticClass:"content"},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.item.body)}})]),_vm._v(" "),(_vm.item.btLabel)?_c('router-link',{staticClass:"",attrs:{"to":_vm.getRouteObj(_vm.item.btTo || '/rdv')}},[_c('span',{staticClass:"button",class:{
                'is-link': _vm.item.layout != 'hero',
                'is-black': _vm.item.layout == 'hero',
              }},[_vm._v(_vm._s(_vm.item.btLabel))])]):_vm._e(),_vm._v(" "),(_vm.item.secondaryBtTo)?_c('router-link',{staticClass:"",attrs:{"to":_vm.getRouteObj(_vm.item.secondaryBtTo || '/rdv')}},[_c('b-button',{staticClass:"is-ghost",attrs:{"type":"is-ghost","icon-right":"chevron-right"}},[_vm._v("\n              "+_vm._s(_vm.item.secondaryBtLabel || "**MISSING LABEL **")+"\n            ")])],1):_vm._e()],1)])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }