<template>
  <section
    class="BlockContent"
    :class="{
      [item.layout]: true,
      [item.bgColor]: true,
      [item.blockClass]: true,
      fullscreen: item.fullscreen,
    }"
    :style="sectionStyles"
  >
    <div class="container">
      <div class="theBlock" v-if="item">
        <div
          class="columns is-vcentered"
          :class="{
            'reverse-row-order': item.reverse,
          }"
        >
          <div class="column" :class="{ 'is-3': item.layout == 'banner' }" v-if="!isHero && item.photo600">
            <figure
              class="image inCol"
              :class="{
                gray: !item.noFilter,
              }"
            >
              <img :src="item.photo600 || img.photo" :alt="item.title || item.name" loading="lazy" />
            </figure>
          </div>
          <div
            class="column"
            :class="{
              'text-align-center': isHero,
            }"
          >
            <figure class="image secondaryImg" v-if="isHero && item.secondaryImg">
              <!-- 

                style="width: 900px; max-width: 100%"
                
            -->
              <img :src="item.secondaryImg" />
            </figure>
            <h3 class="subtitle is-5" v-if="item.subtitle" style="color: #c5c5c5">
              {{ item.subtitle }}
            </h3>
            <h1
              class="title"
              :class="{
                'is-1': item.layout == 'hero',
                'is-2': item.layout != 'hero',
              }"
            >
              {{ item.title }}

              <!--
                 {{ item.title }}

              {{ i18nProps }}
              -->
            </h1>

            <div class="content">
              <div v-html="item.body" />
            </div>
            <router-link v-if="item.btLabel" class=" " :to="getRouteObj(item.btTo || '/rdv')">
              <!--  :to="localePath(getRouteObj(item.btTo || '/rdv'))"
              
              :to="localePath(getRouteObj(item.secondaryBtTo || '/rdv'))"
              -->
              <span
                class="button"
                :class="{
                  'is-link': item.layout != 'hero',
                  'is-black': item.layout == 'hero',
                }"
                >{{ item.btLabel }}</span
              >
            </router-link>

            <router-link v-if="item.secondaryBtTo" class=" " :to="getRouteObj(item.secondaryBtTo || '/rdv')">
              <b-button type="is-ghost" class="is-ghost" icon-right="chevron-right">
                {{ item.secondaryBtLabel || "**MISSING LABEL **" }}
              </b-button>
              <!-- 
                ,       <span class="button is-info">{{
                item.secondaryBtLabel || "**MISSING LABEL **"
              }}</span>
                  size="is-large"
                 outlined
              <span class="button is-info">{{
                item.secondaryBtLabel || "**MISSING LABEL **"
              }}</span> -->
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
/*
import ProfileCardHover from '@/components/profile/ProfileCardHover.vue';
import ProfileAvatar from '@/components/e/profileAvatar.vue';
import verifiedBadge from '@/components/e/verifiedBadge.vue';
*/
export default {
  // name: "codeColored",
  components: {},
  data() {
    return {
      none: "SimpleCode", //this is the name of the component
    };
  },
  props: ["item", "i18nProps"],
  computed: {
    isHero() {
      return this.layout == "hero";
    },
    layout() {
      return this.item.layout || "split";
    },
    sectionStyles() {
      var bg = null;
      if (this.item.photo600 && this.isHero) {
        bg = "url(" + this.item.photo1500Jpg + ")";
        return "background-image:   " + bg; //"#ff0000";
      }

      //handle color. not done yet..
      if (bg) return "background:" + bg; //"#ff0000";

      //  return "background:" +  '#ff
      return ""; //def
    },
  },
  methods: {
    getRouteObj: function (r) {
      //rhis can be either a string
      r = String(r);
      if (r.startsWith("/")) return r; // this.localePath(r); //absolute path.
      if (r.startsWith("#")) return r;
      if (r.startsWith("mailto:")) return r;
      if (r.startsWith("tel:")) return r;
      //otherwise, link to the brochure-based page.
      return r;
      return { name: r }; //this.localePath(); //bianka, jobs, etc
    },
    clickCardBg: function (u) {
      console.log("clickCardBg", u);
    },
    clickLink: function (event) {
      if (this.skeletonPromo) {
        //this is the "add your code promo"
        // scroll down...
      } else {
        var t = event.currentTarget; //not target...
        console.log(t);
        //var u = t.getAttribute('data-href')
        //store.openLink(u); //chrome.tabs.create({url: u, active: true});
      }
    },
  },
  mounted() {
    /* console.log('THIS simplecode mounted');
    console.log(this.c);
    console.log(this.index);*/
  },
};
</script>
<style scoped>
.theBlock {
  width: 100%;
}
.image img {
  object-fit: cover;
}

.inCol img {
  border-radius: 2vw;
}
.inCol.gray img {
  opacity: 1;
}

/*
.banner {
  background: rgb(250, 240, 255);
  color: rgb(126, 72, 138);
}
*/

section.banner {
  padding: 1rem;
  padding-bottom: 0;
}

.BlockContent {
  margin-bottom: 50px;
}
/*
.split {
  background: rgb(218, 255, 225);
  color: rgb(66, 133, 87);
}*/

.BlockContent.green,
.BlockContent.green h1 {
  background: rgb(218, 255, 225);
  color: rgb(66, 133, 87);
}

.BlockContent.white {
  background: rgb(255, 255, 255);
  color: rgb(69, 69, 69);
}

.BlockContent.purple {
  background: rgb(250, 240, 255);
  color: rgb(126, 72, 138);
}

.BlockContent.purple .button.is-link,
.BlockContent.purple .button.is-link:hover {
  color: rgb(250, 240, 255);
  background: rgb(126, 72, 138);
}

.BlockContent.dark {
  background: rgb(68, 68, 68);
  color: rgb(255, 255, 255);
}
.BlockContent.light {
  background: rgb(231, 231, 231);
  color: rgb(72, 72, 72);
}
.BlockContent.orange {
  background: rgb(240, 160, 22);
  color: rgb(255, 255, 255);
}

.BlockContent.red {
  background: rgb(219, 12, 12);
  color: rgb(255, 255, 255);
}

.BlockContent.hero {
  background-color: #ccc;
  background-size: cover;
  /*
  width: 100vw;
 height: 400px;*/
  background-size: cover;
  background-position: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.hero.dark {
  background-color: #444;
  color: white;
}
.hero.dark .content {
  color: white;
  text-shadow: 2px 8px 6px rgb(0 0 0 / 20%), 0 -5px 35px rgb(0 0 0 / 30%);
  font-size: 18px;
}

.button.is-ghost,
.button.is-ghost:hover {
  color: black;
}
.hero.dark .button.is-ghost,
.hero.dark .button.is-ghost:hover {
  color: white;
}

.hero.dark h1 {
  color: white;
  text-shadow: 2px 8px 6px rgb(0 0 0 / 20%), 0 -5px 35px rgb(0 0 0 / 30%);
}
.hero.fullscreen {
  min-height: 70vh;
}

.fullscreen .container {
  display: flex;
  /* justify-content: center; */
  align-items: center;
}
</style>
