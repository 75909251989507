const cats = [
  { label: "All", value: "all", icon: "globe-americas" },
  ///utility, entertainement, assistance, education
  { label: "Entertainment", value: "entertainment", icon: "grin" },
  { label: "Utility", value: "utility", icon: "wrench" },
  { label: "Assistance", value: "assistant", icon: "user" },
  //shopping
  // { label: "Shopping", value: "shopping", icon: "shopping-cart" },
  //brands
  { label: "Brands", value: "brand", icon: "building" }, //user-headset concirege bell, bag

  //{ label: "Fun", value: "fun", icon: "smile" },
  // { label: "Education", value: "education", icon: "graduation-cap" },
];
export default cats;
