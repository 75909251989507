<template>
  <div class="card" @mouseover="handleHover(true)" @mouseout="handleHover(false)" @click="handleClick">
    <div class="card-content">
      <p class="title is-3">{{ item.title }}</p>
      <div v-show="showDescription" class="desc-wrap">
        <p class="description">{{ item.description }}</p>
        <b-button rounded type="is-info" @click.stop="search(item)">Try it</b-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      isHovered: false,
      isClicked: false,
    };
  },
  computed: {
    showDescription() {
      if (window.innerWidth <= 768) {
        return this.isClicked;
      } else {
        return this.isHovered || this.isClicked;
      }
    },
  },
  methods: {
    handleHover(state) {
      this.isHovered = state;
    },
    handleClick() {
      if (window.innerWidth <= 768) {
        if (this.isClicked) {
          this.search();
          this.isClicked = false;
        } else {
          this.isClicked = true;
        }
      }
    },
    search() {
      this.$emit("search", this.item);
      console.log(`Searching for ${this.item.title}`);
    },
  },
};
</script>
<style scoped>
.card {
  height: 300px;
  text-align: left;
  border: 1px solid #ccc;
  padding: 10px;
}

.card-content .title {
  transition: 0.3s; /* Provides smooth fading */
  pointer-events: auto; /* Ensures the title can be interacted with normally when visible */
}

.card:hover .title {
  opacity: 0;
  visibility: hidden;
  pointer-events: none; /* Prevents the title from triggering hover when it's invisible */
}

.desc-wrap {
  position: absolute;
  bottom: 20px;
}
.card .description,
.card button {
  opacity: 0;
}

.card:hover .description,
.card:hover button,
.card[data-clicked="true"] .description,
.card[data-clicked="true"] button {
  opacity: 1;
}
</style>
